import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
    GET_LEADS_GENERATION,
    GET_COST_PER_LEAD,
    GET_LEADS_GENERATION_SPENDS,
    GET_CHANNEL_CHART,
    GET_PERSONA_CHART,
    GET_COHORT_CHART,
    GET_LEADS_GENERATION_REPORT,
    GET_LEADS_COST_PERFORMANCE,
    GET_LOCATION_REPORT,
    GET_LOCATION_MAP,
    GET_ALL_MAP_CHANNEL,
    GET_ALL_MAP_PERSONA,
    GET_ALL_MAP_COHORT
} from "./actionTypes"

import {
    getLeadsGenerationSuccess,
    getLeadsGenerationFail,
    getCostPerLeadSuccess,
    getCostPerLeadFail,
    getLeadsgenerationSpendSuccess,
    getLeadsgenerationSpendFail,
    getChannelChartSuccess,
    getChannelChartFail,
    getPersonaChartSuccess,
    getPersonaChartFail,
    getCohortChartSuccess,
    getCohortChartFail,
    getLeadsgenerationReportSuccess,
    getLeadsgenerationReportFail,
    getLeadCostPerformanceSuccess,
    getLeadCostPerformanceFail,
    getLocationReportSuccess,
    getLocationReportFail,
    getLocationMapSuccess,
    getLocationMapFail,
    getAllMapChannelSuccess,
    getAllMapChannelFail,
    getAllMapPersonaSuccess,
    getAllMapPersonaFail,
    getAllMapCohortSuccess,
    getAllMapCohortFail
} from "./actions"

import {
    getAllLeadsgeneration,
    getAllCostperlead,
    getAllLeadsgenerationSpend,
    getAllChannelChart,
    getAllPersonaChart,
    getAllCohortChart,
    getAllLeadsgenerationReport,
    getAllLeadCostPerformance,
    getLocationReport,
    getLocationMap,
    getAllMapChannel,
    getAllMapPersona,
    getAllMapCohort
} from "helpers/senara_backend/senarabackend_helper"

function* fetchAllLeadsGeneration({ payload: id }) {
    try {
        const { result, error } = yield call(getAllLeadsgeneration, id)
        if (result) yield put(getLeadsGenerationSuccess(result))
        if (error) yield put(getLeadsGenerationFail(error))
    } catch (error) {
        yield put(getLeadsGenerationFail(error))
    }
}

function* fetchAllCostPerLead({payload: id}) {
    try {
      const { result, error } = yield call(getAllCostperlead,id)
      if(result) yield put(getCostPerLeadSuccess(result))
      if(error) yield put(getCostPerLeadFail(error))
    } catch (error) {
      yield put(getCostPerLeadFail(error))
    }
  }

  function* fetchAllLeadsGenerationSpend({payload: id}) {
    try {
      const { result, error } = yield call(getAllLeadsgenerationSpend,id)
      if(result) yield put(getLeadsgenerationSpendSuccess(result))
      if(error) yield put(getLeadsgenerationSpendFail(error))
    } catch (error) {
      yield put(getLeadsgenerationSpendFail(error))
    }
  }

  function* fetchAllChannelChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllChannelChart,query)
      if(result) yield put(getChannelChartSuccess(result))
      if(error) yield put(getChannelChartFail(error))
    } catch (error) {
      yield put(getChannelChartFail(error))
    }
  }

  function* fetchAllPersonaChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllPersonaChart,query)
      if(result) yield put(getPersonaChartSuccess(result))
      if(error) yield put(getPersonaChartFail(error))
    } catch (error) {
      yield put(getPersonaChartFail(error))
    }
  }

  function* fetchAllCohortChart({payload: params}) {
    try {
      //console.log('Cohort params===>', params)
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cohort query===>', query)
      const { result, error } = yield call(getAllCohortChart,query)
      if(result) yield put(getCohortChartSuccess(result))
      if(error) yield put(getCohortChartFail(error))
    } catch (error) {
      yield put(getCohortChartFail(error))
    }
  }

  function* fetchAllLeadsgenerationReport({payload: params}) {
    try {
      // console.log('Report params===>', params)
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      // console.log('Report query===>', query)
      const { result, error } = yield call(getAllLeadsgenerationReport,query)
      if(result) yield put(getLeadsgenerationReportSuccess(result))
      if(error) yield put(getLeadsgenerationReportFail(error))
    } catch (error) {
      yield put(getLeadsgenerationReportFail(error))
    }
  }

  function* fetchAllLeadCostPerformance({payload: id}) {
    try {
      const { result, error } = yield call(getAllLeadCostPerformance,id)
      if(result) yield put(getLeadCostPerformanceSuccess(result))
      if(error) yield put(getLeadCostPerformanceFail(error))
    } catch (error) {
      yield put(getLeadCostPerformanceFail(error))
    }
  }

  function* fetchLocationReport({payload: id}) {
    try {
      const { result, error } = yield call(getLocationReport,id)
      if(result) yield put(getLocationReportSuccess(result))
      if(error) yield put(getLocationReportFail(error))
    } catch (error) {
      yield put(getLocationReportFail(error))
    }
  }

  function* fetchLocationMapData({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query +=`&type=${params?.type?.value}`
      if(params?.suite_type) params?.suite_type?.map((i)=>{query +=`&suite_type[]=${i}`})
      if(params?.price_range) params?.price_range?.map((i)=>{query +=`&price_range[]=${i}`})
      if(params?.is_realtor) params?.is_realtor?.map((i)=>{query +=`&is_realtor[]=${i}`})
      if(params?.with_realtor) params?.with_realtor?.map((i)=>{query +=`&with_realtor[]=${i}`})
      if(params?.senara_score) params?.senara_score?.map((i)=>{query +=`&senara_score[]=${i}`})
      if(params?.channel_id) params?.channel_id?.map((i)=>{query +=`&channel_id[]=${i}`})      
      if(params?.cohort_id) params?.cohort_id?.map((i)=>{query +=`&cohort_id[]=${i}`})
      if(params?.persona_id) params?.persona_id?.map((i)=>{query +=`&persona_id[]=${i}`})
      if(params?.purchased_suite_type) params?.purchased_suite_type?.map((i)=>{query +=`&purchased_suite_type[]=${i}`})
      if(params?.purchased_suite_price) params?.purchased_suite_price?.map((i)=>{query +=`&purchased_suite_price[]=${i}`})
      const { result, error } = yield call(getLocationMap,query)
      if(result) yield put(getLocationMapSuccess(result))
      if(error) yield put(getLocationMapFail(error))
    } catch (error) {
      yield put(getLocationMapFail(error))
    }
  }

  function* fetchAllMapChannel({payload: id}) {
    try {
      const { result, error } = yield call(getAllMapChannel,id)
      if(result) yield put(getAllMapChannelSuccess(result))
      if(error) yield put(getAllMapChannelFail(error))
    } catch (error) {
      yield put(getAllMapChannelFail(error))
    }
  }
  
  function* fetchAllMapPersona({payload: id}) {
    try {
      const { result, error } = yield call(getAllMapPersona,id)
      if(result) yield put(getAllMapPersonaSuccess(result))
      if(error) yield put(getAllMapPersonaFail(error))
    } catch (error) {
      yield put(getAllMapPersonaFail(error))
    }
  }
  
  function* fetchAllMapCohort({payload: id}) {
    try {
      const { result, error } = yield call(getAllMapCohort,id)
      if(result) yield put(getAllMapCohortSuccess(result))
      if(error) yield put(getAllMapCohortFail(error))
    } catch (error) {
      yield put(getAllMapCohortFail(error))
    }
  }

export function* watchLeadsgeneration() {
    yield takeEvery(GET_LEADS_GENERATION, fetchAllLeadsGeneration),
    yield takeEvery(GET_COST_PER_LEAD, fetchAllCostPerLead),
    yield takeEvery(GET_LEADS_GENERATION_SPENDS, fetchAllLeadsGenerationSpend),
    yield takeEvery(GET_CHANNEL_CHART, fetchAllChannelChart),
    yield takeEvery(GET_PERSONA_CHART, fetchAllPersonaChart),
    yield takeEvery(GET_COHORT_CHART, fetchAllCohortChart),
    yield takeEvery(GET_LEADS_GENERATION_REPORT, fetchAllLeadsgenerationReport),
    yield takeEvery(GET_LEADS_COST_PERFORMANCE, fetchAllLeadCostPerformance),
    yield takeEvery(GET_LOCATION_REPORT, fetchLocationReport),
    yield takeEvery(GET_LOCATION_MAP, fetchLocationMapData),
    yield takeEvery(GET_ALL_MAP_CHANNEL, fetchAllMapChannel),
    yield takeEvery(GET_ALL_MAP_PERSONA, fetchAllMapPersona),
    yield takeEvery(GET_ALL_MAP_COHORT, fetchAllMapCohort)
  }

  function* LeadsgenerationSaga() {
    yield all([fork(watchLeadsgeneration)])
  }
  
  export default LeadsgenerationSaga