import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { withRouter, Link } from "react-router-dom"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const userData = JSON.parse(localStorage.getItem("authUser"))?.data
  const profilePicture = userData?.profile_photo
  const firstNameInAvatar = userData?.first_name?.substr(0, 1).toUpperCase()
  const lastNameInAvatar = userData?.last_name?.substr(0, 1).toUpperCase()

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item custom-align-avatar"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user">
            <span
              className={
                "avatar-title rounded-circle bg-soft bg-" +
                "primary" +
                " text-" +
                "primary" +
                " font-size-16"
              }
            >
              {profilePicture ? (
                <img src={profilePicture} className="profile-picture" />
              ) : (
                `${firstNameInAvatar ? firstNameInAvatar : ""}${lastNameInAvatar ? lastNameInAvatar : ""}`
              )}
            </span>
          </div>
          <span className="d-none d-xl-inline-block ms-2 me-1 text-heading-light">
            {userData?.first_name}
            <span style={{ marginLeft: "5px" }}>{userData?.last_name}</span>
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {"Profile"}{" "}
          </Link>
          <Link to="/change-password" className="dropdown-item">
            <i className="bx bxs-key font-size-16 align-middle me-1" />
            {"Change password"}
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(ProfileMenu)
