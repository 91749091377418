import {
    CAMPAIGN_ID,
    GET_LEADS_CREATIVE_VISITS,
    GET_LEADS_CREATIVE_VISITS_SUCCESS,
    GET_LEADS_CREATIVE_VISITS_FAIL,
    GET_CLICK_THROUGH_RATE,
    GET_CLICK_THROUGH_RATE_SUCCESS,
    GET_CLICK_THROUGH_RATE_FAIL,
    GET_COST_PER_CLICK,
    GET_COST_PER_CLICK_SUCCESS,
    GET_COST_PER_CLICK_FAIL,
    GET_CONCEPT_CHART,
    GET_CONCEPT_CHART_SUCCESS,
    GET_CONCEPT_CHART_FAIL,
    GET_VISUAL_CHART,
    GET_VISUAL_CHART_SUCCESS,
    GET_VISUAL_CHART_FAIL,
    GET_COPY_STRATEGY_CHART,
    GET_COPY_STRATEGY_SUCCESS,
    GET_COPY_STRATEGY_FAIL,
    GET_TAG_LINE_CHART,
    GET_TAG_LINE_SUCCESS,
    GET_TAG_LINE_FAIL,
    GET_TOP_ADS,
    GET_TOP_ADS_SUCCESS,
    GET_TOP_ADS_FAIL,
    GET_TOP_ADS_DETAILS,
    GET_TOP_ADS_DETAILS_SUCCESS,
    GET_TOP_ADS_DETAILS_FAIL,
    GET_CREATIVE_REPORT,
    GET_CREATIVE_REPORT_SUCCESS,
    GET_CREATIVE_REPORT_FAIL
} from "./actionTypes"

export const setCampaignId = id => ({
    type: CAMPAIGN_ID,
    payload: id
})

export const getLeadCreationVisits = id => ({
    type: GET_LEADS_CREATIVE_VISITS,
    payload: id
})

export const getLeadCreationVisitsSuccess = data => ({
    type: GET_LEADS_CREATIVE_VISITS_SUCCESS,
    payload: data,
})

export const getLeadCreationVisitsFail = error => ({
    type: GET_LEADS_CREATIVE_VISITS_FAIL,
    payload: error,
})

export const getAllClickThroughRate = id => ({
    type: GET_CLICK_THROUGH_RATE,
    payload: id
})

export const getClickThroughRateSuccess = data => ({
    type: GET_CLICK_THROUGH_RATE_SUCCESS,
    payload: data,
})

export const getClickThroughRateFail = error => ({
    type: GET_CLICK_THROUGH_RATE_FAIL,
    payload: error,
})

export const getAllCostPerClick = id => ({
    type: GET_COST_PER_CLICK,
    payload: id
})

export const getCostPerClickSuccess = data => ({
    type: GET_COST_PER_CLICK_SUCCESS,
    payload: data,
})

export const getCostPerClickFail = error => ({
    type: GET_COST_PER_CLICK_FAIL,
    payload: error,
})

export const getAllConceptChart = params => ({
    type: GET_CONCEPT_CHART,
    payload: params
})

export const getConceptChartSuccess = data => ({
    type: GET_CONCEPT_CHART_SUCCESS,
    payload: data,
})

export const getConceptChartFail = error => ({
    type: GET_CONCEPT_CHART_FAIL,
    payload: error,
})

export const getAllVisualChart = params => ({
    type: GET_VISUAL_CHART,
    payload: params
})

export const getVisualChartSuccess = data => ({
    type: GET_VISUAL_CHART_SUCCESS,
    payload: data,
})

export const getVisualChartFail = error => ({
    type: GET_VISUAL_CHART_FAIL,
    payload: error,
})

export const getAllCopyStrategyChart = params => ({
    type: GET_COPY_STRATEGY_CHART,
    payload: params
})

export const getCopyStrategyChartSuccess = data => ({
    type: GET_COPY_STRATEGY_SUCCESS,
    payload: data,
})

export const getCopyStrategyChartFail = error => ({
    type: GET_COPY_STRATEGY_FAIL,
    payload: error,
})

export const getAllTagLineChart = params => ({
    type: GET_TAG_LINE_CHART,
    payload: params
})

export const getTagLineChartSuccess = data => ({
    type: GET_TAG_LINE_SUCCESS,
    payload: data,
})

export const getTagLineChartFail = error => ({
    type: GET_TAG_LINE_FAIL,
    payload: error,
})

export const getAllTopAds = params => ({
    type: GET_TOP_ADS,
    payload: params
  })
  
  export const getTopAdsSuccess = data => ({
    type: GET_TOP_ADS_SUCCESS,
    payload: data,
  })
  
  export const getTopAdsFail = error => ({
    type: GET_TOP_ADS_FAIL,
    payload: error,
  })

  export const getAllTopAdsDetails = params => ({
    type: GET_TOP_ADS_DETAILS,
    payload: params
  })
  
  export const getTopAdsDetailsSuccess = data => ({
    type: GET_TOP_ADS_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const getTopAdsDetailsFail = error => ({
    type: GET_TOP_ADS_DETAILS_FAIL,
    payload: error,
  })


  export const getCreativeReport = params => ({
    type: GET_CREATIVE_REPORT,
    payload: params
  })
  
  export const getCreativeReportSuccess = data => ({
    type: GET_CREATIVE_REPORT_SUCCESS,
    payload: data,
  })
  
  export const getCreativeReportFail = error => ({
    type: GET_CREATIVE_REPORT_FAIL,
    payload: error,
  })