import {
  SAVE_MEDIA_INTAKE,
  SAVE_MEDIA_INTAKE_SUCCESSFUL,
  SAVE_MEDIA_INTAKE_FAIL,
  GET_MEDIA_INTAKE_SUCCESS,
  GET_MEDIA_INTAKE_FAIL,
  UPDATE_MEDIA_INTAKE_SUCCESS,
  UPDATE_MEDIA_INTAKE_FAIL,
  DELETE_MEDIA_INTAKE_SUCCESS,
  DELETE_MEDIA_INTAKE_FAIL,
  SAVE_SALES_INTAKE,
  SAVE_SALES_INTAKE_SUCCESSFUL,
  SAVE_SALES_INTAKE_FAIL,
  GET_SALES_INTAKE_SUCCESS,
  GET_SALES_INTAKE_FAIL,
  SAVE_CREATIVE_INTAKE_SUCCESSFUL,
  SAVE_CREATIVE_INTAKE_FAIL,
  GET_CREATIVE_INTAKE_SUCCESS,
  GET_CREATIVE_INTAKE_FAIL,
  SAVE_TECHNICAL_INTAKE,
  SAVE_TECHNICAL_INTAKE_SUCCESSFUL,
  SAVE_TECHNICAL_INTAKE_FAIL,
  GET_TECHNICAL_INTAKE_SUCCESS,
  GET_TECHNICAL_INTAKE_FAIL,
  DELETE_SALES_INTAKE_SUCCESS,
  DELETE_SALES_INTAKE_FAIL,
  DELETE_TECHNICAL_INTAKE_SUCCESS,
  DELETE_TECHNICAL_INTAKE_FAIL,
  DELETE_CREATIVE_INTAKE_SUCCESS,
  DELETE_CREATIVE_INTAKE_FAIL
} from "./actionTypes"

const initialState = {
  saveMediaIntakeSuccess: null,
  saveMediaIntakeError: null,
  updateMediaIntakeSuccess: null,
  updateMediaIntakeFail: null,
  deleteMediaIntakeSuccess: null,
  deleteMediaIntakeFail: null,
  all_media_intake: null,
  saveSalesIntakeSuccess: null,
  saveSalesIntakeError: null,
  all_sales_intake: null,
  deleteSalesIntakeSuccess: null,
  deleteSalesIntakeFail: null,
  saveCreativeIntakeSuccess: null,
  saveCreativeIntakeError: null,
  all_creative_intake: null,
  deleteCreativeIntakeSuccess: null,
  deleteCreativeIntakeFail: null,
  saveTechnicalIntakeSuccess: null,
  saveTechnicalIntakeError: null,
  all_technical_intake: null,
  deleteTechnicalIntakeSuccess: null,
  deleteTechnicalIntakeFail: null,
  loading: false,
  data: null,
}

const intake = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MEDIA_INTAKE:
      state = {
        ...state,
        loading: true,
        saveMediaIntakeError: null,
      }
      break
    case SAVE_MEDIA_INTAKE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        saveMediaIntakeSuccess: action.payload,
        saveMediaIntakeError: null,
      }
      break
    case SAVE_MEDIA_INTAKE_FAIL:
      state = {
        ...state,
        data: null,
        loading: false,
        saveMediaIntakeError: action.payload,
      }
      break
    case GET_MEDIA_INTAKE_SUCCESS:
        state = {
          ...state,
          all_media_intake: action.payload,
        }
        break
    case GET_MEDIA_INTAKE_FAIL:
        state = {
          ...state,
          all_media_intake: null,
          error: action.payload,
        }
        break
    case UPDATE_MEDIA_INTAKE_SUCCESS:
        state = {
          ...state,
          updateMediaIntakeSuccess: action.payload,
          updateMediaIntakeFail: null
        }
        break
    case UPDATE_MEDIA_INTAKE_FAIL:
        state = {
        ...state,
        updateMediaIntakeSuccess: null,
        updateMediaIntakeFail: action.payload,
       }
      break
    case DELETE_MEDIA_INTAKE_SUCCESS:
        state = {
          ...state,
          deleteMediaIntakeSuccess: action.payload,
          deleteMediaIntakeFail: null
        }
        break
    case DELETE_MEDIA_INTAKE_FAIL:
        state = {
          ...state,
          deleteMediaIntakeSuccess: null,
          deleteMediaIntakeFail: action.payload,
        }
        break
    case SAVE_SALES_INTAKE:
        state = {
          ...state,
          loading: true,
          saveSalesIntakeError: null,
        }
        break
    case SAVE_SALES_INTAKE_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          saveSalesIntakeSuccess: action.payload,
          saveSalesIntakeError: null,
        }
        break
    case SAVE_SALES_INTAKE_FAIL:
        state = {
          ...state,
          data: null,
          loading: false,
          saveSalesIntakeError: action.payload,
        }
        break
    case GET_SALES_INTAKE_SUCCESS:
        state = {
         ...state,
         all_sales_intake: action.payload,
        }
        break
    case GET_SALES_INTAKE_FAIL:
        state = {
          ...state,
          all_sales_intake: null,
          error: action.payload,
        }
        break
    case SAVE_CREATIVE_INTAKE_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          saveCreativeIntakeSuccess: action.payload,
          saveCreativeIntakeError: null,
        }
        break
    case SAVE_CREATIVE_INTAKE_FAIL:
        state = {
          ...state,
          data: null,
          loading: false,
          saveCreativeIntakeError: action.payload,
        }
        break
    case GET_CREATIVE_INTAKE_SUCCESS:
          state = {
            ...state,
            all_creative_intake: action.payload,
          }
          break
    case GET_CREATIVE_INTAKE_FAIL:
          state = {
            ...state,
            all_creative_intake: null,
            error: action.payload,
          }
        break
        case SAVE_TECHNICAL_INTAKE:
        state = {
          ...state,
          loading: true,
          saveTechnicalIntakeError: null,
        }
        break
    case SAVE_TECHNICAL_INTAKE_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          saveTechnicalIntakeSuccess: action.payload,
          saveTechnicalIntakeError: null,
        }
        break
    case SAVE_TECHNICAL_INTAKE_FAIL:
        state = {
          ...state,
          data: null,
          loading: false,
          saveTechnicalIntakeError: action.payload,
        }
        break
    case GET_TECHNICAL_INTAKE_SUCCESS:
        state = {
          ...state,
          all_technical_intake: action.payload,
        }
          break
    case GET_TECHNICAL_INTAKE_FAIL:
        state = {
          ...state,
          all_technical_intake: null,
          error: action.payload,
        }
        break
    case DELETE_SALES_INTAKE_SUCCESS:
        state = {
          ...state,
          deleteSalesIntakeSuccess: action.payload,
          deleteSalesIntakeFail: null
        }
        break
    case DELETE_SALES_INTAKE_FAIL:
        state = {
          ...state,
          deleteSalesIntakeSuccess: null,
          deleteSalesIntakeFail: action.payload,
        }
        break
    case DELETE_TECHNICAL_INTAKE_SUCCESS:
        state = {
          ...state,
          deleteTechnicalIntakeSuccess: action.payload,
          deleteTechnicalIntakeFail: null
        }
        break
    case DELETE_TECHNICAL_INTAKE_FAIL:
        state = {
          ...state,
          deleteTechnicalIntakeSuccess: null,
          deleteTechnicalIntakeFail: action.payload,
        }
        break
    case DELETE_CREATIVE_INTAKE_SUCCESS:
          state = {
            ...state,
            deleteCreativeIntakeSuccess: action.payload,
            deleteCreativeIntakeFail: null
          }
          break
    case DELETE_CREATIVE_INTAKE_FAIL:
          state = {
            ...state,
            deleteCreativeIntakeSuccess: null,
            deleteCreativeIntakeFail: action.payload,
          }
          break
    default:
      state = { ...state }
      break
  }
  return state
}
export default intake
