import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import {
  GET_ALL_USERS,
  REGISTER_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_GROUP,
  GET_ROLE,
  GET_ROLE_TYPE,
  UPDATE_PROFILE_PHOTO,
  RESET_ROLE_TYPE,
} from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  getUsersSuccess,
  getUsersFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getGroupSuccess,
  getGroupFail,
  getRoleSuccess,
  getRoleFail,
  getRoleTypeSuccess,
  getRoleTypeError,
  updateProfilePhotoSuccess,
  updateProfilePhotoError,
} from "./actions"
import {
  createNewUser,
  deleteUser,
  getAllUsers,
  updateUser,
  getGroup,
  getRole,
  getRoleType,
  updateProfilePhoto,
} from "helpers/senara_backend/senarabackend_helper"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const { result, error } = yield call(createNewUser, user)
    //console.log('create user response===>', result, error)
    if (result) yield put(registerUserSuccessful(result))
    if (error) yield put(registerUserFailed(error))
  } catch (error) {
    //console.log('create user error===>', error)
    yield put(registerUserFailed(error))
  }
}
function* fetchAllUsers() {
  try {
    const { result, error } = yield call(getAllUsers)
    //console.log('in get all users saga==>', result, error)
    if (result) yield put(getUsersSuccess(result))
    if (error) yield put(getUsersFail(error))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const { result, error } = yield call(updateUser, user)
    if (result) yield put(updateUserSuccess(result))
    if (error) yield put(updateUserFail(error))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const { result, error } = yield call(deleteUser, user)
    if (result) yield put(deleteUserSuccess(result))
    if (error) yield put(deleteUserFail(error))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* getUserGroup() {
  try {
    const { result, error } = yield call(getGroup)
    if (result) yield put(getGroupSuccess(result.data))
    if (error) yield put(getGroupFail(error))
  } catch (e) {
    yield put(getGroupFail(e))
  }
}

function* getUserRole({ payload: group_id }) {
  try {
    const { result, error } = yield call(getRole, group_id)
    if (result) yield put(getRoleSuccess(result.data))
    if (error) yield put(getRoleFail(error))
  } catch (error) {
    yield put(getRoleFail(error))
  }
}

function* getUserRoleType({ payload: role_id }) {
  try {
    const { result, error } = yield call(getRoleType, role_id)
    if (result) yield put(getRoleTypeSuccess(result.data))
    if (error) yield put(getRoleTypeError(error))
  } catch (error) {
    yield put(getRoleTypeError(error))
  }
}

function* resetRoleTypes({ payload }) {
  return payload
}

function* updateProfilePhotos({ payload: url }) {
  try {
    const { result, error } = yield call(updateProfilePhoto, url)
    if (result) yield put(updateProfilePhotoSuccess(result.data))
    if (error) yield put(updateProfilePhotoError(error))
  } catch (error) {
    yield put(updateProfilePhotoError(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser),
    yield takeEvery(GET_ALL_USERS, fetchAllUsers),
    yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(GET_GROUP, getUserGroup)
  yield takeEvery(GET_ROLE, getUserRole)
  yield takeEvery(GET_ROLE_TYPE, getUserRoleType)
  yield takeEvery(UPDATE_PROFILE_PHOTO, updateProfilePhotos)
  yield takeEvery(RESET_ROLE_TYPE, resetRoleTypes)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
