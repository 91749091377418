import {
   CAMPAIGN_ID,
   GET_END_USER_SALES,
   GET_END_USER_SALES_SUCCESS,
   GET_END_USER_SALES_FAIL,
   GET_UNREPRESENTED_RATIO,
   GET_UNREPRESENTED_RATIO_SUCCESS,
   GET_UNREPRESENTED_RATIO_FAIL,
   GET_COMMISSIONS_SAVED,
   GET_COMMISSIONS_SAVED_SUCCESS,
   GET_COMMISSIONS_SAVED_FAIL,
   GET_REMAINING_INVENTORY_CHART,
   GET_REMAINING_INVENTORY_CHART_SUCCESS,
   GET_REMAINING_INVENTORY_CHART_FAIL,
   GET_DEMAND_RATIO_CHART,
   GET_DEMAND_RATIO_CHART_SUCCESS,
   GET_DEMAND_RATIO_CHART_FAIL,
   GET_REMAINING_INVENTORY_REPORT,
   GET_REMAINING_INVENTORY_REPORT_SUCCESS,
   GET_REMAINING_INVENTORY_REPORT_FAIL,
   GET_DEMAND_RATIO_REPORT,
   GET_DEMAND_RATIO_REPORT_SUCCESS,
   GET_DEMAND_RATIO_REPORT_FAIL
} from "./actionTypes.js"

export const setCampaignId = id => ({
    type: CAMPAIGN_ID,
    payload: id
})

export const getEndUserSales = id => ({
    type: GET_END_USER_SALES,
    payload: id
})

export const getEndUserSalesSuccess = data => ({
    type: GET_END_USER_SALES_SUCCESS,
    payload: data,
})

export const getEndUserSalesFail = error => ({
    type: GET_END_USER_SALES_FAIL,
    payload: error,
})

export const getUnrepresentedRatio = id => ({
    type: GET_UNREPRESENTED_RATIO,
    payload: id
})

export const getUnrepresentedRatioSuccess = data => ({
    type: GET_UNREPRESENTED_RATIO_SUCCESS,
    payload: data,
})

export const getUnrepresentedRatioFail = error => ({
    type: GET_UNREPRESENTED_RATIO_FAIL,
    payload: error,
})

export const getCommissionsSaved = id => ({
    type: GET_COMMISSIONS_SAVED,
    payload: id
})

export const getCommissionsSavedSuccess = data => ({
    type: GET_COMMISSIONS_SAVED_SUCCESS,
    payload: data,
})

export const getCommissionsSavedFail = error => ({
    type: GET_COMMISSIONS_SAVED_FAIL,
    payload: error,
})

export const getAllRemainingInventoryChart = id => ({
    type: GET_REMAINING_INVENTORY_CHART,
    payload: id
  })
  
  export const getAllRemainingInventoryChartSuccess = data => ({
    type: GET_REMAINING_INVENTORY_CHART_SUCCESS,
    payload: data,
  })
  
  export const getAllRemainingInventoryChartFail = error => ({
    type: GET_REMAINING_INVENTORY_CHART_FAIL,
    payload: error,
  })

  export const getAllDemandRatioChart= id => ({
    type: GET_DEMAND_RATIO_CHART,
    payload: id
  })
  
  export const getAllDemandRatioChartSuccess = data => ({
    type: GET_DEMAND_RATIO_CHART_SUCCESS,
    payload: data,
  })
  
  export const getAllDemandRatioChartFail = error => ({
    type: GET_DEMAND_RATIO_CHART_FAIL,
    payload: error,
  })

  export const getAllRemainingInventoryReport = id => ({
    type: GET_REMAINING_INVENTORY_REPORT,
    payload: id
  })
  
  export const getRemainingInventoryReportSuccess = data => ({
    type: GET_REMAINING_INVENTORY_REPORT_SUCCESS,
    payload: data,
  })
  
  export const getRemainingInventoryReportFail = error => ({
    type: GET_REMAINING_INVENTORY_REPORT_FAIL,
    payload: error,
  })

  export const getAllDemandRatioReport = id => ({
    type: GET_DEMAND_RATIO_REPORT,
    payload: id
  })
  
  export const getDemandRatioReportSuccess = data => ({
    type: GET_DEMAND_RATIO_REPORT_SUCCESS,
    payload: data,
  })
  
  export const getDemandRatioReportFail = error => ({
    type: GET_DEMAND_RATIO_REPORT_FAIL,
    payload: error,
  })