/**
 * Add campaign
 */
export const CAMPAIGN_CREATE = "campaign_create"
export const CAMPAIGN_CREATE_SUCCESSFUL = "campaign_create_successfull"
export const CAMPAIGN_CREATE_FAILED = "campaign_create_failed"

/**
 * Get campaign
 */
export const GET_ALL_CAMPAIGN = "get_all_campaign"
export const GET_ALL_CAMPAIGN_SUCCESS = "get_all_campaign_success"
export const GET_ALL_CAMPAIGN_FAIL = "get_all_campaign_fail"

/**
 * Get campaign by ID
 */
 export const GET_CAMPAIGN_BY_ID = "GET_CAMPAIGN_BY_ID"
 export const GET_CAMPAIGN_BY_ID_SUCCESS = "GET_CAMPAIGN_BY_ID_SUCCESS"
 export const GET_CAMPAIGN_BY_ID_FAIL = "GET_CAMPAIGN_BY_ID_FAIL"

/**
 * Edit user
 */
 export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
 export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
 export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"

/**
 * Delete campaign
 */
 export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
 export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
 export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL"

 /**
 * Get Flight Phase
 */
  export const GET_ALL_FLIGHT_PHASE = "GET_ALL_FLIGHT_PHASE"
  export const GET_ALL_FLIGHT_PHASE_SUCCESS = "GET_ALL_FLIGHT_PHASE_SUCCESS"
  export const GET_ALL_FLIGHT_PHASE_FAIL = "GET_ALL_FLIGHT_PHASE_FAIL"

  /**
 * Get Cover Photo
 */
 export const UPLOAD_COVER_PHOTO = "UPLOAD_COVER_PHOTO"
 export const UPLOAD_COVER_PHOTO_SUCCESS = "UPLOAD_COVER_PHOTO_SUCCESS"
 export const UUPLOAD_COVER_PHOTO_FAIL = "UUPLOAD_COVER_PHOTO_FAIL"

 /**
 * Get campaign
 */
export const GET_ALL_VIEW_PERMISSION = "GET_ALL_VIEW_PERMISSION"
export const GET_ALL_VIEW_PERMISSION_SUCCESS = "GET_ALL_VIEW_PERMISSION_SUCCESS"
export const GET_ALL_VIEW_PERMISSION_FAIL = "GET_ALL_VIEW_PERMISSION_FAIL"

 /**
 * Save Get campaign
 */
  export const SAVE_VIEW_PERMISSIONE = "SAVE_VIEW_PERMISSIONE"
  export const SAVE_VIEW_PERMISSIONE_SUCCESSFUL = "SAVE_VIEW_PERMISSIONE_SUCCESSFUL"
  export const SAVE_VIEW_PERMISSIONE_FAIL = "SAVE_VIEW_PERMISSIONE_FAIL"


 /**
 * Get Countries
 */
  export const GET_ALL_COUNTRIES  = "GET_ALL_COUNTRIES"
  export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES_SUCCESS"
  export const GET_ALL_COUNTRIES_FAIL = "GET_ALL_COUNTRIES_FAIL"

/**
 * Get Personalize
 */
export const GET_CAMPAIGN_PERSONALIZE = "GET_CAMPAIGN_PERSONALIZE"
export const GET_CAMPAIGN_PERSONALIZE_SUCCESS = "GET_CAMPAIGN_PERSONALIZE_SUCCESS"
export const GET_CAMPAIGN_PERSONALIZE_FAIL = "GET_CAMPAIGN_PERSONALIZE_FAIL"

/**
 * Get Suggections
*/
export const GET_ADDRESS_SUGGESTIONS = "GET_ADDRESS_SUGGESTIONS"
export const GET_ADDRESS_SUGGESTIONS_SUCCESS = "GET_ADDRESS_SUGGESTIONS_SUCCESS"
export const GET_ADDRESS_SUGGESTIONS_FAIL = "GET_ADDRESS_SUGGESTIONS_FAIL"
