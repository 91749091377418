import {
    CAMPAIGN_ID,
    GET_LEADS,
    GET_LEADS_SUCCESS,
    GET_LEADS_FAIL,
    GET_QUALIFIED_LEADS,
    GET_QUALIFIED_LEADS_SUCCESS,
    GET_QUALIFIED_LEADS_SUCCESS_FAIL,
    GET_FIRM_SALES,
    GET_FIRM_SALES_SUCCESS,
    GET_FIRM_SALES_SUCCESS_FAIL,
    GET_SPENDS,
    GET_SPENDS_SUCCESS,
    GET_SPENDS_SUCCESS_FAIL,
    GET_APPOINTMENTS_HELD,
    GET_APPOINTMENTS_HELD_SUCCESS,
    GET_APPOINTMENTS_HELD_SUCCESS_FAIL,
    GET_REVENUE,
    GET_REVENUE_SUCCESS,
    GET_REVENUE_SUCCESS_FAIL,
    GET_SALES_PIPELINE_PROGRESSION,
    GET_SALES_PIPELINE_PROGRESSION_SUCCESS,
    GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL,
    GET_SALES_PROSPECTS,
    GET_SALES_PROSPECTS_SUCCESS,
    GET_SALES_PROSPECTS_FAIL,
    GET_SALES_PROSPECT_IQ,
    GET_SALES_PROSPECT_IQ_SUCCESS,
    GET_SALES_PROSPECT_IQ_FAIL
  } from "./actionTypes"

  export const setCampaignId = id => ({
    type:CAMPAIGN_ID,
    payload: id
  })
  export const getLeads = id => ({
    type: GET_LEADS,
    payload: id
  })
  
  export const getLeadsSuccess = data => ({
    type: GET_LEADS_SUCCESS,
    payload: data,
  })
  
  export const getLeadsFail = error => ({
    type: GET_LEADS_FAIL,
    payload: error,
  })

  export const getAllQualifiedLeads = id => ({
    type: GET_QUALIFIED_LEADS,
    payload: id
  })
  
  export const getQualifiedLeadsSuccess = data => ({
    type: GET_QUALIFIED_LEADS_SUCCESS,
    payload: data,
  })
  
  export const getQualifiedLeadsFail = error => ({
    type: GET_QUALIFIED_LEADS_SUCCESS_FAIL,
    payload: error,
  })

  export const getAllFirmSales = id => ({
    type: GET_FIRM_SALES,
    payload: id
  })
  
  export const getFirmSalesSuccess = data => ({
    type: GET_FIRM_SALES_SUCCESS,
    payload: data,
  })
  
  export const getFirmSalesFail = error => ({
    type: GET_FIRM_SALES_SUCCESS_FAIL,
    payload: error,
  })

  export const getAllSpends = id => ({
    type: GET_SPENDS,
    payload: id
  })
  
  export const getSpendsSuccess = data => ({
    type: GET_SPENDS_SUCCESS,
    payload: data,
  })
  
  export const getSpendsFail = error => ({
    type: GET_SPENDS_SUCCESS_FAIL,
    payload: error,
  })

  export const getAllAppointmentsHeld = id => ({
    type: GET_APPOINTMENTS_HELD,
    payload: id
  })
  
  export const getAppointmentsHeldSuccess = data => ({
    type: GET_APPOINTMENTS_HELD_SUCCESS,
    payload: data,
  })
  
  export const getAppointmentsHeldFail = error => ({
    type: GET_APPOINTMENTS_HELD_SUCCESS_FAIL,
    payload: error,
  })
  export const getAllRevenue = id => ({
    type: GET_REVENUE,
    payload: id
  })
  
  export const getRevenueSuccess = data => ({
    type: GET_REVENUE_SUCCESS,
    payload: data,
  })
  
  export const getRevenueFail = error => ({
    type: GET_REVENUE_SUCCESS_FAIL,
    payload: error,
  })

  export const getAllSalesPipelineProgression = params => ({
    type: GET_SALES_PIPELINE_PROGRESSION,
    payload: params
  })
  
  export const getSalesPipelineProgressionSuccess = data => ({
    type: GET_SALES_PIPELINE_PROGRESSION_SUCCESS,
    payload: data,
  })
  
  export const getSalesPipelineProgressionFail = error => ({
    type: GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL,
    payload: error,
  })

  export const getAllSalesProspects = id => ({
    type: GET_SALES_PROSPECTS,
    payload: id
  })
  
  export const getSalesProspectsSuccess = data => ({
    type: GET_SALES_PROSPECTS_SUCCESS,
    payload: data,
  })
  
  export const getSalesProspectsFail = error => ({
    type: GET_SALES_PROSPECTS_FAIL,
    payload: error,
  })

  export const getAllSalesProspectIQDetails = params => ({
    type: GET_SALES_PROSPECT_IQ,
    payload: params
  })
  
  export const getAllSalesProspectIQDetailsSuccess = data => ({
    type: GET_SALES_PROSPECT_IQ_SUCCESS,
    payload: data,
  })
  
  export const getAllSalesProspectIQDetailsFail = error => ({
    type: GET_SALES_PROSPECT_IQ_FAIL,
    payload: error,
  })