import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { UPLOAD_IMAGES,UPLOAD_FILES } from "./actionTypes"
import { uploadImagesSuccess, uploadImagesFail,uploadFilesSuccess,uploadFilesFail,updateLoadingFiles, removeLoadingFiles } from "./actions"
import { uploadImages,updateFilesPut } from "helpers/senara_backend/senarabackend_helper"

function* addImages({ payload }) {
  const { file } = payload
  try {
    const { result, error } = yield call(uploadImages, {file})
    //console.log('Upload Images Saga response===>', result, error)
    if (result) {
      yield put(uploadImagesSuccess({...result,original:file}))
    }
    if (error) {
      yield put(uploadImagesFail({...error,original:file}))
    }
  } catch (error) {
    //console.log("upload images error===>", error)
    yield put(uploadImagesFail({...error,original:file}))
  }
}

function* addfiles({ payload }) {
  const {url, file} = payload
  try {
    yield put(updateLoadingFiles(file.localId))
    const { result, error } = yield call(updateFilesPut,{url,file})
    if (result) {
      yield put(removeLoadingFiles(file.localId))
      yield put(uploadFilesSuccess({...result}))
    }
    if (error) {
      yield put(removeLoadingFiles(file.localId))
      yield put(uploadFilesFail({...error}))
    }
  } catch (error) {
    yield put(removeLoadingFiles(file.localId))
    yield put(uploadFilesFail({...error}))
  }
}

export function* watchUploadImages() {
  yield takeEvery(UPLOAD_IMAGES, addImages)
  yield takeEvery(UPLOAD_FILES, addfiles)
}

function* imagesSaga() {
  yield all([fork(watchUploadImages)])
}

export default imagesSaga
