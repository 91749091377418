import { 
    SAVE_EMAIL_NURTURING,
    SAVE_EMAIL_NURTURING_SUCCESSFUL,
    SAVE_EMAIL_NURTURING_FAIL,
    GET_EMAIL_NURTURING,
    GET_EMAIL_NURTURING_SUCCESS,
    GET_EMAIL_NURTURING_FAIL,
    DELETE_EMAIL_NURTURING,
    DELETE_EMAIL_NURTURING_SUCCESS,
    DELETE_EMAIL_NURTURING_FAIL
    } from "./actionTypes";
   
export const saveEmailNurturing = params => {
    return {
    type: SAVE_EMAIL_NURTURING,
    payload: params,
   }
}
   
export const saveEmailNurturingSuccess = data => {
    return {
      type: SAVE_EMAIL_NURTURING_SUCCESSFUL,
      payload: data,
    }
}
   
export const saveEmailNurturingFail = data => {
    return {
      type: SAVE_EMAIL_NURTURING_FAIL,
      payload: data,
    }
}

export const getEmailNurturing = id => ({
  type: GET_EMAIL_NURTURING,
  payload: id
})

export const getEmailNurturingSuccess = data => ({
  type: GET_EMAIL_NURTURING_SUCCESS,
  payload: data,
})

export const getEmailNurturingFail = error => ({
  type: GET_EMAIL_NURTURING_FAIL,
  payload: error,
})

export const deleteEmailNurturing = data => ({
  type: DELETE_EMAIL_NURTURING,
  payload: data,
})

export const deleteEmailNurturingSuccess = data => ({
  type: DELETE_EMAIL_NURTURING_SUCCESS,
  payload: data,
})

export const deleteEmailNurturingFail = error => ({
  type: DELETE_EMAIL_NURTURING_FAIL,
  payload: error,
})