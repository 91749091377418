import {
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_ERROR,
} from "./actionTypes"

export const verify = (user, history) => {
  console.log(user)
  return {
    type: VERIFY_PASSWORD,
    payload: { user, history },
  }
}

export const verifySuccess = message => {
  return {
    type: VERIFY_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const verifyError = message => {
  return {
    type: VERIFY_PASSWORD_ERROR,
    payload: message,
  }
}
