import {
    GET_LEADS_SUCCESS,
    GET_LEADS_FAIL,
    GET_QUALIFIED_LEADS_SUCCESS,
    GET_QUALIFIED_LEADS_SUCCESS_FAIL,
    GET_FIRM_SALES_SUCCESS,
    GET_FIRM_SALES_SUCCESS_FAIL,
    GET_SPENDS_SUCCESS,
    GET_SPENDS_SUCCESS_FAIL,
    GET_APPOINTMENTS_HELD_SUCCESS,
    GET_APPOINTMENTS_HELD_SUCCESS_FAIL,
    GET_REVENUE_SUCCESS,
    GET_REVENUE_SUCCESS_FAIL,
    GET_SALES_PIPELINE_PROGRESSION_SUCCESS,
    GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL,
    CAMPAIGN_ID,
    GET_SALES_PROSPECTS_SUCCESS,
    GET_SALES_PROSPECTS_FAIL,
    GET_SALES_PROSPECT_IQ_SUCCESS,
    GET_SALES_PROSPECT_IQ_FAIL
  } from "./actionTypes"

  const initialState = {
    campaignId: sessionStorage.getItem('campaignId') || null,
    loading: false,
    all_leads: null,
    all_qualified_leads: null,
    all_firm_sales: null,
    all_spends: null,
    all_appointments_held: null,
    all_revenue: null,
    all_sales_pipeline_progression: null,
    all_sales_prospects: null,
    all_sales_prospects_iq: null,
  }

  const dashboard = (state = initialState, action) => {
    switch (action.type) {
      case CAMPAIGN_ID:
        sessionStorage.setItem('campaignId',action.payload)
        state = {
          ...state,
          campaignId: action.payload
        }
        break
      case GET_LEADS_SUCCESS:
        state = {
          ...state,
          all_leads: action.payload,
        }
        break
      case GET_LEADS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_QUALIFIED_LEADS_SUCCESS:
        state = {
          ...state,
          all_qualified_leads: action.payload,
        }
        break
      case GET_QUALIFIED_LEADS_SUCCESS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_FIRM_SALES_SUCCESS:
        state = {
          ...state,
          all_firm_sales: action.payload,
        }
        break
      case GET_FIRM_SALES_SUCCESS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_SPENDS_SUCCESS:
        state = {
          ...state,
          all_spends: action.payload,
        }
        break
      case GET_SPENDS_SUCCESS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_APPOINTMENTS_HELD_SUCCESS:
        state = {
          ...state,
          all_appointments_held: action.payload,
        }
        break
      case GET_APPOINTMENTS_HELD_SUCCESS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_REVENUE_SUCCESS:
        state = {
          ...state,
          all_revenue: action.payload,
        }
        break
      case GET_REVENUE_SUCCESS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_SALES_PIPELINE_PROGRESSION_SUCCESS:
          state = {
            ...state,
            all_sales_pipeline_progression: action.payload,
          }
          break
        case GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
            case GET_SALES_PROSPECTS_SUCCESS:
              state = {
                ...state,
                all_sales_prospects: action.payload,
              }
              break
            case GET_SALES_PROSPECTS_FAIL:
              state = {
                ...state,
                error: action.payload,
              }
          break
          case GET_SALES_PROSPECT_IQ_SUCCESS:
            state = {
              ...state,
              all_sales_prospects_iq: action.payload,
            }
          break
          case GET_SALES_PROSPECT_IQ_FAIL:
            state = {
            ...state,
            error: action.payload,
            }
          break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default dashboard