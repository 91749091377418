/**
 * Save Company
 */
export const SAVE_COMPANY = "SAVE_COMPANY"
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS"
export const SAVE_COMPANY_FAIL = "SAVE_COMPANY_FAIL"

/**
 * Get Company
 */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/**
 * Delete Company
 */
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

/**
 * Get Company Role
 */
 export const GET_ALL_COMPANY_ROLE = "GET_ALL_COMPANY_ROLE"
 export const GET_ALL_COMPANY_ROLE_SUCCESS = "GET_ALL_COMPANY_ROLE_SUCCESS"
 export const GET_ALL_COMPANY_ROLE_FAIL = "GET_ALL_COMPANY_ROLE_FAIL"

 /**
 * Get Location
 */
  export const GET_ALL_LOCATION = "GET_ALL_LOCATION"
  export const GET_ALL_LOCATION_SUCCESS = "GET_ALL_LOCATION_SUCCESS"
  export const GET_ALL_LOCATION_FAIL = "GET_ALL_LOCATION_FAIL"