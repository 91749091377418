export const CAMPAIGN_ID = "CAMPAIGN_ID"

/**
 * Get End User Sales
 */
 export const GET_END_USER_SALES = "GET_END_USER_SALES"
 export const GET_END_USER_SALES_SUCCESS = "GET_END_USER_SALES_SUCCESS"
 export const GET_END_USER_SALES_FAIL = "GET_END_USER_SALES_FAIL"

 /**
 * Get Unrepresented Ratio
 */
  export const GET_UNREPRESENTED_RATIO = "GET_UNREPRESENTED_RATIO"
  export const GET_UNREPRESENTED_RATIO_SUCCESS = "GET_UNREPRESENTED_RATIO_SUCCESS"
  export const GET_UNREPRESENTED_RATIO_FAIL = "GET_UNREPRESENTED_RATIO_FAIL"

   /**
 * Get Commissions Saved
 */
   export const GET_COMMISSIONS_SAVED = "GET_COMMISSIONS_SAVED"
   export const GET_COMMISSIONS_SAVED_SUCCESS = "GET_COMMISSIONS_SAVED_SUCCESS"
   export const GET_COMMISSIONS_SAVED_FAIL = "GET_COMMISSIONS_SAVED_FAIL"

    /**
 *  Remaining Inventory Chart
 */
  export const GET_REMAINING_INVENTORY_CHART = "GET_REMAINING_INVENTORY_CHART"
  export const GET_REMAINING_INVENTORY_CHART_SUCCESS = "GET_REMAINING_INVENTORY_CHART_SUCCESS"
  export const GET_REMAINING_INVENTORY_CHART_FAIL = "GET_REMAINING_INVENTORY_CHART_FAIL"

      /**
 *  Remaining Inventory Chart
 */
  export const GET_DEMAND_RATIO_CHART = "GET_DEMAND_RATIO_CHART"
  export const GET_DEMAND_RATIO_CHART_SUCCESS = "GET_DEMAND_RATIO_CHART_SUCCESS"
  export const GET_DEMAND_RATIO_CHART_FAIL = "GET_DEMAND_RATIO_CHART_FAIL"

    /**
 * Remaining Inventory Report
 */
export const GET_REMAINING_INVENTORY_REPORT = "GET_REMAINING_INVENTORY_REPORT"
export const GET_REMAINING_INVENTORY_REPORT_SUCCESS = "GET_REMAINING_INVENTORY_REPORT_SUCCESS"
export const GET_REMAINING_INVENTORY_REPORT_FAIL = "GET_REMAINING_INVENTORY_REPORT_FAIL"

    /**
 * Demand Ratio Report
 */
export const GET_DEMAND_RATIO_REPORT = "GET_DEMAND_RATIO_REPORT"
export const GET_DEMAND_RATIO_REPORT_SUCCESS = "GET_DEMAND_RATIO_REPORT_SUCCESS"
export const GET_DEMAND_RATIO_REPORT_FAIL = "GET_DEMAND_RATIO_REPORT_FAIL"
