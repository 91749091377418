export const CAMPAIGN_ID = "CAMPAIGN_ID"
/**
 * Get Leads
 */
 export const GET_LEADS = "GET_LEADS"
 export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
 export const GET_LEADS_FAIL = "GET_LEADS_FAIL"

 /**
 * Qualified leads
 */
  export const GET_QUALIFIED_LEADS = "GET_QUALIFIED_LEADS"
  export const GET_QUALIFIED_LEADS_SUCCESS = "GET_QUALIFIED_LEADS_SUCCESS"
  export const GET_QUALIFIED_LEADS_SUCCESS_FAIL = "GET_QUALIFIED_LEADS_SUCCESS_FAIL"

   /**
 * Firm Sales
 */
    export const GET_FIRM_SALES = "GET_FIRM_SALES"
    export const GET_FIRM_SALES_SUCCESS = "GET_FIRM_SALES_SUCCESS"
    export const GET_FIRM_SALES_SUCCESS_FAIL = "GET_FIRM_SALES_SUCCESS_FAIL"
  
 /**
 * Spends
 */
  export const GET_SPENDS = "GET_SPENDS"
  export const GET_SPENDS_SUCCESS = "GET_SPENDS_SUCCESS"
  export const GET_SPENDS_SUCCESS_FAIL = "GET_SPENDS_SUCCESS_FAIL"

  /**
 * Appointments held
 */
   export const GET_APPOINTMENTS_HELD = "GET_APPOINTMENTS_HELD"
   export const GET_APPOINTMENTS_HELD_SUCCESS = "GET_APPOINTMENTS_HELD_SUCCESS"
   export const GET_APPOINTMENTS_HELD_SUCCESS_FAIL = "GET_APPOINTMENTS_HELD_SUCCESS_FAIL"

/**
 * Revenue
 */
 export const GET_REVENUE = "GET_REVENUE"
 export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS"
 export const GET_REVENUE_SUCCESS_FAIL = "GET_REVENUE_SUCCESS_FAIL"

 /**
 * Revenue
 */
  export const GET_SALES_PIPELINE_PROGRESSION = "GET_SALES_PIPELINE_PROGRESSION"
  export const GET_SALES_PIPELINE_PROGRESSION_SUCCESS = "GET_SALES_PIPELINE_PROGRESSION_SUCCESS"
  export const GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL = "GET_SALES_PIPELINE_PROGRESSION_SUCCESS_FAIL"

  /**
 * Sales prospects
 */
export const GET_SALES_PROSPECTS = "GET_SALES_PROSPECTS"
export const GET_SALES_PROSPECTS_SUCCESS = "GET_SALES_PROSPECTS_SUCCESS"
export const GET_SALES_PROSPECTS_FAIL = "GET_SALES_PROSPECTS_FAIL"

 /**
 * Sales prospects IQ
 */
  export const GET_SALES_PROSPECT_IQ = "GET_SALES_PROSPECT_IQ"
  export const GET_SALES_PROSPECT_IQ_SUCCESS = "GET_SALES_PROSPECT_IQ_SUCCESS"
  export const GET_SALES_PROSPECT_IQ_FAIL = "GET_SALES_PROSPECT_IQ_FAIL"