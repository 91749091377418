import axios from "axios"
import { del, get, post, put } from "../api_helper"
import * as url from "./senara_url_helper"

const API_URL = process.env.REACT_APP_DATABASEURL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)
const mapData = (isSuccess, values) => {
  let result = {}
  if (isSuccess) {
    result = {
      name: "Request succeeded",
      status: values?.data?.status,
      message: values?.data?.message,
      data: values?.data?.data,
    }
  } else {
    result = {
      name: "Request failed",
      status: values.response?.data?.status,
      message: values.response?.data?.message,
      error: values.response?.data?.error,
      data: values.response?.data?.data,
    }
  }
  return result
}

// Login function
export const postLogin = data => post(url.LOGIN, data)

// verify password function
export const verifyResetPassword = data => post(url.VERIFY, data)

// Forgot password function
export const postForgotPassword = data => post(url.FORGOT_PASSWORD, data)

// Forgot password function
export const postResetPassword = data => post(url.RESET_PASSWORD, data)

// change password function
export const postChangePassword = data => post(url.CHANGE_PASSWORD, data)

// Create user
export const createNewUser = data => post(url.CREATE_USER, data)

// Create campaign
export const createNewCampaign = data => post(url.CREATE_CAMPAIGN, data)

// Get all users
export const getAllUsers = () => get(url.GET_ALL_USERS)

// Update user
export const updateUser = data => post(url.UPDATE_USER, data)

// Update user
export const deleteUser = data => post(url.DELETE_USER, data)

// Get all campaign
export const getAllCampaign = () => get(url.GET_ALL_CAMPAIGN)

// Get campaign by Id
export const getCampaignById = campaignId =>
  get(url.GET_CAMPAIGN_BY_ID + campaignId)

// Update campaign
export const updateCampaign = data => post(url.UPDATE_CAMPAIGN, data)

// Get Group
export const getGroup = () => get(url.GET_GROUP)

// Get Role
export const getRole = groupID => get(url.GET_ROLE + groupID)

// Get Role Type
export const getRoleType = roleID => get(url.GET_ROLE_TYPE + roleID)

// Update Profile Picture
export const updateProfilePhoto = data => post(url.UPDATE_PROFILE_PHOTO + data)

// Delete campaign
export const deleteCampaign = data => post(url.DELETE_CAMPAIGN, data)

// Get all companies
export const getAllCompanies = () => get(url.GET_ALL_COMPANIES)

// Create/Update company
export const saveCompany = data => post(url.CREATE_UPDATE_COMPANY, data)

// Update user
export const deleteCompany = data => post(url.DELETE_COMPANY, data)

// Dashboard apis starts here
// Get all leads
export const getAllLeads = campaignId => get(url.GET_LEADS + campaignId)

// Get all Qualified leads
export const getAllQualifiedLeads = campaignId =>
  get(url.GET_QUALIFIED_LEADS + campaignId)

// Get all Firm Sales
export const getAllFirmSales = campaignId =>
  get(url.GET_FIRM_SALES + campaignId)

// Get all spends
export const getAllSpends = campaignId => get(url.GET_SPENDS + campaignId)

// Get all appointments held
export const getAllAppointmentsHeld = campaignId =>
  get(url.GET_APPOINTMENTS_HELD + campaignId)

// Get all revenue
export const getAllRevenue = campaignId => get(url.GET_REVENUE + campaignId)

// Get all Sales Pipeline Progression
export const getAllSalesPipelineProgression = params =>
  get(url.GET_SALES_PIPELINE_PROGRESSION + params)

// Get all Sales Prospects
export const getAllSalesProspects = campaignId =>
  get(url.GET_SALES_PROSPECTS + campaignId)

// Get all activites
export const getAllActivities = params => get(url.GET_ACTIVITIES + params)

// Get all leads
export const getAllLeadsgeneration = campaignId =>
  get(url.GET_LEADS_GENERATION + campaignId)

// Get all leads
export const getAllCostperlead = campaignId =>
  get(url.GET_COST_PER_LEAD + campaignId)

// Get all Lead Generation Spend
export const getAllLeadsgenerationSpend = campaignId =>
  get(url.GET_LEADS_GENERATION_SPENDS + campaignId)

// Get all Channel Chart
export const getAllChannelChart = params => get(url.GET_CHANNEL_CHART + params)

// Get all Persona Chart
export const getAllPersonaChart = params => get(url.GET_PERSONA_CHART + params)

// Get all Cohort Chart
export const getAllCohortChart = params => get(url.GET_COHORT_CHART + params)

// Get all Lead Generation Report
export const getAllLeadsgenerationReport = params =>
  get(url.GET_LEADS_GENERATION_REPORT + params)

// Get all Lead Cost Performance
export const getAllLeadCostPerformance = campaignId =>
  get(url.GET_LEADS_COST_PERFORMANCE + campaignId)

// Get all Flight Plans
export const getAllFlightPlans = params => get(url.GET_FLIGHT_PLANS + params)
//Leads Creative
// Get all visits
export const getAllLeadCreativeVisits = campaignId =>
  get(url.GET_LEADS_CREATIVE_VISITS + campaignId)

// Get Click Through Rate
export const getAllClickThroughRate = campaignId =>
  get(url.GET_CLICK_THROUGH_RATE + campaignId)

// Get Cost Per Click
export const getAllCostPerClick = campaignId =>
  get(url.GET_COST_PER_CLICK + campaignId)

// Get all Concept Chart
export const getAllConceptChart = params => get(url.GET_CONCEPT_CHART + params)

// Get all Visual Chart
export const getAllVisualChart = params => get(url.GET_VISUAL_CHART + params)

// Get all Copy Strategy Chart
export const getAllCopyStrategyChart = params =>
  get(url.GET_COPY_STRATEGY_CHART + params)

// Get all Copy Strategy Chart
export const getAllTagLineChart = params => get(url.GET_TAG_LINE_CHART + params)

// Get all Top Ads Name
export const getAllTopAds = params => get(url.GET_TOP_ADS + params)

// Get home buyer persona
export const getAllHomebuyerPersona = campaignId =>
  get(url.GET_HOMEBUYER_PERSONA + campaignId)

// Get all activites
export const getAllTopAdsDetails = params =>
  get(url.GET_TOP_ADS_DETAILS + params)

// Get Creative Report
export const getCreativeReport = params => get(url.GET_CREATIVE_REPORT + params)

// Get Targeted cohort tabs
export const getTargetedCohortTabs = campaignId =>
  get(url.GET_TARGETED_COHORT_TABS + campaignId)

// Get Targeted cohort details
export const getTargetedCohortDetails = params =>
  get(url.GET_TARGETED_COHORT_DETAILS + params)

// Get Landing Pages details
export const getLandingPageDetails = campaignId =>
  get(url.GET_LANDING_PAGES_DETAILS + campaignId)

// Ad Gallery tabs
export const getAdGalleryTabs = campaignId =>
  get(url.GET_AD_GALLERY_TABS + campaignId)

// Get Targeted cohort details
export const getAdGalleryDetails = params =>
  get(url.GET_AD_GALLERY_DETAILS + params)

// Get Location Report
export const getLocationReport = campaignId =>
  get(url.GET_LOCATION_REPORT + campaignId)

// Get Location Map
export const getLocationMap = params => get(url.GET_LOCATION_MAP + params)

// Get Image Gallery
export const uploadImages = payload => {
  const form = new FormData()
  form.append("file", payload.file)
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_DATABASEURL}${url.UPLOAD_IMAGES}`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}
// Get Notifications
export const getNotifications = campaignId =>
  get(url.GET_NOTIFICATIONS + campaignId)

// Get End User Sales
export const getAllEndUserSales = campaignId =>
  get(url.GET_END_USER_SALES + campaignId)

// Get Unrepresented Ratio
export const getAllUnrepresentedRatio = campaignId =>
  get(url.GET_UNREPRESENTED_RATIO + campaignId)

// Get Unrepresented Ratio
export const getAllCommissionsSaved = campaignId =>
  get(url.GET_COMMISSIONS_SAVED + campaignId)

// Get all Remaining Inventory Chart
export const getAllRemainingInventoryChart = campaignId =>
  get(url.GET_REMAINING_INVENTORY_CHART + campaignId)

// Get all Demand Ratio Chart
export const getAllDemandRatioChart = campaignId =>
  get(url.GET_DEMAND_RATIO_CHART + campaignId)

// Get all Remaining Inventory Report
export const getAllRemainingInventoryReport = campaignId =>
  get(url.GET_REMAINING_INVENTORY_REPORT + campaignId)

// Get all Demand Ratio Report
export const getAllDemandRatioReport = campaignId =>
  get(url.GET_DEMAND_RATIO_REPORT + campaignId)

// Get all Company Role
export const getAllCompanyRole = () => get(url.GET_COMPANY_ROLES)

// Get all Location Region
export const getAllLocation = () => get(url.GET_LOCATION)

// Get all Flight Phase
export const getAllFlightPhase = () => get(url.GET_FLIGHT_PHASE)

// Get Cover Photo Patha
export const uploadCoverPhoto = payload => {
  const form = new FormData()
  form.append("file", payload.file)
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_DATABASEURL}${url.UPLOAD_COVER_PHOTO}`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}

// Create Media Intake
export const saveMediaIntake = (params, data) =>
  post(url.CREATE_MEDIA_INTAKE + params, data)

// Get all Media Intake
export const getAllMediaIntake = params =>
  get(url.GET_ALL_MEDIA_INTAKE + params)

//Update Media Intake
export const updateMediaIntake = data => post(url.UPDATE_MEDIA_INTAKE, data)

// Delete Media Intake
export const deleteMediaIntake = data => post(url.DELETE_MEDIA_INTAKE, data)

// Create Sales Intake
export const saveSalesIntake = (params, data) =>
  post(url.CREATE_SALES_INTAKE + params, data)

// Get all Sales Intake
export const getAllSalesIntake = params =>
  get(url.GET_ALL_SALES_INTAKE + params)

// Delete Sales Intake
export const deleteSalesIntake = data => post(url.DELETE_SALES_INTAKE, data)

// Creative Intake
export const saveCreativeIntake = (params, data) =>
  post(url.CREATE_CREATIVE_INTAKE + params, data)

// Get all Creative Intake
export const getAllCreativeIntake = params =>
  get(url.GET_ALL_CREATIVE_INTAKE + params)

// Delete Creative Intake
export const deleteCreativeIntake = data =>
  post(url.DELETE_CREATIVE_INTAKE, data)

// Technical Intake
export const saveTechnicalIntake = (params, data) =>
  post(url.CREATE_TECHNICAL_INTAKE + params, data)

// Get all Technical Intake
export const getAllTechnicalIntake = params =>
  get(url.GET_ALL_TECHNICAL_INTAKE + params)

// Delete Technical Intake
export const deleteTechnicalIntake = data =>
  post(url.DELETE_TECHNICAL_INTAKE, data)

// Create campaign
export const createNewEmailNurturing = data =>
  post(url.CREATE_EMAIL_NURTURING, data)

// Get all View Permission
export const getAllViewPermission = campaignId =>
  get(url.GET_ALL_VIEW_PERMISSION + campaignId)

//Get Save View Permission
export const saveViewPermission = data => post(url.SAVE_VIEW_PERMISSION, data)

// Get all Map Channel
export const getAllMapChannel = campaignId =>
  get(url.GET_MAP_CHANNEL + campaignId)

// Get all Map Persona
export const getAllMapPersona = campaignId =>
  get(url.GET_MAP_PERSONA + campaignId)

// Get all Map Cohort
export const getAllMapCohort = campaignId =>
  get(url.GET_MAP_COHORT + campaignId)

// Get all Technical Intake
export const getAllSalesProspectIQDetails = params =>
  get(url.GET_ALL_SALES_PROSPECT_IQ + params)

// Get Media Plan Url
export const getMediaPlanUrl = campaignId =>
  get(url.GET_MEDIA_PLAN_URL + campaignId)

// Get Email Nurturing
export const getEmailNurturing = campaignId =>
  get(url.GET_EMAIL_NURTURING + campaignId)

// Update user
export const deleteEmailNurturing = data =>
  post(url.DELETE_EMAIL_NURTURING, data)

// Get all Countries
export const getAllCountries = () => get(url.GET_COUNTRIES)

// Get Campaign Validate Details
export const getCampaignValidateDetails = params => post(url.GET_CAMPAIGN_VALIDATE_DETAILS, params)

// Get Sales Phases Details
export const getSalesPhasesDetails = campaignId => get(url.GET_SALES_PHASES_DETAILS + campaignId)

// Get Campaign User Admin
export const getAdminUsers = () => get(url.GET_ADMIN_USERS)

// Get Activity Users
export const getActivityUsers = campaignId => get(url.GET_ACTIVITY_USERS_DETAILS + campaignId)

// Get Map Suite Type
export const getMapSuiteType = campaignId => get(url.GET_MAP_SUITE_TYPE + campaignId)

// Get Map Price Range
export const getMapPriceRange = campaignId => get(url.GET_MAP_PRICE_RANGE + campaignId)

// Get Map Is Realtor
export const getMapIsRealtor = campaignId => get(url.GET_MAP_IS_REALTOR + campaignId)

// Get Map Has Realtor
export const getMapHasRealtor = campaignId => get(url.GET_MAP_HAS_REALTOR + campaignId)

// Get Map Senara Score 
export const getMapSenaraScore  = campaignId => get(url.GET_MAP_SENARA_SCORE + campaignId)

// Get Map Purchased Suite Type
export const getMapPurchasedSuiteType = campaignId => get(url.GET_MAP_PURCHASED_SUITE_TYPE + campaignId)

// Get Map Purchased Suite Price
export const getMapPurchasedSuitePrice = campaignId => get(url.GET_MAP_PURCHASED_SUITE_PRICE + campaignId)

// Upload File
export const uploadFile = fileName => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_DATABASEURL}${url.UPLOAD_FILES_URL}`,
    data: fileName,
  })
}

//Put Uploaded File
// export const uploadFilesPut = (url,file) => axios.put(url,file)
export const updateFilesPut = async(payload) => {
  console.log("updateFilesPut",payload.url,payload.file)
  return await fetch(payload.url, {
   method: "PUT",
   body: payload.file,
  }).then(response => {
    console.log("response",response)
    const successResult = {
      result: mapData(true, response),
    }
    return successResult
  })
  .catch(error => {
    const errorResult = {
      error: mapData(false, error),
    }
    return errorResult
  })
}

// export const updateFilePut = async(url,file) => {
//   return axios({
//     method: "PUT",
//     body: file,
//   })
// }

// Get personalized
export const getPersonalizedData = id => get(url.GET_PERSONALIZE + id)
