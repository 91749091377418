import {
    CAMPAIGN_ID,
    GET_LEADS_GENERATION_SUCCESS,
    GET__LEADS_GENERATION_FAIL,
    GET_COST_PER_LEAD_SUCCESS,
    GET_COST_PER_LEAD_FAIL,
    GET_LEADS_GENERATION_SPENDS_SUCCESS,
    GET_LEADS_GENERATION_SPENDS_FAIL,
    GET_CHANNEL_CHART_SUCCESS,
    GET_CHANNEL_CHART_FAIL,
    GET_PERSONA_CHART_SUCCESS,
    GET_PERSONA_CHART_FAIL,
    GET_COHORT_CHART_SUCCESS,
    GET_COHORT_CHART_FAIL,
    GET_LEADS_GENERATION_REPORT_SUCCESS,
    GET_LEADS_GENERATION_REPORT_FAIL,
    GET_LEADS_COST_PERFORMANCE_SUCCESS,
    GET_LEADS_COST_PERFORMANCE_FAIL,
    GET_LOCATION_REPORT_SUCCESS,
    GET_LOCATION_REPORT_FAIL,
    GET_LOCATION_MAP_SUCCESS,
    GET_LOCATION_MAP_FAIL,
    GET_ALL_MAP_CHANNEL_SUCCESS,
    GET_ALL_MAP_CHANNEL_FAIL,
    GET_ALL_MAP_PERSONA_SUCCESS,
    GET_ALL_MAP_PERSONA_FAIL,
    GET_ALL_MAP_COHORT_SUCCESS,
    GET_ALL_MAP_COHORT_FAIL
  } from "./actionTypes"

  const initialState = {
    loading: false,
    all_leads_generation: null,
    all_cost_per_leads: null,
    all_leads_generation_spend: null,
    all_channel_chart: null,
    all_persona_chart: null,
    all_cohort_chart: null,
    all_leads_generation_report: null,
    all_lead_cost_performance: null,
    location_report: null,
    location_map: null,
    all_map_channel: null,
    all_map_persona: null,
    all_map_cohort: null,
  }
  const leadsgeneration = (state = initialState, action) => {
    switch (action.type) {
      case GET_LEADS_GENERATION_SUCCESS:
        state = {
          ...state,
          all_leads_generation: action.payload,
        }
        break
      case GET__LEADS_GENERATION_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_COST_PER_LEAD_SUCCESS:
        state = {
          ...state,
          all_cost_per_leads: action.payload,
        }
        break
      case GET_COST_PER_LEAD_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_LEADS_GENERATION_SPENDS_SUCCESS:
        state = {
          ...state,
          all_leads_generation_spend: action.payload,
        }
        break
      case GET_LEADS_GENERATION_SPENDS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_CHANNEL_CHART_SUCCESS:
        state = {
          ...state,
          all_channel_chart: action.payload,
        }
        break
      case GET_CHANNEL_CHART_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_PERSONA_CHART_SUCCESS:
        state = {
          ...state,
          all_persona_chart: action.payload,
        }
        break
      case GET_PERSONA_CHART_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_COHORT_CHART_SUCCESS:
        state = {
          ...state,
          all_cohort_chart: action.payload,
        }
        break
      case GET_COHORT_CHART_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
      case GET_LEADS_GENERATION_REPORT_SUCCESS:
          state = {
            ...state,
            all_leads_generation_report: action.payload,
          }
          break
      case GET_LEADS_GENERATION_REPORT_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
      case GET_LEADS_COST_PERFORMANCE_SUCCESS:
          state = {
            ...state,
            all_lead_cost_performance: action.payload,
          }
          break
      case GET_LEADS_COST_PERFORMANCE_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
      case GET_LOCATION_REPORT_SUCCESS:
            state = {
              ...state,
              location_report: action.payload,
            }
            break
      case GET_LOCATION_REPORT_FAIL:
            state = {
              ...state,
              error: action.payload,
            }
            break
      case GET_LOCATION_MAP_SUCCESS:
              state = {
                ...state,
                location_map: action.payload,
              }
              break
      case GET_LOCATION_MAP_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
        break
      case GET_ALL_MAP_CHANNEL_SUCCESS:
          state = {
            ...state,
            all_map_channel: action.payload,
          }
        break
      case GET_ALL_MAP_CHANNEL_FAIL:
        state = {
          ...state,
          error: action.payload,
        }  
        break
      case GET_ALL_MAP_PERSONA_SUCCESS:
        state = {
          ...state,
          all_map_persona: action.payload,
        }
        break
      case GET_ALL_MAP_PERSONA_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
      case GET_ALL_MAP_COHORT_SUCCESS:
        state = {
          ...state,
          all_map_cohort: action.payload,
        }
        break
      case GET_ALL_MAP_COHORT_FAIL:
        state = {
          ...state,
          error: action.payload,
        } 
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default leadsgeneration