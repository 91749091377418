import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getCampaign,
} from "../../store/actions"
import { setCampaignId } from "store/dashboard-widgets/actions"
import { IconSenaraLogo05 } from "assets/images/senaraIndex"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [customCampaignLogo, setCustomCampaignLogo] = useState()
  const [socialDrp, setsocialDrp] = useState(false)
  const dispatch = useDispatch()

  const { Campaing } = useSelector(state => ({
    Campaing: state.Campaing,
  }))

  const { all_campaign } = useSelector(state => ({
    all_campaign: state.Campaing.all_campaign,
  }))
  const { campaignId } = useSelector(state => ({
    campaignId: state.Dashboard.campaignId,
  }))

  useEffect(() => {
    if (all_campaign === null) dispatch(getCampaign("SET_CAMPAIGN_ID"))
  }, [])

  useEffect(() => {
    if (all_campaign?.data?.length === 0) {
      dispatch(setCampaignId("NO_CAMPAIGNS_ASSIGNED"))
    }
  }, [all_campaign])

  useEffect(() => {
    if (campaignId && all_campaign?.data.length > 0) {
      const selectedCamp = all_campaign?.data?.find(
        x => x.id === Number(campaignId)
      )
      setSelectedCampaign(selectedCamp)
      setCustomCampaignLogo(selectedCamp?.logo_path)
    }
  }, [campaignId, all_campaign])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span>
                  <img
                    src={customCampaignLogo || IconSenaraLogo05}
                    alt=""
                    style={{ maxWidth: "100%", objectFit: "contain" }}
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span>
                  <img
                    src={customCampaignLogo || IconSenaraLogo05}
                    alt=""
                    style={{ maxWidth: "100%", objectFit: "contain" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            {selectedCampaign && (
              <Dropdown
                className="dropdown-mega d-lg-block"
                isOpen={megaMenu}
                toggle={() => {
                  setmegaMenu(!megaMenu)
                }}
              >
                <DropdownToggle className="btn header-item text-heading-light" caret tag="button">
                  {" "}
                  {selectedCampaign.name || "Campaigns"}{" "}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-megamenu menu-custom">
                  <Col md={12}>
                    <ul className="list-unstyled megamenu-list custom-ul">
                      {all_campaign?.data?.map(campaign => {
                        return (
                          <li key={campaign.id}>
                            <Link
                              to="#"
                              className="text-heading-light"
                              onClick={() => {
                                dispatch(setCampaignId(campaign.id)),
                                  setmegaMenu(false)
                              }}
                            >
                              {campaign.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          <div className="d-flex">
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
