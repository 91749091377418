import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR
} from "./actionTypes"

export const resetPwd = (user, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, history },
  }
}

export const resetPwdSuccess = message => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const resetPwdError = message => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  }
}
