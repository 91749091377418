import {
    GET_FLIGHTPLANS,
    GET_FLIGHTPLANS_SUCCESS,
    GET_FLIGHTPLANS_FAIL,
    GET_HOMEBUYER_PERSONA,
    GET_HOMEBUYER_PERSONA_SUCCESS,
    GET_HOMEBUYER_PERSONA_FAIL,
    GET_TARGETED_COHORT_TABS,
    GET_TARGETED_COHORT_TABS_SUCCESS,
    GET_TARGETED_COHORT_TABS_FAIL,
    GET_TARGETED_COHORT_DETAILS,
    GET_TARGETED_COHORT_DETAILS_SUCCESS,
    GET_TARGETED_COHORT_DETAILS_FAIL,
    GET_LANDING_PAGES_DETAILS,
    GET_LANDING_PAGES_DETAILS_SUCCESS,
    GET_LANDING_PAGES_DETAILS_FAIL,
    GET_AD_GALLERY_TABS,
    GET_AD_GALLERY_TABS_SUCCESS,
    GET_AD_GALLERY_TABS_FAIL,
    GET_AD_GALLERY_DETAILS,
    GET_AD_GALLERY_DETAILS_SUCCESS,
    GET_AD_GALLERY_DETAILS_FAIL,
    GET_MEDIA_PLAN_URL,
    GET_MEDIA_PLAN_URL_SUCCESS,
    GET_MEDIA_PLAN_URL_FAIL
  } from "./actionTypes"

  export const getFlightplans = params => ({
    type: GET_FLIGHTPLANS,
    payload: params
  })
  
  export const getFlightplansSuccess = data => ({
    type: GET_FLIGHTPLANS_SUCCESS,
    payload: data,
  })
  
  export const getFlightplansFail = error => ({
    type: GET_FLIGHTPLANS_FAIL,
    payload: error,
  })

  export const getHomeBuyerPersona = params => ({
    type: GET_HOMEBUYER_PERSONA,
    payload: params
  })
  
  export const getHomeBuyerPersonaSuccess = data => ({
    type: GET_HOMEBUYER_PERSONA_SUCCESS,
    payload: data,
  })
  
  export const getHomeBuyerPersonaFail = error => ({
    type: GET_HOMEBUYER_PERSONA_FAIL,
    payload: error,
  })

  export const getTargetedCohortTabs = params => ({
    type: GET_TARGETED_COHORT_TABS,
    payload: params
  })
  
  export const getTargetedCohortTabsSuccess = data => ({
    type: GET_TARGETED_COHORT_TABS_SUCCESS,
    payload: data,
  })
  
  export const getTargetedCohortTabsFail = error => ({
    type: GET_TARGETED_COHORT_TABS_FAIL,
    payload: error,
  })

  export const getTargetedCohortDetail = params => ({
    type: GET_TARGETED_COHORT_DETAILS,
    payload: params
  })
  
  export const getTargetedCohortDetailsSuccess = data => ({
    type: GET_TARGETED_COHORT_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const getTargetedCohortDetailsFail = error => ({
    type: GET_TARGETED_COHORT_DETAILS_FAIL,
    payload: error,
  })

  export const getLandingPageDetails = params => ({
    type: GET_LANDING_PAGES_DETAILS,
    payload: params
  })
  
  export const getLandingPageDetailsSuccess = data => ({
    type: GET_LANDING_PAGES_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const getLandingPageDetailsFail = error => ({
    type: GET_LANDING_PAGES_DETAILS_FAIL,
    payload: error,
  })

  export const getAdGalleryTabs = params => ({
    type: GET_AD_GALLERY_TABS,
    payload: params
  })
  
  export const getAdGalleryTabsSuccess = data => ({
    type: GET_AD_GALLERY_TABS_SUCCESS,
    payload: data,
  })
  
  export const getAdGalleryTabsFail = error => ({
    type: GET_AD_GALLERY_TABS_FAIL,
    payload: error,
  })

  export const getAdGalleryDetail = params => ({
    type: GET_AD_GALLERY_DETAILS,
    payload: params
  })
  
  export const getAdGalleryDetailsSuccess = data => ({
    type: GET_AD_GALLERY_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const getAdGalleryDetailsFail = error => ({
    type: GET_AD_GALLERY_DETAILS_FAIL,
    payload: error,
  })

  export const getMediaPlanUrl = id => ({
    type: GET_MEDIA_PLAN_URL,
    payload: id
  })
  
  export const getMediaPlanUrlSuccess = data => ({
    type: GET_MEDIA_PLAN_URL_SUCCESS,
    payload: data,
  })
  
  export const getMediaPlanUrlFail = error => ({
    type: GET_MEDIA_PLAN_URL_FAIL,
    payload: error,
  })
