import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { GET_NOTIFICATIONS } from "./actionTypes"
import { getNotifications } from "helpers/senara_backend/senarabackend_helper"
import { getNotificationsFail, getNotificationsSuccess } from "./actions"

function* fetchAllNotifications({payload: id}) {
  try {
    const { result, error } = yield call(getNotifications,id)
    if (result) yield put(getNotificationsSuccess(result))
    if (error) yield put(getNotificationsFail(error))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}

export function* watchNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, fetchAllNotifications)
}

function* NotificationsSaga() {
  yield all([fork(watchNotifications)])
}

export default NotificationsSaga
