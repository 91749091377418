const { UPDATE_RAW_DATA } = require("./actionTypes")

const initalState = {}

const rawDataReducer = (state = initalState, action) => {
   switch (action.type) {
    case UPDATE_RAW_DATA:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default rawDataReducer
