import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "./actionTypes"

const initialState = {
  loading: false,
  notifications: null,
  error: null,
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        notifications: action.payload,
      }
      break
    case GET_NOTIFICATIONS_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default notifications
