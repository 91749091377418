import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { postLogin } from "../../../helpers/senara_backend/senarabackend_helper"

// senara login
function* loginUser({ payload: { user, history } }) {
  try {
    const { result, error } = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    if (result) {
      localStorage.setItem("authUser", JSON.stringify(result))
      yield put(loginSuccess(result))
    }
    if (error) {
      yield put(apiError(error))
    }
    history.push("/overview")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
