import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
    GET_END_USER_SALES,
    GET_UNREPRESENTED_RATIO,
    GET_COMMISSIONS_SAVED,
    GET_REMAINING_INVENTORY_CHART,
    GET_DEMAND_RATIO_CHART,
    GET_REMAINING_INVENTORY_REPORT,
    GET_DEMAND_RATIO_REPORT
 } from "./actionTypes.js"

 import {
    getEndUserSalesSuccess,
    getEndUserSalesFail,
    getUnrepresentedRatioSuccess,
    getUnrepresentedRatioFail,
    getCommissionsSavedSuccess,
    getCommissionsSavedFail,
    getAllRemainingInventoryChartSuccess,
    getAllRemainingInventoryChartFail,
    getAllDemandRatioChartSuccess,
    getAllDemandRatioChartFail,
    getRemainingInventoryReportSuccess,
    getRemainingInventoryReportFail,
    getDemandRatioReportSuccess,
    getDemandRatioReportFail
 } from "./actions"

 import {
    getAllEndUserSales,
    getAllUnrepresentedRatio,
    getAllCommissionsSaved,
    getAllRemainingInventoryChart,
    getAllDemandRatioChart,
    getAllRemainingInventoryReport,
    getAllDemandRatioReport
} from "helpers/senara_backend/senarabackend_helper"

function* fetchAllEndUserSales({ payload: id }) {
    try {
        //console.log("EndUserSales=>",id)
        const { result, error } = yield call(getAllEndUserSales, id)
        if (result) yield put(getEndUserSalesSuccess(result))
        if (error) yield put(getEndUserSalesFail(error))
    } catch (error) {
        yield put(getEndUserSalesFail(error))
    }
}

function* fetchAllUnrepresentedRatio({ payload: id }) {
    try {
        //console.log("UnrepresentedRatio=>",id)
        const { result, error } = yield call(getAllUnrepresentedRatio, id)
        if (result) yield put(getUnrepresentedRatioSuccess(result))
        if (error) yield put(getUnrepresentedRatioFail(error))
    } catch (error) {
        yield put(getUnrepresentedRatioFail(error))
    }
}

function* fetchAllCommissionsSaved({ payload: id }) {
    try {
        //console.log("CommissionsSaved=>",id)
        const { result, error } = yield call(getAllCommissionsSaved, id)
        if (result) yield put(getCommissionsSavedSuccess(result))
        if (error) yield put(getCommissionsSavedFail(error))
    } catch (error) {
        yield put(getCommissionsSavedFail(error))
    }
}

function* fetchAllRemainingInventoryChart({payload: id}) {
    try {
      const { result, error } = yield call(getAllRemainingInventoryChart,id)
      if(result) yield put(getAllRemainingInventoryChartSuccess(result))
      if(error) yield put(getAllRemainingInventoryChartFail(error))
    } catch (error) {
      yield put(getAllRemainingInventoryChartFail(error))
    }
  }

function* fetchAllDemandRatioChart({payload: id}) {
    try {
      const { result, error } = yield call(getAllDemandRatioChart,id)
      if(result) yield put(getAllDemandRatioChartSuccess(result))
      if(error) yield put(getAllDemandRatioChartFail(error))
    } catch (error) {
      yield put(getAllDemandRatioChartFail(error))
    }
}

function* fetchAllRemainingInventoryReport({payload: id}) {
  try {
    const { result, error } = yield call(getAllRemainingInventoryReport,id)
    if(result) yield put(getRemainingInventoryReportSuccess(result))
    if(error) yield put(getRemainingInventoryReportFail(error))
  } catch (error) {
    yield put(getRemainingInventoryReportFail(error))
  }
}

function* fetchAllDemandRatioReport({payload: id}) {
  try {
    const { result, error } = yield call(getAllDemandRatioReport,id)
    if(result) yield put(getDemandRatioReportSuccess(result))
    if(error) yield put(getDemandRatioReportFail(error))
  } catch (error) {
    yield put(getDemandRatioReportFail(error))
  }
}

export function* watchProfitability() {
    yield takeEvery(GET_END_USER_SALES, fetchAllEndUserSales),
    yield takeEvery(GET_UNREPRESENTED_RATIO, fetchAllUnrepresentedRatio),
    yield takeEvery(GET_COMMISSIONS_SAVED, fetchAllCommissionsSaved),
    yield takeEvery(GET_REMAINING_INVENTORY_CHART, fetchAllRemainingInventoryChart),
    yield takeEvery(GET_DEMAND_RATIO_CHART, fetchAllDemandRatioChart),
    yield takeEvery(GET_REMAINING_INVENTORY_REPORT, fetchAllRemainingInventoryReport),
    yield takeEvery(GET_DEMAND_RATIO_REPORT, fetchAllDemandRatioReport)
  }

function* ProfitabilitySaga() {
   yield all([fork(watchProfitability)])
}

export default ProfitabilitySaga