import React, { lazy } from "react"
import { Redirect } from "react-router-dom"

// Profile
const UserProfile = lazy(() => import("../pages/Authentication/user-profile"))

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"))
const Logout = lazy(() => import("../pages/Authentication/Logout"))
const Register = lazy(() => import("../pages/Authentication/Register"))
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"))

// Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard/index"))
const UsersList = lazy(() => import("pages/Users/UsersList"))
const AddCampaign = lazy(() => import("pages/Campaigns/AddCampaign"))
const CampaignList = lazy(() => import("pages/Campaigns/CampaignList"))
const AddUser = lazy(() => import("pages/Users/AddUser"))
const Prospects = lazy(() => import("pages/LeadGenerations/Prospects"))
const Creative = lazy(() => import("../pages/Creative/Creative"))
const Leads = lazy(() => import("../pages/Dashboard/LeadGeneration"))
const KeyActivities = lazy(() => import("../pages/DataConcierge/KeyActivities"))
const Audience = lazy(() => import("../pages/Strategy/Audience"))
const Media = lazy(() => import("../pages/Strategy/Media/Media"))
const Journey = lazy(() => import("../pages/Strategy/Journey/Journey"))
const Location = lazy(() => import("../pages/LeadGenerations/LocationIndex"))
const CompanyList = lazy(() => import("pages/Company/CompanyList"))
const AddCompany = lazy(() => import("pages/Company/AddCompany"))
const ChangePassword = lazy(() => import("pages/Authentication/ChangePassword"))
const ResetPassword = lazy(() => import("pages/Authentication/ResetPassword"))
const AddImages = lazy(() => import("pages/Images/AddImages"))
const ImageGallery = lazy(() => import("pages/Images/ImageGallery"))
const Profitability = lazy(() => import("pages/Profitability/Profitability"))
const MediaIntake = lazy(() => import("pages/Intake/Media/MediaIntake"))
const MediaIntakeList = lazy(() => import("pages/Intake/Media/MediaIntakeList"))
const SalesIntake = lazy(() => import("pages/Intake/Sales/SalesIntake"))
const SalesIntakeList = lazy(() => import("pages/Intake/Sales/SalesIntakeList"))
const TechnicalIntake = lazy(() =>
  import("pages/Intake/Technical/TechnicalIntake")
)
const TechnicalIntakeList = lazy(() =>
  import("pages/Intake/Technical/TechnicalIntakeList")
)
const CreativeIntake = lazy(() =>
  import("pages/Intake/CreativeIntake/CreativeIntake")
)
const CreativeIntakeList = lazy(() =>
  import("pages/Intake/CreativeIntake/CreativeIntakeList")
)
const ProspectNurturing = lazy(() =>
  import("pages/Strategy/ProspectNurturing/ProspectNurturing")
)
const AddProspectNurturing = lazy(() =>
  import("pages/Strategy/ProspectNurturing/AddProspectNurturing")
)

const authProtectedRoutes = [
  { path: "/overview", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/overview" /> },
  { path: "/lead-generation/prospects", component: Prospects },
  { path: "/lead-generation/creative", component: Creative },
  { path: "/lead-generation/performance", component: Leads },
  { path: "/lead-generation/location", component: Location },
  { path: "/data-concierge/key-activities", component: KeyActivities },
  { path: "/strategy/audience", component: Audience },
  { path: "/strategy/media", component: Media },
  { path: "/strategy/journey", component: Journey },
  { path: "/change-password", component: ChangePassword },
  { path: "/profitability/inventory", component: Profitability },
  { path: "/intake/add-media", component: MediaIntake },
  { path: "/intake/add-sales-intake", component: SalesIntake },
  { path: "/intake/add-technical-intake", component: TechnicalIntake },
  { path: "/intake/add-creative-intake", component: CreativeIntake },
  { path: "/strategy/prospect-nurturing", component: ProspectNurturing },
  {
    path: "/strategy/prospect-nurturing/add-touchpoint",
    component: AddProspectNurturing,
  },
]

const adminRoutes = [
  { path: "/admin/user", component: UsersList },
  { path: "/admin/user/add-user", component: AddUser },
  { path: "/admin/campaign/add-campaign", component: AddCampaign },
  { path: "/admin/campaign/edit-campaign/:campaignID", component: AddCampaign },
  { path: "/admin/company", component: CompanyList },
  { path: "/admin/company/add-company", component: AddCompany },
  { path: "/admin/campaign", component: CampaignList },
  { path: "/admin/assets", component: AddImages },
  { path: "/admin/gallery", component: ImageGallery },
  { path: "/admin/intake/add-media", component: MediaIntake },
  { path: "/admin/intake/media-intake", component: MediaIntakeList },
  { path: "/admin/intake/add-sales", component: SalesIntake },
  { path: "/admin/intake/sales-intake", component: SalesIntakeList },
  { path: "/admin/intake/technical-intake", component: TechnicalIntakeList },
  { path: "/admin/intake/creative-intake", component: CreativeIntakeList },
]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/resetPassword/:resetToken", component: ResetPassword },
  { path: "/verify/:token", component: ResetPassword },
]

export { publicRoutes, authProtectedRoutes, adminRoutes }
