const ICONS_BASE_URL = 'https://d3iqk27qzcd0cx.cloudfront.net';

// Senara Logo
export const IconSenaraLogo = ICONS_BASE_URL+'/images/Senara_Logo.png';
export const IconSenaraLogo02 = ICONS_BASE_URL+'/images/Senara_Logo-02.svg';
export const IconSenaraLogo05 = ICONS_BASE_URL+'/images/Senara_Logo-05.svg';

// Side menu icons
export const IconOverview = ICONS_BASE_URL+'/images/overview.svg';
export const IconDataConcierge = ICONS_BASE_URL+'/images/datacon.svg';
export const IconLeadGeneration = ICONS_BASE_URL+'/images/leadgen.svg';
export const IconStrategy = ICONS_BASE_URL+'/images/strategy.svg';
export const IconAdminMenu = ICONS_BASE_URL+'/images/admin-new.svg';
export const IconProfitabilityMenu = ICONS_BASE_URL+'/images/Profitability-white.svg';

// Counter icons
export const IconLeads = ICONS_BASE_URL+'/images/Leads.svg';
export const IconQualifiedLead = ICONS_BASE_URL+'/images/Qualified_Lead.svg';
export const IconFirmSale = ICONS_BASE_URL+'/images/FirmSale.svg';
export const IconSpend = ICONS_BASE_URL+'/images/Spend.svg';
export const IconAppointmentHeld = ICONS_BASE_URL+'/images/AppointmentHeld.svg';
export const IconRevenue = ICONS_BASE_URL+'/images/Revenue.svg';

export const IconCostPerLead = ICONS_BASE_URL+'/images/Cost_Per_Lead.svg';
export const IconVisits = ICONS_BASE_URL+'/images/Visits.svg';
export const IconClickThroughRate = ICONS_BASE_URL+'/images/ClickThroughRate.svg';
export const IconCostPerClick = ICONS_BASE_URL+'/images/CostPerClick.png';

export const IconAssistedRatio = ICONS_BASE_URL+'/images/AssistedRatio.svg';
export const IconEndUserSales = ICONS_BASE_URL+'/images/EndUserSales.svg';
export const IconCommissionsSaved = ICONS_BASE_URL+'/images/CommissionsSaved.svg';

//GIF Icons
export const SenaraLogoGIF = ICONS_BASE_URL+'/campaign/senara_preloader_150.gif';

// Intake Icon
export const IconIntakeMenu = ICONS_BASE_URL+'/images/Intake-Icon-white.svg';
