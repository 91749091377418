import { 
   UPLOAD_IMAGES,
   UPLOAD_IMAGES_SUCCESS,
   UPLOAD_IMAGES_FAIL,
   UPLOAD_FILES,
   UPLOAD_FILES_SUCCESS,
   UPLOAD_FILES_FAIL,
   UPLOAD_FILES_TYPE,
   UPLOAD_FILES_TYPE_REMOVE
 } from "./actionTypes";

 export const uploadImages = data => {
  return {
    type: UPLOAD_IMAGES,
    payload: data ,
  }
}

export const uploadImagesSuccess = data => {
  return {
    type: UPLOAD_IMAGES_SUCCESS,
    payload: data,
  }
}

export const uploadImagesFail = data => {
  return {
    type: UPLOAD_IMAGES_FAIL,
    payload: data,
  }
}

export const uploadFiles = files => {
  return {
    type: UPLOAD_FILES,
    payload: files ,
  }
}

export const uploadFilesSuccess = files => {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload: files,
  }
}

export const uploadFilesFail = files => {
  return {
    type: UPLOAD_FILES_FAIL,
    payload: files,
  }
}

export const updateLoadingFiles = key =>{
  return {
    type: UPLOAD_FILES_TYPE,
    payload: key,
  }
}

export const removeLoadingFiles = key =>{
  return {
    type:UPLOAD_FILES_TYPE_REMOVE,
    payload:key
  }
}