
/**
 * Add Media Intake
 */
 export const SAVE_MEDIA_INTAKE = "SAVE_MEDIA_INTAKE"
 export const SAVE_MEDIA_INTAKE_SUCCESSFUL = "SAVE_MEDIA_INTAKE_SUCCESSFUL"
 export const SAVE_MEDIA_INTAKE_FAIL = "SAVE_MEDIA_INTAKE_FAIL"
 
 /**
 * Get Media Intake
 */
export const GET_MEDIA_INTAKE = "GET_MEDIA_INTAKE"
export const GET_MEDIA_INTAKE_SUCCESS = "GET_MEDIA_INTAKE_SUCCESS"
export const GET_MEDIA_INTAKE_FAIL = "GET_MEDIA_INTAKE_FAIL"


/**
 * Update Media Intake
 */
 export const UPDATE_MEDIA_INTAKE = "UPDATE_MEDIA_INTAKE"
 export const UPDATE_MEDIA_INTAKE_SUCCESS = "UPDATE_MEDIA_INTAKE_SUCCESS"
 export const UPDATE_MEDIA_INTAKE_FAIL = "UPDATE_MEDIA_INTAKE_FAIL"

/**
 * Delete Media Intake
 */
 export const DELETE_MEDIA_INTAKE = "DELETE_MEDIA_INTAKE"
 export const DELETE_MEDIA_INTAKE_SUCCESS = "DELETE_MEDIA_INTAKE_SUCCESS"
 export const DELETE_MEDIA_INTAKE_FAIL = "DELETE_MEDIA_INTAKE_FAIL"


 /**
 * Add Sales Intake
 */
 export const SAVE_SALES_INTAKE = "SAVE_SALES_INTAKE"
 export const SAVE_SALES_INTAKE_SUCCESSFUL = "SAVE_SALES_INTAKE_SUCCESSFUL"
 export const SAVE_SALES_INTAKE_FAIL = "SAVE_SALES_INTAKE_FAIL"

/**
 * Get Sales Intake
 */
 export const GET_SALES_INTAKE = "GET_SALES_INTAKE"
 export const GET_SALES_INTAKE_SUCCESS = "GET_SALES_INTAKE_SUCCESS"
 export const GET_SALES_INTAKE_FAIL = "GET_SALES_INTAKE_FAIL"


 /**
 * Delete Sales Intake
 */
  export const DELETE_SALES_INTAKE = "DELETE_SALES_INTAKE"
  export const DELETE_SALES_INTAKE_SUCCESS = "DELETE_SALES_INTAKE_SUCCESS"
  export const DELETE_SALES_INTAKE_FAIL = "DELETE_SALES_INTAKE_FAIL"

  /**
 * Add Creative Intake
 */
  export const SAVE_CREATIVE_INTAKE = "SAVE_CREATIVE_INTAKE"
  export const SAVE_CREATIVE_INTAKE_SUCCESSFUL = "SAVE_CREATIVE_INTAKE_SUCCESSFUL"
  export const SAVE_CREATIVE_INTAKE_FAIL = "SAVE_CREATIVE_INTAKE_FAIL"
 
 /**
 * Get Creative Intake
 */
  export const GET_CREATIVE_INTAKE = "GET_CREATIVE_INTAKE"
  export const GET_CREATIVE_INTAKE_SUCCESS = "GET_CREATIVE_INTAKE_SUCCESS"
  export const GET_CREATIVE_INTAKE_FAIL = "GET_CREATIVE_INTAKE_FAIL"

 /**
 * Delete Creative Intake
 */
  export const DELETE_CREATIVE_INTAKE = "DELETE_CREATIVE_INTAKE"
  export const DELETE_CREATIVE_INTAKE_SUCCESS = "DELETE_CREATIVE_INTAKE_SUCCESS"
  export const DELETE_CREATIVE_INTAKE_FAIL = "DELETE_CREATIVE_INTAKE_FAIL"
   
    /**
 * Add Technical Intake
 */
  export const SAVE_TECHNICAL_INTAKE = "SAVE_TECHNICAL_INTAKE"
  export const SAVE_TECHNICAL_INTAKE_SUCCESSFUL = "SAVE_TECHNICAL_INTAKE_SUCCESSFUL"
  export const SAVE_TECHNICAL_INTAKE_FAIL = "SAVE_TECHNICAL_INTAKE_FAIL"

  /**
 * Get Technical Intake
 */
  export const GET_TECHNICAL_INTAKE = "GET_TECHNICAL_INTAKE"
  export const GET_TECHNICAL_INTAKE_SUCCESS = "GET_TECHNICAL_INTAKE_SUCCESS"
  export const GET_TECHNICAL_INTAKE_FAIL = "GET_TECHNICAL_INTAKE_FAIL"

  /**
 * Delete Technical Intake
 */
  export const DELETE_TECHNICAL_INTAKE = "DELETE_TECHNICAL_INTAKE"
  export const DELETE_TECHNICAL_INTAKE_SUCCESS = "DELETE_TECHNICAL_INTAKE_SUCCESS"
  export const DELETE_TECHNICAL_INTAKE_FAIL = "DELETE_TECHNICAL_INTAKE_FAIL"
