import axios from "axios"

//base url for axios
const API_URL = process.env.REACT_APP_DATABASEURL

const axiosApi = axios.create({
  baseURL: API_URL,
})
axiosApi.interceptors.request.use(function (config) {
  let authUser = JSON.parse(localStorage.getItem("authUser"))
  config.headers["Authorization"] = `Bearer ${authUser?.data.token}`
  return config
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const mapData = (isSuccess, values) => {
  let result = {}
  if (isSuccess) {
    result = {
      name: "Request succeeded",
      status: values?.data?.status,
      message: values?.data?.message,
      data: values?.data?.data,
    }
  } else {
    result = {
      name: "Request failed",
      status: values.response?.data?.status,
      message: values.response?.data?.message,
      error: values.response?.data?.error,
      data: values.response?.data?.data,
    }
  }
  return result
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      const successResult = {
        result: mapData(true, response),
      }
      return successResult
    })
    .catch(error => {
      const errorResult = {
        error: mapData(false, error),
      }
      return errorResult
    })
}
