
/**
 * Get Images
 */
 export const UPLOAD_IMAGES = "UPLOAD_IMAGES"
 export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS"
 export const UPLOAD_IMAGES_FAIL = "UPLOAD_IMAGES_FAIL"


 /**
 * Get Files
 */
  export const UPLOAD_FILES = "UPLOAD_FILES"
  export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS"
  export const UPLOAD_FILES_FAIL = "UPLOAD_FILES_FAIL"

   /**
 * Get File Type
 */
export const UPLOAD_FILES_TYPE = "UPLOAD_FILES_TYPE"
export const UPLOAD_FILES_TYPE_REMOVE="UPLOAD_FILES_TYPE_REMOVE"