import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR
} from "./actionTypes"


const initialState = {
  resetPwdSuccessMsg: null,
  resetPwdError: null,
}

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        resetPwdSuccessMsg: null,
        resetPwdError: null,
      }
      break
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetPwdSuccessMsg: action.payload,
        resetPwdError: null
      }
      break
    case RESET_PASSWORD_ERROR:
      state = { ...state, resetPwdSuccessMsg: null,resetPwdError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default resetPassword

