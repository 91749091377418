import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Company
import Company from "./company/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Verify from "./auth/verifypwd/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ResetPassword from "./auth/resetpwd/reducer"
import ChangePassword from "./auth/changepwd/reducer"
import Campaing from "./campaigns/reducer"
import Activities from "./data-concierge/reducer"
// Leads Generation
import LeadsGeneration from "./leadgeneration/reducer"

// Leads Creative
import LeadCreative from "./leadcreative/reducer"
//Strategy
import Strategy from "./strategy/reducer"
import Dashboard from "./dashboard-widgets/reducer"
import Notifications from "./notifications/reducer"

//Images
import Images from "./images/reducer"

//Profitability
import Profitability from "./profitability/reducer"

// Media Intake
import Intake from "./intake/reducer"

import rawDataReducer from './rawData/index'

// Prospect Nurturing
import EmailNurturing from "./prospect-nurturing/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Verify,
  Account,
  Company,
  ForgetPassword,
  ResetPassword,
  Dashboard,
  Activities,
  Strategy,
  ChangePassword,
  Campaing,
  LeadsGeneration,
  LeadCreative,
  Images,
  Notifications,
  Profitability,
  Intake,
  EmailNurturing,
  rawData:rawDataReducer
})

// export default rootReducer
export default (state, action) =>
  rootReducer(action.type === "LOGOUT_USER" ? undefined : state, action)
