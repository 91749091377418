import {
    CAMPAIGN_CREATE,
    CAMPAIGN_CREATE_SUCCESSFUL,
    CAMPAIGN_CREATE_FAILED,
    GET_ALL_CAMPAIGN,
    GET_ALL_CAMPAIGN_SUCCESS,
    GET_ALL_CAMPAIGN_FAIL,
    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_FAIL,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_FAIL,
    GET_ALL_FLIGHT_PHASE,
    GET_ALL_FLIGHT_PHASE_SUCCESS,
    GET_ALL_FLIGHT_PHASE_FAIL,
    UPLOAD_COVER_PHOTO,
    UPLOAD_COVER_PHOTO_SUCCESS,
    UUPLOAD_COVER_PHOTO_FAIL,
    GET_ALL_VIEW_PERMISSION,
    GET_ALL_VIEW_PERMISSION_SUCCESS,
    GET_ALL_VIEW_PERMISSION_FAIL,
    SAVE_VIEW_PERMISSIONE,
    SAVE_VIEW_PERMISSIONE_SUCCESSFUL,
    SAVE_VIEW_PERMISSIONE_FAIL,
    GET_CAMPAIGN_BY_ID,
    GET_CAMPAIGN_BY_ID_SUCCESS,
    GET_CAMPAIGN_BY_ID_FAIL,
    GET_ALL_COUNTRIES,
    GET_ALL_COUNTRIES_SUCCESS,
    GET_ALL_COUNTRIES_FAIL,
    GET_CAMPAIGN_PERSONALIZE,
    GET_CAMPAIGN_PERSONALIZE_SUCCESS,
    GET_CAMPAIGN_PERSONALIZE_FAIL,
    GET_ADDRESS_SUGGESTIONS,
    GET_ADDRESS_SUGGESTIONS_SUCCESS,
    GET_ADDRESS_SUGGESTIONS_FAIL,
  } from "./actionTypes"
  
  export const campaingCreate = data => {
    return {
      type: CAMPAIGN_CREATE,
      payload: { data },
    }
  }

  export const campaingCreateSuccessful = data => {
    return {
      type: CAMPAIGN_CREATE_SUCCESSFUL,
      payload: data,
    }
  }

  export const campaingCreateFailed = data => {
    return {
      type: CAMPAIGN_CREATE_FAILED,
      payload: data,
    }
  }

  export const getCampaign = params => ({
    type: GET_ALL_CAMPAIGN,
    payload: params
  })
  export const getCampaignSuccess = data => ({
    type: GET_ALL_CAMPAIGN_SUCCESS,
    payload: data,
  })
  
  export const getCampaignFail = error => ({
    type: GET_ALL_CAMPAIGN_FAIL,
    payload: error,
  })
  
  export const getCampaignById = (id,source) => ({
    type: GET_CAMPAIGN_BY_ID,
    payload: {id,isActiveCam:source === 'active'}
  })
  export const getCampaignByIdSuccess = data => ({
    type: GET_CAMPAIGN_BY_ID_SUCCESS,
    payload: data,
  })
  
  export const getCampaignByIdFail = error => ({
    type: GET_CAMPAIGN_BY_ID_FAIL,
    payload: error,
  })
  

  export const updateCampaign = data => ({
    type: UPDATE_CAMPAIGN,
    payload: data,
  })
  
  export const updateCampaignSuccess = data => ({
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: data,
  })
  
  export const updateCampaignFail = error => ({
    type: UPDATE_CAMPAIGN_FAIL,
    payload: error,
  })

  export const deleteCampaign = data => ({
    type: DELETE_CAMPAIGN,
    payload: data,
  })
  
  export const deleteCampaignSuccess = data => ({
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: data,
  })
  
  export const deleteCampaignrFail = error => ({
    type: DELETE_CAMPAIGN_FAIL,
    payload: error,
  })

  export const getFlightPhase = () => ({
    type: GET_ALL_FLIGHT_PHASE,
  })
  export const getFlightPhaseSuccess = users => ({
    type: GET_ALL_FLIGHT_PHASE_SUCCESS,
    payload: users,
  })
  
  export const getFlightPhaseFail = error => ({
    type: GET_ALL_FLIGHT_PHASE_FAIL,
    payload: error,
  })

  export const uploadCoverPhoto = data => {
    return {
      type: UPLOAD_COVER_PHOTO,
      payload: data ,
    }
  }
  
  export const uploadCoverPhotoSuccess = data => {
    return {
      type: UPLOAD_COVER_PHOTO_SUCCESS,
      payload: data,
    }
  }
  
  export const uploadCoverPhotoFail = data => {
    return {
      type: UUPLOAD_COVER_PHOTO_FAIL,
      payload: data,
    }
  }

  export const getAllViewPermission = (id, source) => ({
    type: GET_ALL_VIEW_PERMISSION,
    payload: {id,isUser:source === 'user'}
  })
  
  export const getViewPermissionSuccess = data => ({
    type: GET_ALL_VIEW_PERMISSION_SUCCESS,
    payload: data,
  })
  
  export const getViewPermissionFail = error => ({
    type: GET_ALL_VIEW_PERMISSION_FAIL,
    payload: error,
  })

  export const saveViewPermission = data => {
    return {
      type: SAVE_VIEW_PERMISSIONE,
      payload: data,
    }
  }
  
  export const saveViewPermissionSuccess = data => {
    return {
      type: SAVE_VIEW_PERMISSIONE_SUCCESSFUL,
      payload: data,
    }
  }
  
  export const saveViewPermissionFail = data => {
    return {
      type: SAVE_VIEW_PERMISSIONE_FAIL,
      payload: data,
    }
  }

  export const getCountries = () => ({
    type: GET_ALL_COUNTRIES,
  })
  export const getCountriesSuccess = countries => ({
    type: GET_ALL_COUNTRIES_SUCCESS,
    payload: countries,
  })
  
  export const getCountriesFail = error => ({
    type: GET_ALL_COUNTRIES_FAIL,
    payload: error,
  })
  
  export const getCampaignPersonalize = (id,source) => ({
    type: GET_CAMPAIGN_PERSONALIZE,
    payload: {id,isActiveCam:source === 'active'}
  })
  export const getCampaignPersonalizeSuceess = data => ({
    type: GET_CAMPAIGN_PERSONALIZE_SUCCESS,
    payload: data,
  })
  
  export const getCampaignPersonalizeError = error => ({
    type: GET_CAMPAIGN_PERSONALIZE_FAIL,
    payload: error,
  })

  export const getSuggestions = (data, key) => ({
    type: GET_ADDRESS_SUGGESTIONS,
    payload: { data, key },
  })
  export const getSuggestionsSuccess = data => ({
    type: GET_ADDRESS_SUGGESTIONS_SUCCESS,
    payload: data,
  })
  
  export const getSuggestionsFail = error => ({
    type: GET_ADDRESS_SUGGESTIONS_FAIL,
    payload: error,
  })