import {
  GET_FLIGHTPLANS_SUCCESS,
  GET_FLIGHTPLANS_FAIL,
  GET_HOMEBUYER_PERSONA_SUCCESS,
  GET_HOMEBUYER_PERSONA_FAIL,
  GET_TARGETED_COHORT_TABS_SUCCESS,
  GET_TARGETED_COHORT_TABS_FAIL,
  GET_TARGETED_COHORT_DETAILS_SUCCESS,
  GET_TARGETED_COHORT_DETAILS_FAIL,
  GET_LANDING_PAGES_DETAILS_SUCCESS,
  GET_LANDING_PAGES_DETAILS_FAIL,
  GET_AD_GALLERY_TABS_SUCCESS,
  GET_AD_GALLERY_TABS_FAIL,
  GET_AD_GALLERY_DETAILS_SUCCESS,
  GET_AD_GALLERY_DETAILS_FAIL,
  GET_MEDIA_PLAN_URL_SUCCESS,
  GET_MEDIA_PLAN_URL_FAIL
  } from "./actionTypes"

  const initialState = {
    loading: false,
    all_flightplans: null,
    all_homeBuyerPersonas: null,
    all_targeted_cohort_tabs: null,
    targeted_cohort_detail: null,
    all_landing_pages_detail: null,
    all_ad_gallery_tabs: null,
    ad_gallery_detail: null,
    media_plan_url : null,
    error: null
  }

  const strategy = (state = initialState, action) => {
    switch (action.type) {
      case GET_FLIGHTPLANS_SUCCESS:
        state = {
          ...state,
          all_flightplans: action.payload,
        }
        break
      case GET_FLIGHTPLANS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
      case GET_HOMEBUYER_PERSONA_SUCCESS:
        state = {
          ...state,
          all_homeBuyerPersonas: action.payload,
        }
        break
      case GET_HOMEBUYER_PERSONA_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
        case GET_TARGETED_COHORT_TABS_SUCCESS:
          state = {
            ...state,
            all_targeted_cohort_tabs: action.payload,
          }
          break
        case GET_TARGETED_COHORT_TABS_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
          case GET_TARGETED_COHORT_DETAILS_SUCCESS:
            state = {
              ...state,
              targeted_cohort_detail: action.payload,
            }
            break
          case GET_TARGETED_COHORT_DETAILS_FAIL:
            state = {
              ...state,
              error: action.payload,
            }
            break
            case GET_LANDING_PAGES_DETAILS_SUCCESS:
            state = {
              ...state,
              all_landing_pages_detail: action.payload,
            }
            break
          case GET_LANDING_PAGES_DETAILS_FAIL:
            state = {
              ...state,
              error: action.payload,
            }
            break
            case GET_AD_GALLERY_TABS_SUCCESS:
            state = {
              ...state,
              all_ad_gallery_tabs: action.payload,
            }
            break
          case GET_AD_GALLERY_TABS_FAIL:
            state = {
              ...state,
              error: action.payload,
            }
            break
            case GET_AD_GALLERY_DETAILS_SUCCESS:
              state = {
                ...state,
                ad_gallery_detail: action.payload,
              }
              break
            case GET_AD_GALLERY_DETAILS_FAIL:
              state = {
                ...state,
                error: action.payload,
              }
              break
            case GET_MEDIA_PLAN_URL_SUCCESS:
              state = {
                ...state,
                media_plan_url: action.payload,
              }
              break
            case GET_MEDIA_PLAN_URL_FAIL:
              state = {
                ...state,
                error: action.payload,
              } 
              break
            default:
              state = { ...state }
              break
    }
    return state
  }
  
  export default strategy