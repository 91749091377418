import {
  SAVE_MEDIA_INTAKE,
  SAVE_MEDIA_INTAKE_SUCCESSFUL,
  SAVE_MEDIA_INTAKE_FAIL,
  GET_MEDIA_INTAKE,
  GET_MEDIA_INTAKE_SUCCESS,
  GET_MEDIA_INTAKE_FAIL,
  UPDATE_MEDIA_INTAKE,
  UPDATE_MEDIA_INTAKE_SUCCESS,
  UPDATE_MEDIA_INTAKE_FAIL,
  DELETE_MEDIA_INTAKE,
  DELETE_MEDIA_INTAKE_SUCCESS,
  DELETE_MEDIA_INTAKE_FAIL,
  SAVE_SALES_INTAKE,
  SAVE_SALES_INTAKE_SUCCESSFUL,
  SAVE_SALES_INTAKE_FAIL,
  GET_SALES_INTAKE,
  GET_SALES_INTAKE_SUCCESS,
  GET_SALES_INTAKE_FAIL,
  SAVE_CREATIVE_INTAKE,
  SAVE_CREATIVE_INTAKE_SUCCESSFUL,
  SAVE_CREATIVE_INTAKE_FAIL,
  GET_CREATIVE_INTAKE,
  GET_CREATIVE_INTAKE_SUCCESS,
  GET_CREATIVE_INTAKE_FAIL,
  SAVE_TECHNICAL_INTAKE,
  SAVE_TECHNICAL_INTAKE_SUCCESSFUL,
  SAVE_TECHNICAL_INTAKE_FAIL,
  GET_TECHNICAL_INTAKE,
  GET_TECHNICAL_INTAKE_SUCCESS,
  GET_TECHNICAL_INTAKE_FAIL,
  DELETE_SALES_INTAKE,
  DELETE_SALES_INTAKE_SUCCESS,
  DELETE_SALES_INTAKE_FAIL,
  DELETE_TECHNICAL_INTAKE,
  DELETE_TECHNICAL_INTAKE_SUCCESS,
  DELETE_TECHNICAL_INTAKE_FAIL,
  DELETE_CREATIVE_INTAKE,
  DELETE_CREATIVE_INTAKE_SUCCESS,
  DELETE_CREATIVE_INTAKE_FAIL,
} from "./actionTypes"

export const saveMediaIntake = params => {
  return {
    type: SAVE_MEDIA_INTAKE,
    payload: params,
  }
}

export const saveMediaIntakeSuccess = data => {
  return {
    type: SAVE_MEDIA_INTAKE_SUCCESSFUL,
    payload: data,
  }
}

export const saveMediaIntakeFail = data => {
  return {
    type: SAVE_MEDIA_INTAKE_FAIL,
    payload: data,
  }
}

export const getMediaIntake = params => ({
  type: GET_MEDIA_INTAKE,
  payload: params
})

export const getMediaIntakeSuccess = data => ({
  type: GET_MEDIA_INTAKE_SUCCESS,
  payload: data,
})

export const getMediaIntakeFail = error => ({
  type: GET_MEDIA_INTAKE_FAIL,
  payload: error,
})

export const updateMediaIntake = data => ({
  type: UPDATE_MEDIA_INTAKE,
  payload: data,
})

export const updateMediaIntakeSuccess = data => ({
  type: UPDATE_MEDIA_INTAKE_SUCCESS,
  payload: data,
})

export const updateMediaIntakeFail = error => ({
  type: UPDATE_MEDIA_INTAKE_FAIL,
  payload: error,
})

export const deleteMediaIntake = data => ({
  type: DELETE_MEDIA_INTAKE,
  payload: data,
})

export const deleteMediaIntakeSuccess = data => ({
  type: DELETE_MEDIA_INTAKE_SUCCESS,
  payload: data,
})

export const deleteMediaIntakeFail = error => ({
  type: DELETE_MEDIA_INTAKE_FAIL,
  payload: error,
})

export const saveSalesIntake = params => {
  return {
    type: SAVE_SALES_INTAKE,
    payload: params,
  }
}

export const saveSalesIntakeSuccess = data => {
  return {
    type: SAVE_SALES_INTAKE_SUCCESSFUL,
    payload: data,
  }
}

export const saveSalesIntakeFail = data => {
  return {
    type: SAVE_SALES_INTAKE_FAIL,
    payload: data,
  }
}

export const getSalesIntake = params => ({
  type: GET_SALES_INTAKE,
  payload: params,
})

export const getSalesIntakeSuccess = data => ({
  type: GET_SALES_INTAKE_SUCCESS,
  payload: data,
})

export const getSalesIntakeFail = error => ({
  type: GET_SALES_INTAKE_FAIL,
  payload: error,
})

export const deleteSalesIntake = data => ({
  type: DELETE_SALES_INTAKE,
  payload: data,
})

export const deleteSalesIntakeSuccess = data => ({
  type: DELETE_SALES_INTAKE_SUCCESS,
  payload: data,
})

export const deleteSalesIntakeeFail = error => ({
  type: DELETE_SALES_INTAKE_FAIL,
  payload: error,
})

export const saveCreativeIntake = params => {
  return {
    type: SAVE_CREATIVE_INTAKE,
    payload: params,
  }
}

export const saveCreativeIntakeSuccess = data => {
  return {
    type: SAVE_CREATIVE_INTAKE_SUCCESSFUL,
    payload: data,
  }
}

export const saveCreativeIntakeFail = data => {
  return {
    type: SAVE_CREATIVE_INTAKE_FAIL,
    payload: data,
  }
}

export const getCreativeIntake = params => ({
  type: GET_CREATIVE_INTAKE,
  payload: params,
})

export const getCreativeIntakeSuccess = data => ({
  type: GET_CREATIVE_INTAKE_SUCCESS,
  payload: data,
})

export const getCreativeIntakeFail = error => ({
  type: GET_CREATIVE_INTAKE_FAIL,
  payload: error,
})

export const deleteCreativeIntake = data => ({
  type: DELETE_CREATIVE_INTAKE,
  payload: data,
})

export const deleteCreativeIntakeSuccess = data => ({
  type: DELETE_CREATIVE_INTAKE_SUCCESS,
  payload: data,
})

export const deleteCreativeIntakeFail = error => ({
  type: DELETE_CREATIVE_INTAKE_FAIL,
  payload: error,
})

export const saveTechnicalIntake = params => {
  return {
    type: SAVE_TECHNICAL_INTAKE,
    payload: params,
  }
}

export const saveTechnicalIntakeSuccess = data => {
  return {
    type: SAVE_TECHNICAL_INTAKE_SUCCESSFUL,
    payload: data,
  }
}

export const saveTechnicalIntakeFail = data => {
  return {
    type: SAVE_TECHNICAL_INTAKE_FAIL,
    payload: data,
  }
}

export const getTechnicalIntake = params => ({
  type: GET_TECHNICAL_INTAKE,
  payload: params,
})

export const getTechnicalIntakeSuccess = data => ({
  type: GET_TECHNICAL_INTAKE_SUCCESS,
  payload: data,
})

export const getTechnicalIntakeFail = error => ({
  type: GET_TECHNICAL_INTAKE_FAIL,
  payload: error,
})

export const deleteTechnicalIntake = data => ({
  type: DELETE_TECHNICAL_INTAKE,
  payload: data,
})

export const deleteTechnicalIntakeSuccess = data => ({
  type: DELETE_TECHNICAL_INTAKE_SUCCESS,
  payload: data,
})

export const deleteTechnicalIntakeFail = error => ({
  type: DELETE_TECHNICAL_INTAKE_FAIL,
  payload: error,
})
