import {
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  verifySuccessMsg: null,
  verifyError: null,
}

const verifyPassword = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_PASSWORD:
      state = {
        ...state,
        verifySuccessMsg: null,
        verifyError: null,
      }
      break
    case VERIFY_PASSWORD_SUCCESS:
      state = {
        ...state,
        verifySuccessMsg: action.payload,
        verifyError: null,
      }
      break
    case VERIFY_PASSWORD_ERROR:
      state = {
        ...state,
        verifySuccessMsg: null,
        verifyError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default verifyPassword
