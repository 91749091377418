import {
  SAVE_COMPANY,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAIL,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_ALL_COMPANY_ROLE,
  GET_ALL_COMPANY_ROLE_SUCCESS,
  GET_ALL_COMPANY_ROLE_FAIL,
  GET_ALL_LOCATION,
  GET_ALL_LOCATION_SUCCESS,
  GET_ALL_LOCATION_FAIL
} from "./actionTypes"

export const saveCompany = company => {
  return {
    type: SAVE_COMPANY,
    payload: { company },
  }
}

export const saveCompanySuccess = company => {
  return {
    type: SAVE_COMPANY_SUCCESS,
    payload: company,
  }
}

export const saveCompanyFail = company => {
  return {
    type: SAVE_COMPANY_FAIL,
    payload: company,
  }
}
export const getCompanies = () => ({
  type: GET_COMPANIES,
})

export const getCompaniesSuccess = companies => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
})

export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const deleteCompany = company => ({
  type: DELETE_COMPANY,
  payload: company,
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})

export const getCompanyRole = () => ({
  type: GET_ALL_COMPANY_ROLE,
})
export const getCompanyRoleSuccess = users => ({
  type: GET_ALL_COMPANY_ROLE_SUCCESS,
  payload: users,
})

export const getCompanyRoleFail = error => ({
  type: GET_ALL_COMPANY_ROLE_FAIL,
  payload: error,
})

export const getLocation = () => ({
  type: GET_ALL_LOCATION,
})
export const getLocationSuccess = users => ({
  type: GET_ALL_LOCATION_SUCCESS,
  payload: users,
})

export const getLocationFail = error => ({
  type: GET_ALL_LOCATION_FAIL,
  payload: error,
})

