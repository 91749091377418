import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE_TYPE,
  GET_ROLE_TYPE_SUCCESS,
  GET_ROLE_TYPE_FAIL,
  UPDATE_PROFILE_PHOTO,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
  RESET_ROLE_TYPE,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  all_users: null,
  updateUserSuccess: null,
  updateUserFail: null,
  deleteUserSuccess: null,
  deleteUserFail: null,
  group: [],
  role: [],
  roleType: [],
  updateProfilePhoto: [],
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        all_users: action.payload,
      }
      break
    case GET_ALL_USERS_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        updateUserSuccess: action.payload,
        updateUserFail: null,
        // all_users: state.all_users?.data.map(user =>
        //   user.id.toString() === action.payload.id.toString()
        //     ? { user, ...action.payload }
        //     : user
        // ),
      }
      break
    case UPDATE_USER_FAIL:
      state = {
        ...state,
        updateUserSuccess: null,
        updateUserFail: action.payload,
      }
      break
    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        deleteUserSuccess: action.payload,
        deleteUserFail: null,
        // users: state.all_users?.data.filter(
        //   user => user.id.toString() !== action.payload.id.toString()
        // ),
      }
      break
    case DELETE_USER_FAIL:
      state = {
        ...state,
        deleteUserSuccess: null,
        deleteUserFail: action.payload,
      }
      break
    case GET_GROUP:
      state = {
        ...state,
        loading: true,
        group: null,
      }
      break
    case GET_GROUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        group: action.payload,
      }
      break
    case GET_GROUP_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case GET_ROLE:
      state = {
        ...state,
        loading: true,
        role: null,
      }
      break
    case GET_ROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        role: action.payload,
      }
      break
    case GET_ROLE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case GET_ROLE_TYPE:
      state = {
        ...state,
        loading: true,
        roleType: null,
      }
      break
    case GET_ROLE_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        roleType: action.payload,
      }
      break
    case GET_ROLE_TYPE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case RESET_ROLE_TYPE:
      state = {
        ...state,
        loading: false,
        roleType: action.payload,
      }
      break
    case UPDATE_PROFILE_PHOTO:
      state = {
        ...state,
        loading: true,
        updateProfilePhoto: null,
      }
      break
    case UPDATE_PROFILE_PHOTO_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateProfilePhoto: action.payload,
      }
      break
    case UPDATE_PROFILE_PHOTO_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
