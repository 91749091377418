import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { CAMPAIGN_CREATE,GET_ALL_CAMPAIGN,UPDATE_CAMPAIGN,DELETE_CAMPAIGN,GET_ALL_FLIGHT_PHASE,UPLOAD_COVER_PHOTO,
GET_ALL_VIEW_PERMISSION,
SAVE_VIEW_PERMISSIONE,
GET_CAMPAIGN_BY_ID,
GET_ALL_COUNTRIES,
GET_CAMPAIGN_PERSONALIZE,
} from "./actionTypes"
import { campaingCreateSuccessful, campaingCreateFailed,getCampaignSuccess,getCampaignFail,updateCampaignSuccess,updateCampaignFail,deleteCampaignSuccess,deleteCampaignrFail,
  getFlightPhaseSuccess,
  getFlightPhaseFail,
  uploadCoverPhotoSuccess,
  uploadCoverPhotoFail,
  getViewPermissionSuccess,
  getViewPermissionFail,
  saveViewPermissionSuccess,
  saveViewPermissionFail,
  getCampaignByIdSuccess,
  getCampaignByIdFail,
  getCountriesSuccess,
  getCountriesFail,
  getCampaignPersonalizeSuceess,
  getCampaignPersonalizeError,
} from "./actions"
import { createNewCampaign, getAllCampaign, updateCampaign,deleteCampaign,getAllFlightPhase,uploadCoverPhoto, getAllViewPermission,
  saveViewPermission,
  getCampaignById,
  getAllCountries,
  getPersonalizedData
 } from "helpers/senara_backend/senarabackend_helper"
import { setCampaignId } from "store/dashboard-widgets/actions"

// Campanig Create.
function* campaingCreate({ payload: { data } }) {
  try {
    //console.log('create campaing data===>', data)
    const { result, error } = yield call(createNewCampaign, data)
    if (result) yield put(campaingCreateSuccessful(result))
    if (error) yield put(campaingCreateFailed(error))
  } catch (error) {
    yield put(campaingCreateFailed(error))
  }
}

function* fetchAllCampaign({payload: params}) {
  try {
    const { result, error } = yield call(getAllCampaign)
    if(result) {
      yield put(getCampaignSuccess(result))
      if(params === 'SET_CAMPAIGN_ID' && !sessionStorage.getItem('campaignId'))yield put(setCampaignId(result?.data[0]?.id))
    }
    if(error) yield put(getCampaignFail(error))
  } catch (error) {
    yield put(getCampaignFail(error))
  }
}

function* onUpdateCampaign({ payload: data }) {
  try {
    const {result , error} = yield call(updateCampaign, data)
    if(result)yield put(updateCampaignSuccess(result))
    if(error)yield put(updateCampaignFail(error))
  } catch (error) {
    yield put(updateCampaignFail(error))
  }
}

function* onDeleteCampaign({ payload: data }) {
  try {
    const {result, error} = yield call(deleteCampaign, data)
    if(result)yield put(deleteCampaignSuccess(result))
    if(error)yield put(deleteCampaignrFail(error))
  } catch (error) {
    yield put(deleteCampaignrFail(error))
  }
}

function* fetchAllFlightPhase() {
  try {
    const { result, error } = yield call(getAllFlightPhase)
    //console.log('in get all Flight Phase saga==>', result, error)
    if(result) yield put(getFlightPhaseSuccess(result))
    if(error) yield put(getFlightPhaseFail(error))
  } catch (error) {
    yield put(getFlightPhaseFail(error))
  }
}

function* fetchuploadCoverPhoto({ payload }) {
  //console.log('Cover Images Saga payload===>', payload)
  const { file } = payload
  try {
    const { result, error } = yield call(uploadCoverPhoto, {file})
    //console.log('Upload Images Saga response===>', result, error)
    if (result) {
      yield put(uploadCoverPhotoSuccess({...result,original:file}))
    }
    if (error) {
      yield put(uploadCoverPhotoFail({...error,original:file}))
    }
  } catch (error) {
    //console.log("upload images error===>", error)
    yield put(uploadCoverPhotoFail({...error,original:file}))
  }
}

function* saveViewPermissionDetails({payload: data}) {
  try{
      // let query = params?.campaign_id
      const { result, error } = yield call(saveViewPermission, data)
      if(result)yield put(saveViewPermissionSuccess(result))
      if(error)yield put(saveViewPermissionFail(error))
  } catch (error) {
    yield put(saveViewPermissionFail(error))
  }
}

function* fetchAllViewPermission({payload: {id,isUser}}) {
  try {
    const { result, error } = yield call(getAllViewPermission,id)
    if(result) yield put(getViewPermissionSuccess({result,isUser}))
    if(error) yield put(getViewPermissionFail(error))
  } catch (error) {
    yield put(getViewPermissionFail(error))
  }
}

function* fetchCampaignById({payload: {id,isActiveCam}}) {
  try {
    const { result, error } = yield call(getCampaignById,id)
    if(result) yield put(getCampaignByIdSuccess({result,isActiveCam}))
    if(error) yield put(getCampaignByIdFail(error))
  } catch (error) {
    yield put(getCampaignByIdFail(error))
  }
}

function* fetchAllCountries() {
  try {
    const { result, error } = yield call(getAllCountries)
    if(result) yield put(getCountriesSuccess(result))
    if(error) yield put(getCountriesFail(error))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* fetchPersonalizedData({payload: {id,isActiveCam}}) {
  try {
    const { result, error } = yield call(getPersonalizedData, id)
    if (result) yield put(getCampaignPersonalizeSuceess({result,isActiveCam}))
    if (error) yield put(getCampaignPersonalizeError(error))
  } catch (error) {
    yield put(getCampaignPersonalizeError(error))
  }
}

export function* watchCreateCampaing() {
  yield takeEvery(CAMPAIGN_CREATE, campaingCreate),
  yield takeEvery(GET_ALL_CAMPAIGN, fetchAllCampaign),
  yield takeEvery(UPDATE_CAMPAIGN, onUpdateCampaign),
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign),
  yield takeEvery(GET_ALL_FLIGHT_PHASE, fetchAllFlightPhase),
  yield takeEvery(UPLOAD_COVER_PHOTO, fetchuploadCoverPhoto),
  yield takeEvery(SAVE_VIEW_PERMISSIONE, saveViewPermissionDetails),
  yield takeEvery(GET_ALL_VIEW_PERMISSION, fetchAllViewPermission)
  yield takeEvery(GET_CAMPAIGN_BY_ID, fetchCampaignById)
  yield takeEvery(GET_ALL_COUNTRIES, fetchAllCountries)
  yield takeEvery(GET_CAMPAIGN_PERSONALIZE, fetchPersonalizedData)
}

function* campaingSaga() {
  yield all([fork(watchCreateCampaing)])
}

export default campaingSaga
