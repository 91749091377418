/**
 * Add Media Intake
 */
export const SAVE_EMAIL_NURTURING = "SAVE_EMAIL_NURTURING"
export const SAVE_EMAIL_NURTURING_SUCCESSFUL = "SAVE_EMAIL_NURTURING_SUCCESSFUL"
export const SAVE_EMAIL_NURTURING_FAIL = "SAVE_EMAIL_NURTURING_FAIL"

 /**
 * Get Email Nurturing
 */
export const GET_EMAIL_NURTURING  = "GET_EMAIL_NURTURING"
export const GET_EMAIL_NURTURING_SUCCESS = "GET_EMAIL_NURTURING_SUCCESS"
export const GET_EMAIL_NURTURING_FAIL = "GET_EMAIL_NURTURING_FAIL"

   /**
 * Delete  Email Nurturing
 */
export const DELETE_EMAIL_NURTURING = "DELETE_EMAIL_NURTURING"
export const DELETE_EMAIL_NURTURING_SUCCESS = "DELETE_EMAIL_NURTURING_SUCCESS"
export const DELETE_EMAIL_NURTURING_FAIL = "DELETE_EMAIL_NURTURING_FAIL"