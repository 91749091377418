import { 
SAVE_EMAIL_NURTURING,
SAVE_EMAIL_NURTURING_SUCCESSFUL,
SAVE_EMAIL_NURTURING_FAIL,
GET_EMAIL_NURTURING_SUCCESS,
GET_EMAIL_NURTURING_FAIL,
DELETE_EMAIL_NURTURING_SUCCESS,
DELETE_EMAIL_NURTURING_FAIL
} from "./actionTypes";

const initialState = {
    saveEmailNurturingSuccess: null,
    saveEmailNurturingError: null,
    all_email_nurturing: null,
    deleteEmailNurturingSuccess: null,
    deleteEmailNurturingFail: null,
    loading: false,
    data: null,
}
const emailnurturing = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EMAIL_NURTURING:
          state = {
            ...state,
            loading: true,
            saveEmailNurturingError: null,
          }
          break
        case SAVE_EMAIL_NURTURING_SUCCESSFUL:
          state = {
            ...state,
            loading: false,
            saveEmailNurturingSuccess: action.payload,
            saveEmailNurturingError: null,
          }
          break
        case SAVE_EMAIL_NURTURING_FAIL:
          state = {
            ...state,
            data: null,
            loading: false,
            saveEmailNurturingError: action.payload,
          }
          break
        case GET_EMAIL_NURTURING_SUCCESS:
          state = {
              ...state,
              all_email_nurturing: action.payload,
          }
          break
        case GET_EMAIL_NURTURING_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
        case DELETE_EMAIL_NURTURING_SUCCESS:
            state = {
                ...state,
                deleteEmailNurturingSuccess: action.payload,
                deleteEmailNurturingFail: null
            }
          break
        case DELETE_EMAIL_NURTURING_FAIL:
            state = {
              ...state,
              deleteEmailNurturingSuccess: null,
              deleteEmailNurturingFail: action.payload,
            }
          break  
        default:
          state = { ...state }
          break
      }
      return state
}
export default emailnurturing