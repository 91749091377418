// auth
export const LOGIN = "/login"
export const VERIFY = "/user/verify"
export const FORGOT_PASSWORD = "/forget-password"
export const RESET_PASSWORD = "/reset-password"
export const CHANGE_PASSWORD = "/user/change-password"

// Update Profile Picture
export const UPDATE_PROFILE_PHOTO = "/user/update-profile-photo?profile_photo="

// company
export const CREATE_UPDATE_COMPANY = "/company/save"
export const GET_ALL_COMPANIES = "/company/get"
export const DELETE_COMPANY = "/company/delete"

// user
export const CREATE_USER = "/user/save"
export const GET_ALL_USERS = "/user/get"
export const UPDATE_USER = "/user/update"
export const DELETE_USER = "/user/delete"

// campaigns
export const CREATE_CAMPAIGN = "/campaign/save"
export const GET_ALL_CAMPAIGN = "/campaign/get"
export const GET_CAMPAIGN_BY_ID = "/campaign/get-detail-by-id?campaign_id="
export const UPDATE_CAMPAIGN = "/campaign/save"
export const DELETE_CAMPAIGN = "/campaign/delete"

//Dashboard Widgets
export const GET_LEADS = "/overview/leads?campaign_id="
export const GET_QUALIFIED_LEADS = "/overview/qualified_leads?campaign_id="
export const GET_FIRM_SALES = "/overview/firm_sales?campaign_id="
export const GET_SPENDS = "/overview/spends?campaign_id="
export const GET_APPOINTMENTS_HELD = "/overview/appointments_held?campaign_id="
export const GET_REVENUE = "/overview/revenue?campaign_id="

//Sales Pipeline Progression
export const GET_SALES_PIPELINE_PROGRESSION =
  "/overview/sales-pipeline-progression?campaign_id="

//Sales Prospects
export const GET_SALES_PROSPECTS = "/overview/sales-prospects?campaign_id="

//Sales Prospects
export const GET_ACTIVITIES = "/activity/get?campaign_id="

//Leads Generation Widgets
export const GET_LEADS_GENERATION = "/leads/lead-total?campaign_id="
export const GET_COST_PER_LEAD = "/leads/cost-per-lead-total?campaign_id="
export const GET_LEADS_GENERATION_SPENDS = "/leads/spend-total?campaign_id="

//Channel Chart
export const GET_CHANNEL_CHART = "/leads/channel-chart?campaign_id="

//Persona Chart
export const GET_PERSONA_CHART = "/leads/persona-chart?campaign_id="

//Cohort Chart
export const GET_COHORT_CHART = "/leads/cohort-chart?campaign_id="

//Lead Generation Report
export const GET_LEADS_GENERATION_REPORT = "/leads/report?campaign_id="

//Lead Cost Performance
export const GET_LEADS_COST_PERFORMANCE =
  "/leads/lead-cost-performance?campaign_id="

//Flight Plans
export const GET_FLIGHT_PLANS = "/media/flight-plan?campaign_id="
//Lead Cost Performance
export const GET_LEADS_CREATIVE_VISITS = "/creative/visits-total?campaign_id="

//Creative Click through rete
export const GET_CLICK_THROUGH_RATE =
  "/creative/cost-through-rate-total?campaign_id="

//Creative Click through rete
export const GET_COST_PER_CLICK = "/creative/cost-per-click-total?campaign_id="

//Concept Chart
export const GET_CONCEPT_CHART = "/creative/concept-chart?campaign_id="

//visual Chart
export const GET_VISUAL_CHART = "/creative/visual-chart?campaign_id="

//Get Copy Strategy Chart
export const GET_COPY_STRATEGY_CHART =
  "/creative/copy-strategy-chart?campaign_id="

//Get Copy Strategy Chart
export const GET_TAG_LINE_CHART = "/creative/tagline-chart?campaign_id="

//Top Ads
export const GET_TOP_ADS = "/creative/top-ads-name?campaign_id="

//Home buyers persona
export const GET_HOMEBUYER_PERSONA = "/audience/home-buyer-persona?campaign_id="

//Top Ads Details
export const GET_TOP_ADS_DETAILS = "/creative/top-ads-details?campaign_id="

//Creative Report
export const GET_CREATIVE_REPORT = "/creative/report?campaign_id="

//Targeted Cohort tabs
export const GET_TARGETED_COHORT_TABS =
  "/audience/targeted-cohort-persona?campaign_id="

//Targeted Cohort details
export const GET_TARGETED_COHORT_DETAILS =
  "/audience/targeted-cohort-details?campaign_id="

//Landing Pages details
export const GET_LANDING_PAGES_DETAILS = "/journey/landing-page?campaign_id="

//Ad Gallery tabs
export const GET_AD_GALLERY_TABS = "/journey/ad-gallery-name?campaign_id="

//Ad Gallery details
export const GET_AD_GALLERY_DETAILS = "/journey/ad-gallery-details?campaign_id="

//Ad Gallery details
export const GET_LOCATION_REPORT = "/location/report?campaign_id="

//Ad Gallery details
export const GET_LOCATION_MAP = "/location/map?campaign_id="

//Add Images Gallery
export const UPLOAD_IMAGES = "/upload/image"

//Notifications
export const GET_NOTIFICATIONS = "/notification/get?campaign_id="

//Profitability Widgets
export const GET_END_USER_SALES = "/profitability/end-user-sales?campaign_id="
export const GET_UNREPRESENTED_RATIO =
  "/profitability/unrepresented-ratio?campaign_id="
export const GET_COMMISSIONS_SAVED =
  "/profitability/commission-saved?campaign_id="

//Remaining Inventory Chart
export const GET_REMAINING_INVENTORY_CHART =
  "/profitability/remaining-inventory-chart?campaign_id="

//Demand Ratio Chart
export const GET_DEMAND_RATIO_CHART =
  "/profitability/demand-ratio-chart?campaign_id="

//Remaining Inventory Report
export const GET_REMAINING_INVENTORY_REPORT =
  "/profitability/remaining-inventory-report?campaign_id="

//Demand Ratio Report
export const GET_DEMAND_RATIO_REPORT =
  "/profitability/demand-ratio-report?campaign_id="

// company Role
export const GET_COMPANY_ROLES = "/company/role"

// user group
export const GET_GROUP = "/group/get"

// user role
export const GET_ROLE = "/role/get?group_id="

// user role type
export const GET_ROLE_TYPE = "/role/type?role_id="

// company Locations
export const GET_LOCATION = "/country/get"

// Flight Phase
export const GET_FLIGHT_PHASE = "/flight-phase/get"

//Add Cover Photo For Campaign
export const UPLOAD_COVER_PHOTO = "/upload/image"

// Media Intake
export const CREATE_MEDIA_INTAKE = "/intake/media/save?campaign_id="
export const GET_ALL_MEDIA_INTAKE = "/intake/media/get?campaign_id="
export const UPDATE_MEDIA_INTAKE = "/intake/media/save"
export const DELETE_MEDIA_INTAKE = "/intake/media/delete"

// Technical Intake
export const CREATE_TECHNICAL_INTAKE = "/intake/technical/save?campaign_id="
export const GET_ALL_TECHNICAL_INTAKE = "/intake/technical/get?campaign_id="
export const DELETE_TECHNICAL_INTAKE = "/intake/technical/delete"

// Sales Intake
export const CREATE_SALES_INTAKE = "/intake/sales/save?campaign_id="
export const GET_ALL_SALES_INTAKE = "/intake/sales/get?campaign_id="
export const DELETE_SALES_INTAKE = "/intake/sales/delete"

// Creative Intake
export const CREATE_CREATIVE_INTAKE = "/intake/creative/save?campaign_id="
export const GET_ALL_CREATIVE_INTAKE = "/intake/creative/get?campaign_id="
export const DELETE_CREATIVE_INTAKE = "/intake/sales/delete"

// Prospect Nurturing
export const CREATE_EMAIL_NURTURING = "/email-nurturing/save"

//View Permission
export const GET_ALL_VIEW_PERMISSION = "/campaign/view-permission?campaign_id="

// Save View Permission
export const SAVE_VIEW_PERMISSION = "/campaign/save-view-permission"

///Map Channel
export const GET_MAP_CHANNEL = "/location/map-channel?campaign_id="

///Map Persona
export const GET_MAP_PERSONA = "/location/map-persona?campaign_id="

///Map Cohort
export const GET_MAP_COHORT = "/location/map-cohort?campaign_id="

export const GET_ALL_SALES_PROSPECT_IQ =
  "/sales-prospect/get-detail-by-id?campaign_id="

//Media Plan Url
export const GET_MEDIA_PLAN_URL = "/media/plan-url?campaign_id="

//Email Nurturing
export const GET_EMAIL_NURTURING = "/email-nurturing/get?campaign_id="

//Email Nurturing Delete
export const DELETE_EMAIL_NURTURING = "/email-nurturing/delete?campaign_id="

// countries
export const GET_COUNTRIES = "/campaign/region"

// Campaign Validate Details
export const GET_CAMPAIGN_VALIDATE_DETAILS = "/campaign/validate-details"

// Sales Phases Details
export const GET_SALES_PHASES_DETAILS = "/campaign/sales-phases?campaign_id="

// Campaign User Admin
export const GET_ADMIN_USERS = "/user/admin"

// Sales Phases Details
export const GET_ACTIVITY_USERS_DETAILS = "/activity/users?campaign_id="

// Map Suite Type
export const GET_MAP_SUITE_TYPE = "/location/map-suite-type?campaign_id="

// Map Price Range
export const GET_MAP_PRICE_RANGE = "/location/map-price-range?campaign_id="

// Map Is Realtor
export const GET_MAP_IS_REALTOR = "/location/map-is-realtor?campaign_id="

// Map Has Realtor 
export const GET_MAP_HAS_REALTOR = "/location/map-with-realtor?campaign_id="

// Map Senara Score 
export const GET_MAP_SENARA_SCORE = "/location/map-senara-score?campaign_id="

// Map Purchased Suite Type
export const GET_MAP_PURCHASED_SUITE_TYPE = "/location/map-purchased-suite-type?campaign_id="

// Map Purchased Suite Price
export const GET_MAP_PURCHASED_SUITE_PRICE = "/location/map-purchased-suite-price?campaign_id="

// File Upload
export const UPLOAD_FILES_URL = "/upload/pre-signed-url"
// personalize
export const GET_PERSONALIZE = "/campaign/personalize?campaign_id="
