import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { SAVE_EMAIL_NURTURING,GET_EMAIL_NURTURING,DELETE_EMAIL_NURTURING } from "./actionTypes"

import { saveEmailNurturingFail, saveEmailNurturingSuccess,
getEmailNurturingSuccess,
getEmailNurturingFail,
deleteEmailNurturingSuccess,
deleteEmailNurturingFail
} from "./actions"

import { createNewEmailNurturing, getEmailNurturing,deleteEmailNurturing } from "helpers/senara_backend/senarabackend_helper"

function* saveEmailNurturingDetails({payload: data}) {
    try{
        const { result, error } = yield call(createNewEmailNurturing, data)
        if(result)yield put(saveEmailNurturingSuccess(result))
        if(error)yield put(saveEmailNurturingFail(error))
    } catch (error) {
      yield put(saveEmailNurturingFail(error))
    }
}

function* fetchEmailNurturing ({payload: id}) {
    try {
      const { result, error } = yield call(getEmailNurturing,id)
      if(result) yield put(getEmailNurturingSuccess(result))
      if(error) yield put(getEmailNurturingFail(error))
    } catch (error) {
      yield put(getEmailNurturingFail(error))
    }
  }

function* onDeleteEmailNurturing({ payload: id }) {
    try {
      const {result, error} = yield call(deleteEmailNurturing, id)
      if(result)yield put(deleteEmailNurturingSuccess(result))
      if(error)yield put(deleteEmailNurturingFail(error))
    } catch (error) {
      yield put(deleteEmailNurturingFail(error))
    }
}

export function* watchEmailNurturing() {
    yield takeEvery(SAVE_EMAIL_NURTURING, saveEmailNurturingDetails)
    yield takeEvery(GET_EMAIL_NURTURING, fetchEmailNurturing)
    yield takeEvery(DELETE_EMAIL_NURTURING, onDeleteEmailNurturing)
}

function* emailNurturingSaga() {
 yield all([fork(watchEmailNurturing)])
}
  
export default emailNurturingSaga