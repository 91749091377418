import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE_TYPE,
  GET_ROLE_TYPE_SUCCESS,
  GET_ROLE_TYPE_FAIL,
  UPDATE_PROFILE_PHOTO,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
  RESET_ROLE_TYPE,
} from "./actionTypes"

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}
export const getUsers = () => ({
  type: GET_ALL_USERS,
})
export const getUsersSuccess = users => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_ALL_USERS_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const getGroup = () => ({
  type: GET_GROUP,
})

export const getGroupSuccess = groups => ({
  type: GET_GROUP_SUCCESS,
  payload: groups,
})

export const getGroupFail = error => ({
  type: GET_GROUP_FAIL,
  payload: error,
})

export const getRole = groupID => ({
  type: GET_ROLE,
  payload: groupID,
})

export const getRoleSuccess = role => ({
  type: GET_ROLE_SUCCESS,
  payload: role,
})

export const getRoleFail = error => ({
  type: GET_ROLE_FAIL,
  payload: error,
})

export const getRoleType = roleID => ({
  type: GET_ROLE_TYPE,
  payload: roleID,
})

export const getRoleTypeSuccess = roleType => ({
  type: GET_ROLE_TYPE_SUCCESS,
  payload: roleType,
})

export const getRoleTypeError = error => ({
  type: GET_ROLE_TYPE_FAIL,
  payload: error,
})

export const resetRoleType = () => ({
  type: RESET_ROLE_TYPE,
  payload: [],
})

export const updateProfilePhoto = url => ({
  type: UPDATE_PROFILE_PHOTO,
  payload: url,
})

export const updateProfilePhotoSuccess = data => ({
  type: UPDATE_PROFILE_PHOTO_SUCCESS,
  payload: data,
})

export const updateProfilePhotoError = error => ({
  type: UPDATE_PROFILE_PHOTO_FAIL,
  payload: error,
})
