import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import {
  IconAdminMenu,
  IconDataConcierge,
  IconLeadGeneration,
  IconOverview,
  IconStrategy,
  IconProfitabilityMenu,
  IconIntakeMenu,
} from "assets/images/senaraIndex"

import { IconSenaraLogo02, IconSenaraLogo05 } from "assets/images/senaraIndex"
import { CardSubtitle } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { getAllViewPermission } from "store/campaigns/actions"

const SidebarContent = props => {
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState()

  const current_user = JSON.parse(localStorage.getItem("authUser")).data?.user_role
  // const current_user_company_role = JSON.parse(localStorage.getItem("authUser")).data?.company_role_id

  const { all_view_user_permission } = useSelector(state => ({
    all_view_user_permission: state.Campaing.all_view_user_permission,
  }))
  
  const { campaignId } = useSelector(state => ({
    campaignId: state.Dashboard.campaignId,
  }))

  const getData = id => {
    dispatch(getAllViewPermission(id, "user"))
  }
  useEffect(() => {
    if (campaignId != null && campaignId !== "NO_CAMPAIGNS_ASSIGNED") {
      getData(campaignId)
    }
  }, [campaignId])

  useEffect(() => {
    let permission = {}
    all_view_user_permission?.data.map(obj => {
      permission[obj.short_code] = {
        show_to: obj.show_to,
      }
    })
    setPermissions(permission)
  }, [all_view_user_permission])

  const getPermissions = short_code =>
    current_user === 4 || current_user === 3 ? true
    : permissions?.[short_code]?.show_to === 1 ? true
    : permissions?.[short_code]?.show_to === 3 && current_user !== 5

  let isUser = false
  if (JSON.parse(localStorage.getItem("authUser"))) {
    if (
      JSON.parse(localStorage.getItem("authUser")).data?.user_role === 1 ||
      JSON.parse(localStorage.getItem("authUser")).data?.user_role === 2 ||
      JSON.parse(localStorage.getItem("authUser")).data?.user_role === 5
    ) {
      isUser = true
    }
  }

  const ref = useRef()
  const iconStyles = {
    minWidth: "20px",
    height: "20px",
    marginRight: "15px",
  }
  const iconStyles1 = {
    height: "20px",
    marginRight: "16px",
    marginLeft: "3px",
  }
  const iconStyles2 = {
    height: "18px",
    marginRight: "16px",
    marginLeft: "3px",
  }
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/overview" className="">
                <img src={IconOverview} alt="admin" style={iconStyles} />
                <span>{props.t("Overview")}</span>
              </Link>
            </li>
            <li>
              <Link to="/data" className="has-arrow">
                <img src={IconDataConcierge} alt="admin" style={iconStyles} />
                <span>{props.t("Data Concierge")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/data-concierge/key-activities">
                    {props.t("Key Activities")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/leads" className="has-arrow ">
                <img src={IconLeadGeneration} alt="admin" style={iconStyles} />
                <span>{props.t("Lead Generation")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  className={
                    getPermissions("page_prospect_iq") === true
                      ? "custom_show"
                      : "custom_hide"
                  }
                >
                  <Link to="/lead-generation/prospects">
                    {props.t("Prospects")}
                  </Link>
                </li>
                <li>
                  <Link to="/lead-generation/performance">
                    {props.t("Performance")}
                  </Link>
                </li>
                <li
                  className={
                    getPermissions("page_location") === true
                      ? "custom_show"
                      : "custom_hide"
                  }
                >
                  <Link to="/lead-generation/location">
                    {props.t("Location")}
                  </Link>
                </li>
                <li>
                  <Link to="/lead-generation/creative">
                    {props.t("Creative")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/strategy" className="has-arrow ">
                <img src={IconStrategy} alt="admin" style={iconStyles} />
                <span>{props.t("Strategy")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/strategy/audience">{props.t("Audience")}</Link>
                </li>
                <li>
                  <Link to="/strategy/media">{props.t("Media")} </Link>
                </li>
                <li>
                  <Link to="/strategy/journey">{props.t("Journey")} </Link>
                </li>
                <li
                  className={
                    getPermissions("page_prospect_nurturing") === true
                      ? "custom_show"
                      : "custom_hide"
                  }
                >
                  <Link to="/strategy/prospect-nurturing">
                    {props.t("Prospect Nurturing")}{" "}
                  </Link>
                  <Link
                    className="custom-add-url"
                    to="/strategy/prospect-nurturing/add-touchpoint"
                  ></Link>
                </li>
              </ul>
            </li>
            <li
              className={
                getPermissions("page_profitability") === true
                  ? "custom_show"
                  : "custom_hide"
              }
            >
              <Link to="/profitability" className="has-arrow ">
                <img
                  src={IconProfitabilityMenu}
                  alt="admin"
                  style={iconStyles}
                />
                <span>{props.t("Profitability")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/profitability/inventory">
                    {props.t("Inventory")}
                  </Link>
                </li>
              </ul>
            </li>
            {isUser && (
              <li
                className={
                  getPermissions("page_onboarding") === true
                    ? "custom_show"
                    : "custom_hide"
                }
              >
                <Link to="/intake" className="has-arrow ">
                  <img src={IconIntakeMenu} alt="admin" style={iconStyles1} />
                  <span>{props.t("Intake")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/intake/add-media">{props.t("Media")}</Link>
                  </li>
                  <li>
                    <Link to="/intake/add-technical-intake">
                      {props.t("Technical")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/intake/add-sales-intake">
                      {props.t("Sales")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/intake/add-creative-intake">
                      {props.t("Creative")}{" "}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {localStorage.getItem("authUser") &&
              (JSON.parse(localStorage.getItem("authUser")).data?.user_role ===
                3 ||
                JSON.parse(localStorage.getItem("authUser")).data?.user_role ===
                  4) && (
                <li>
                  <Link to="/admin" className="has-arrow">
                    <img src={IconAdminMenu} alt="admin" style={iconStyles} />
                    <span>{props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/admin/company">{props.t("Company")}</Link>
                      <Link
                        className="custom-add-url"
                        to="/admin/company/add-company"
                      ></Link>
                    </li>
                    <li>
                      <Link to="/admin/campaign">{props.t("Campaign")}</Link>
                      <Link
                        className="custom-add-url"
                        to="/admin/campaign/add-campaign"
                      ></Link>
                    </li>
                    <li>
                      <Link to="/admin/user">{props.t("User")}</Link>
                      <Link
                        className="custom-add-url"
                        to="/admin/user/add-user"
                      ></Link>
                    </li>
                    {/* <li>
                  <Link to="/admin/intake" className="has-arrow ">
                    <img src={IconStrategy} alt='admin' style={iconStyles}/>
                    <span>{props.t("Intake")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/admin/intake/media-intake">{props.t("Media")}</Link>
                    </li>
                    <li>
                      <Link to="/admin/intake/technical-intake">{props.t("Technical")} </Link>
                    </li>
                    <li>
                      <Link to="/admin/intake/sales-intake">{props.t("Sales")} </Link>
                    </li>
                    <li>
                      <Link to="/admin/intake/creative-intake">{props.t("Creative")} </Link>
                    </li>
                  </ul>
                 </li> */}
                    <li>
                      <Link to="/admin/assets">{props.t("Assets ")}</Link>
                    </li>
                  </ul>
                </li>
              )}
          </ul>
          {props.customCampaignLogo && (
            <div className="default-campaign-logo">
              <CardSubtitle className="campaign-powerd-by">
                POWERED BY
              </CardSubtitle>
              <Link
                to="/"
                className="logo logo-dark"
                style={{ width: "fit-content" }}
              >
                <span className="logo-sm">
                  <img
                    className="default-dark-logo"
                    src={IconSenaraLogo02}
                    alt=""
                    height="22"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    className="default-dark-logo"
                    src={IconSenaraLogo02}
                    alt=""
                    height="17"
                  />
                </span>
              </Link>
              <Link
                to="/"
                className="logo logo-light"
                style={{ width: "fit-content" }}
              >
                <span className="logo-sm">
                  <img
                    className="default-dark-logo"
                    src={IconSenaraLogo02}
                    alt=""
                    height="40"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    className="default-dark-logo"
                    src={IconSenaraLogo02}
                    alt=""
                    height="50"
                  />
                </span>
              </Link>
              <Link to="/" className="logo collpsed-logo">
                <img src={IconSenaraLogo05} alt="" height="40" />
              </Link>
            </div>
          )}
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  customCampaignLogo: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
