import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import VerifySaga from "./auth/verifypwd/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ResetSaga from "./auth/resetpwd/saga"
import ChangeSaga from "./auth/changepwd/saga"
import CampaingSaga from "./campaigns/saga"
import LayoutSaga from "./layout/saga"
import CompanySaga from "./company/saga"
import DashboardSaga from "./dashboard-widgets/saga"
import DataConciergeSaga from "./data-concierge/saga"
import LeadsgenerationSaga from "./leadgeneration/saga"
import StrategySaga from "./strategy/saga"
import LeadCreativeSaga from "./leadcreative/saga"
import ImagesSaga from "./images/saga"
import NotificationsSaga from "./notifications/saga"
import ProfitabilitySaga from "./profitability/saga"
import IntakeSaga from "./intake/saga"
import EmailNurturingSaga from "./prospect-nurturing/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(CampaingSaga),
    fork(CompanySaga),
    fork(DashboardSaga),
    fork(DataConciergeSaga),
    fork(StrategySaga),
    fork(LeadsgenerationSaga),
    fork(LeadCreativeSaga),
    fork(ImagesSaga),
    fork(AuthSaga),
    fork(VerifySaga),
    fork(ForgetSaga),
    fork(ResetSaga),
    fork(ChangeSaga),
    fork(LayoutSaga),
    fork(NotificationsSaga),
    fork(ProfitabilitySaga),
    fork(IntakeSaga),
    fork(EmailNurturingSaga),
  ])
}
