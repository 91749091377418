import React, { createContext, useContext, useState } from "react"
import { eye } from "react-icons-kit/feather/eye"
import { eyeOff } from "react-icons-kit/feather/eyeOff"
import PropTypes from "prop-types"

const ToggleContext = createContext()

const ToggleProvider = ({ children }) => {
  const [type, setType] = useState("password")
  const [iconPassword, setIconPassword] = useState(eyeOff)
  const [confirmtype, setConfirmType] = useState("password")
  const [iconConfirmPassword, setIconConfirmPassword] = useState(eyeOff)
  const [oldpasswordtype, setOldPasswordType] = useState("password")
  const [iconOldassword, setIconOldPassword] = useState(eyeOff)

  const togglePasswordVisibility = () => {
    if (type === "password") {
      setIconPassword(eye)
      setType("text")
    } else {
      setIconPassword(eyeOff)
      setType("password")
    }
  }
  const toggleConfirmPasswordVisibility = () => {
    if (confirmtype === "password") {
      setIconConfirmPassword(eye)
      setConfirmType("text")
    } else {
      setIconConfirmPassword(eyeOff)
      setConfirmType("password")
    }
  }

  const toggleOldPasswordVisibility = () => {
    if (oldpasswordtype === "password") {
      setIconOldPassword(eye)
      setOldPasswordType("text")
    } else {
      setIconOldPassword(eyeOff)
      setOldPasswordType("password")
    }
  }

  const helpText = `Password must be at least 8 characters and
  include at least one uppercase letter, one
  number, and one special character from
  !,@,#,$,%,^,&,*`

  return (
    <ToggleContext.Provider
      value={{
        type,
        iconPassword,
        confirmtype,
        iconConfirmPassword,
        oldpasswordtype,
        iconOldassword,
        toggleOldPasswordVisibility,
        togglePasswordVisibility,
        toggleConfirmPasswordVisibility,
        helpText,
      }}
    >
      {children}
    </ToggleContext.Provider>
  )
}

// Custom Hook
const useToggle = () => useContext(ToggleContext)

ToggleProvider.propTypes = {
  children: PropTypes.any,
}

export { useToggle, ToggleProvider }
