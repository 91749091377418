import {
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL
  } from "./actionTypes"

  const initialState = {
    loading: false,
    all_activites: null,
    error: null
  }

  const activities = (state = initialState, action) => {
    switch (action.type) {
      case GET_ACTIVITIES_SUCCESS:
        state = {
          ...state,
          all_activites: action.payload,
        }
        break
      case GET_ACTIVITIES_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default activities