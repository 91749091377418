import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { GET_ACTIVITIES } from "./actionTypes"
import { 
  getActivitiesSuccess, 
  getActivitiesFail
} from "./actions"
import { getAllActivities
} from "helpers/senara_backend/senarabackend_helper"

function* fetchAllActivities({payload: params}) {
    try {
      //console.log('params===>', params)
      let query = params?.campaign_id
      if(params?.category) query = `${query}&category=${params?.category}`
      //console.log('query===>', query)
      const { result, error } = yield call(getAllActivities,query)
      if(result) yield put(getActivitiesSuccess(result))
      if(error) yield put(getActivitiesFail(error))
    } catch (error) {
      yield put(getActivitiesFail(error))
    }
  }

export function* watchDataConciergeWidgets() {
    yield takeEvery(GET_ACTIVITIES, fetchAllActivities)
  }

  function* DataConciergeSaga() {
    yield all([fork(watchDataConciergeWidgets)])
  }
  
  export default DataConciergeSaga