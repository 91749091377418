import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { GET_COMPANIES, SAVE_COMPANY, DELETE_COMPANY, GET_ALL_COMPANY_ROLE, GET_ALL_LOCATION } from "./actionTypes"
import { saveCompanySuccess, saveCompanyFail, getCompaniesSuccess, getCompaniesFail,deleteCompanySuccess, deleteCompanyFail,
  getCompanyRoleSuccess,
  getCompanyRoleFail,
  getLocationSuccess,
  getLocationFail
} from "./actions"
import { saveCompany, deleteCompany, getAllCompanies,getAllCompanyRole, getAllLocation } from "helpers/senara_backend/senarabackend_helper"

function* addUpdateCompany({ payload: { company } }) {

  try{
      const { result, error } = yield call(saveCompany, company)
      if(result)yield put(saveCompanySuccess(result))
      if(error)yield put(saveCompanyFail(error))
  } catch (error) {
   yield put(saveCompanyFail(error))
  }
}
function* fetchAllCompanies() {
  try {
    const { result, error } = yield call(getAllCompanies)
    if(result) yield put(getCompaniesSuccess(result))
    if(error) yield put(getCompaniesFail(error))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}

function* onDeleteCompany({ payload: company_id }) {
  try {
    const {result, error} = yield call(deleteCompany, company_id)
    if(result)yield put(deleteCompanySuccess(result))
    if(error)yield put(deleteCompanyFail(error))
  } catch (error) {
    yield put(deleteCompanyFail(error))
  }
}

function* fetchAllCompanyRole() {
  try {
    const { result, error } = yield call(getAllCompanyRole)
    if(result) yield put(getCompanyRoleSuccess(result))
    if(error) yield put(getCompanyRoleFail(error))
  } catch (error) {
    yield put(getCompanyRoleFail(error))
  }
}

function* fetchAllLocation() {
  try {
    const { result, error } = yield call(getAllLocation)
    if(result) yield put(getLocationSuccess(result))
    if(error) yield put(getLocationFail(error))
  } catch (error) {
    yield put(getLocationFail(error))
  }
}

export function* watchCompanyRegister() {
  yield takeEvery(SAVE_COMPANY, addUpdateCompany),
  yield takeEvery(GET_COMPANIES, fetchAllCompanies),
  yield takeEvery(DELETE_COMPANY, onDeleteCompany),
  yield takeEvery(GET_ALL_COMPANY_ROLE, fetchAllCompanyRole),
  yield takeEvery(GET_ALL_LOCATION, fetchAllLocation)
}

function* companySaga() {
  yield all([fork(watchCompanyRegister)])
}

export default companySaga
