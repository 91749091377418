import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { GET_LEADS,
  GET_QUALIFIED_LEADS,
  GET_FIRM_SALES,
  GET_SPENDS,
  GET_APPOINTMENTS_HELD,
  GET_REVENUE,
  GET_SALES_PIPELINE_PROGRESSION,
  GET_SALES_PROSPECTS,
  GET_SALES_PROSPECT_IQ
} from "./actionTypes"
import { 
  getLeadsSuccess, 
  getLeadsFail,
  getQualifiedLeadsSuccess,
  getQualifiedLeadsFail,
  getFirmSalesSuccess,
  getFirmSalesFail,
  getSpendsSuccess,
  getSpendsFail,
  getAppointmentsHeldSuccess,
  getAppointmentsHeldFail,
  getRevenueSuccess,
  getRevenueFail,
  getSalesPipelineProgressionSuccess,
  getSalesPipelineProgressionFail,
  getSalesProspectsSuccess,
  getSalesProspectsFail,
  getAllSalesProspectIQDetailsSuccess,
  getAllSalesProspectIQDetailsFail
} from "./actions"
import { getAllLeads,
  getAllQualifiedLeads,
  getAllFirmSales,
  getAllSpends,
  getAllAppointmentsHeld,
  getAllRevenue,
  getAllSalesPipelineProgression, 
  getAllSalesProspects,
  getAllSalesProspectIQDetails
} from "helpers/senara_backend/senarabackend_helper"

function* fetchAllLeads({payload: id}) {
    try {
      const { result, error } = yield call(getAllLeads,id)
      if(result) yield put(getLeadsSuccess(result))
      if(error) yield put(getLeadsFail(error))
    } catch (error) {
      yield put(getLeadsFail(error))
    }
  }

  function* fetchAllQualifiedLeads({payload: id}) {
    try {
      const { result, error } = yield call(getAllQualifiedLeads,id)
      if(result) yield put(getQualifiedLeadsSuccess(result))
      if(error) yield put(getQualifiedLeadsFail(error))
    } catch (error) {
      yield put(getQualifiedLeadsFail(error))
    }
  }

  function* fetchAllFirmSales({payload: id}) {
    try {
      const { result, error } = yield call(getAllFirmSales,id)
      if(result) yield put(getFirmSalesSuccess(result))
      if(error) yield put(getFirmSalesFail(error))
    } catch (error) {
      yield put(getFirmSalesFail(error))
    }
  }
 
  function* fetchAllSpends({payload: id}) {
    try {
      const { result, error } = yield call(getAllSpends,id)
      if(result) yield put(getSpendsSuccess(result))
      if(error) yield put(getSpendsFail(error))
    } catch (error) {
      yield put(getSpendsFail(error))
    }
  }

  function* fetchAllAppointmentsHeld({payload: id}) {
    try {
      const { result, error } = yield call(getAllAppointmentsHeld,id)
      if(result) yield put(getAppointmentsHeldSuccess(result))
      if(error) yield put(getAppointmentsHeldFail(error))
    } catch (error) {
      yield put(getAppointmentsHeldFail(error))
    }
  }
 
  function* fetchAllRevenue({payload: id}) {
    try {
      const { result, error } = yield call(getAllRevenue,id)
      if(result) yield put(getRevenueSuccess(result))
      if(error) yield put(getRevenueFail(error))
    } catch (error) {
      yield put(getRevenueFail(error))
    }
  }

  function* fetchAllSalesPipelineProgression({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      const { result, error } = yield call(getAllSalesPipelineProgression,query)
      if(result) yield put(getSalesPipelineProgressionSuccess(result))
      if(error) yield put(getSalesPipelineProgressionFail(error))
    } catch (error) {
      yield put(getSalesPipelineProgressionFail(error))
    }
  }

  
  function* fetchAllSalesProspects({payload: id}) {
    try {
      const { result, error } = yield call(getAllSalesProspects,id)
      if(result) yield put(getSalesProspectsSuccess(result))
      if(error) yield put(getSalesProspectsFail(error))
    } catch (error) {
      yield put(getSalesProspectsFail(error))
    }
  }
  
  function* fetchAllSalesProspectIQDetails({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.prospect_id) query = `${query}&prospect_id=${params?.prospect_id}`
      const { result, error } = yield call(getAllSalesProspectIQDetails,query)
      if(result) yield put(getAllSalesProspectIQDetailsSuccess(result))
      if(error) yield put(getAllSalesProspectIQDetailsFail(error))
    } catch (error) {
      yield put(getAllSalesProspectIQDetailsFail(error))
    }
  }

  export function* watchDashboardWidgets() {
    yield takeEvery(GET_LEADS, fetchAllLeads),
    yield takeEvery(GET_QUALIFIED_LEADS, fetchAllQualifiedLeads),
    yield takeEvery(GET_FIRM_SALES, fetchAllFirmSales),
    yield takeEvery(GET_SPENDS, fetchAllSpends),
    yield takeEvery(GET_APPOINTMENTS_HELD, fetchAllAppointmentsHeld),
    yield takeEvery(GET_REVENUE, fetchAllRevenue),
    yield takeEvery(GET_SALES_PIPELINE_PROGRESSION, fetchAllSalesPipelineProgression)
    yield takeEvery(GET_SALES_PROSPECTS, fetchAllSalesProspects)
    yield takeEvery(GET_SALES_PROSPECT_IQ, fetchAllSalesProspectIQDetails)
  }

  function* DashboardSaga() {
    yield all([fork(watchDashboardWidgets)])
  }
  
  export default DashboardSaga