export const CAMPAIGN_ID = "CAMPAIGN_ID"

export const CAMPAIGN_TYPES = "CAMPAIGN_TYPES"

/**
 * Get Visits Total
 */
export const GET_LEADS_CREATIVE_VISITS = "GET_LEADS_CREATIVE_VISITS"
export const GET_LEADS_CREATIVE_VISITS_SUCCESS = "GET_LEADS_CREATIVE_VISITS_SUCCESS"
export const GET_LEADS_CREATIVE_VISITS_FAIL = "GET_LEADS_CREATIVE_VISITS_FAIL"

/**
* Get Click Through Rate
*/
export const GET_CLICK_THROUGH_RATE = "GET_CLICK_THROUGH_RATE"
export const GET_CLICK_THROUGH_RATE_SUCCESS = "GET_CLICK_THROUGH_RATE_SUCCESS"
export const GET_CLICK_THROUGH_RATE_FAIL = "GET_CLICK_THROUGH_RATE_FAIL"

/**
* Get Cost Per Click
*/
export const GET_COST_PER_CLICK = "GET_COST_PER_CLICK"
export const GET_COST_PER_CLICK_SUCCESS = "GET_COST_PER_CLICK_SUCCESS"
export const GET_COST_PER_CLICK_FAIL = "GET_COST_PER_CLICK_FAIL"

/**
* Get Concept Chart
*/
export const GET_CONCEPT_CHART = "GET_CONCEPT_CHART"
export const GET_CONCEPT_CHART_SUCCESS = "GET_CONCEPT_CHART_SUCCESS"
export const GET_CONCEPT_CHART_FAIL = "GET_CONCEPT_CHART_FAIL"

/**
* Get Visual Chart
*/
export const GET_VISUAL_CHART = "GET_VISUAL_CHART"
export const GET_VISUAL_CHART_SUCCESS = "GET_VISUAL_CHART_SUCCESS"
export const GET_VISUAL_CHART_FAIL = "GET_VISUAL_CHART_FAIL"

/**
* Get Copy Strategy Chart
*/
export const GET_COPY_STRATEGY_CHART = "GET_COPY_STRATEGY_CHART"
export const GET_COPY_STRATEGY_SUCCESS = "GET_COPY_STRATEGY_SUCCESS"
export const GET_COPY_STRATEGY_FAIL = "GET_COPY_STRATEGY_FAIL"

/**
* Get Tag Line Chart
*/
export const GET_TAG_LINE_CHART = "GET_TAG_LINE_CHART"
export const GET_TAG_LINE_SUCCESS = "GET_TAG_LINE_SUCCESS"
export const GET_TAG_LINE_FAIL = "GET_TAG_LINE_FAIL"

/**
 * Get Top Ads
 */
 export const GET_TOP_ADS = "GET_TOP_ADS"
 export const GET_TOP_ADS_SUCCESS = "GET_TOP_ADS_SUCCESS"
 export const GET_TOP_ADS_FAIL = "GET_TOP_ADS_FAIL"

 /**
 * Get Top Ads Details
 */
  export const GET_TOP_ADS_DETAILS = "GET_TOP_ADS_DETAILS"
  export const GET_TOP_ADS_DETAILS_SUCCESS = "GET_TOP_ADS_DETAILS_SUCCESS"
  export const GET_TOP_ADS_DETAILS_FAIL = "GET_TOP_ADS_DETAILS_FAIL"

/**
 * Get Creative report
 */
export const GET_CREATIVE_REPORT = "GET_CREATIVE_REPORT"
export const GET_CREATIVE_REPORT_SUCCESS = "GET_CREATIVE_REPORT_SUCCESS"
export const GET_CREATIVE_REPORT_FAIL = "GET_CREATIVE_REPORT_FAIL"