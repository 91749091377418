import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { GET_FLIGHTPLANS, GET_HOMEBUYER_PERSONA, GET_TARGETED_COHORT_DETAILS, GET_TARGETED_COHORT_TABS,GET_LANDING_PAGES_DETAILS,
  GET_AD_GALLERY_TABS,
  GET_AD_GALLERY_DETAILS,
  GET_MEDIA_PLAN_URL
} from "./actionTypes"
import { getAllFlightPlans, getAllHomebuyerPersona, getTargetedCohortDetails, getTargetedCohortTabs,
  getLandingPageDetails,
  getAdGalleryTabs,
  getAdGalleryDetails,
  getMediaPlanUrl
} from "helpers/senara_backend/senarabackend_helper"
import {getFlightplansSuccess, getFlightplansFail, getHomeBuyerPersonaSuccess, getHomeBuyerPersonaFail, getTargetedCohortDetailsSuccess, getTargetedCohortDetailsFail, getTargetedCohortTabsSuccess, getTargetedCohortTabsFail,
  getLandingPageDetailsSuccess,
  getLandingPageDetailsFail,
  getAdGalleryTabsSuccess,
  getAdGalleryTabsFail,
  getAdGalleryDetailsSuccess,
  getAdGalleryDetailsFail,
  getMediaPlanUrlSuccess,
  getMediaPlanUrlFail
} from  './actions';

function* fetchAllFightPlans({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      const { result, error } = yield call(getAllFlightPlans,query)
      if(result) yield put(getFlightplansSuccess(result))
      if(error) yield put(getFlightplansFail(error))
    } catch (error) {
      yield put(getFlightplansFail(error))
    }
  }


function* fetchAllHomeBuyerPersona ({payload: id}) {
  try {
    const { result, error } = yield call(getAllHomebuyerPersona,id)
    if(result) yield put(getHomeBuyerPersonaSuccess(result))
    if(error) yield put(getHomeBuyerPersonaFail(error))
  } catch (error) {
    yield put(getHomeBuyerPersonaFail(error))
  }
}

function* fetchTargetedCohortDetailsByPersonaId ({payload: params}) {
  try {
    let query = params?.campaign_id
    if(params?.persona_id) query = `${query}&persona_id=${params?.persona_id}`
    const { result, error } = yield call(getTargetedCohortDetails,query)
    if(result) yield put(getTargetedCohortDetailsSuccess(result))
    if(error) yield put(getTargetedCohortDetailsFail(error))
  } catch (error) {
    yield put(getTargetedCohortDetailsFail(error))
  }
}


function* fetchAllTargetedCohortTabs ({payload: id}) {
try {
  const { result, error } = yield call(getTargetedCohortTabs,id)
  if(result) yield put(getTargetedCohortTabsSuccess(result))
  if(error) yield put(getTargetedCohortTabsFail(error))
} catch (error) {
  yield put(getTargetedCohortTabsFail(error))
}
}

function* fetchAllLandingPages({payload: id}) {
  try {
    const { result, error } = yield call(getLandingPageDetails,id)
    if(result) yield put(getLandingPageDetailsSuccess(result))
    if(error) yield put(getLandingPageDetailsFail(error))
  } catch (error) {
    yield put(getLandingPageDetailsFail(error))
  }
}

function* fetchAllAdGalleryTabs ({payload: id}) {
  try {
    const { result, error } = yield call(getAdGalleryTabs,id)
    if(result) yield put(getAdGalleryTabsSuccess(result))
    if(error) yield put(getAdGalleryTabsFail(error))
  } catch (error) {
    yield put(getAdGalleryTabsFail(error))
  }
  }

  function* fetchAdGalleryDetailsByConcept ({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.concept) query = `${query}&concept=${params?.concept}`
      //console.log("query",query)
      const { result, error } = yield call(getAdGalleryDetails,query)
      if(result) yield put(getAdGalleryDetailsSuccess(result))
      if(error) yield put(getAdGalleryDetailsFail(error))
    } catch (error) {
      yield put(getAdGalleryDetailsFail(error))
    }
  }
 
  function* fetchMediaPlanUrl ({payload: id}) {
    try {
      const { result, error } = yield call(getMediaPlanUrl,id)
      if(result) yield put(getMediaPlanUrlSuccess(result))
      if(error) yield put(getMediaPlanUrlFail(error))
    } catch (error) {
      yield put(getMediaPlanUrlFail(error))
    }
  }

export function* watchStrategyWidgets() {
    yield takeEvery(GET_FLIGHTPLANS, fetchAllFightPlans),
    yield takeEvery(GET_HOMEBUYER_PERSONA, fetchAllHomeBuyerPersona)
    yield takeEvery(GET_TARGETED_COHORT_TABS, fetchAllTargetedCohortTabs),
    yield takeEvery(GET_TARGETED_COHORT_DETAILS, fetchTargetedCohortDetailsByPersonaId),
    yield takeEvery(GET_LANDING_PAGES_DETAILS, fetchAllLandingPages),
    yield takeEvery(GET_AD_GALLERY_TABS, fetchAllAdGalleryTabs),
    yield takeEvery(GET_AD_GALLERY_DETAILS, fetchAdGalleryDetailsByConcept)
    yield takeEvery(GET_MEDIA_PLAN_URL, fetchMediaPlanUrl)
  }

  function* StrategySaga() {
    yield all([fork(watchStrategyWidgets)])
  }
  
  export default StrategySaga