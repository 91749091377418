import {
    GET_ACTIVITIES,
    GET_ACTIVITIES_SUCCESS,
    GET_ACTIVITIES_FAIL
  } from "./actionTypes"

  export const getActivities = params => ({
    type: GET_ACTIVITIES,
    payload: params
  })
  
  export const getActivitiesSuccess = data => ({
    type: GET_ACTIVITIES_SUCCESS,
    payload: data,
  })
  
  export const getActivitiesFail = error => ({
    type: GET_ACTIVITIES_FAIL,
    payload: error,
  })