import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getNotifications } from "store/actions"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  const [loadingState, setLoadingState] = useState("LOADING")

  const { all_campaign } = useSelector(state => ({
    all_campaign: state.Campaing.all_campaign,
  }));
  const { notifications } = useSelector(state => ({
    notifications: state.Notifications.notifications,
  }))
  const { campaignId } = useSelector(state => ({
    campaignId: state.Dashboard.campaignId,
  }))
  const getData = (id) => {
    setLoadingState("LOADING")
    dispatch(getNotifications(id))
  }

  useEffect(() => {
    if (campaignId != null && campaignId !== "NO_CAMPAIGNS_ASSIGNED") {
      getData(campaignId)
    }
    if (campaignId === "NO_CAMPAIGNS_ASSIGNED") {
      setLoadingState("NO_CAMPAIGNS_ASSIGNED")
    }
  }, [campaignId])
  useEffect(() => {
    if (notifications) {
      //console.log("notifications===>", notifications)
      setLoadingState("DATA_RECEIVED")
    }
  }, [notifications])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-caret-right-square" />
          {/* <span className="badge bg-danger rounded-pill">3</span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="/data-concierge/key-activities" className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>
          
          <SimpleBar style={{ height: "350px" }}>
            {loadingState === "LOADING" ? (
              <div className="creative-report-table">
                <Spinner
                  className="ms-2 align-items-md-center"
                  color="primary"
                />
              </div>
            ) : loadingState === "NO_CAMPAIGNS_ASSIGNED" ? (
              <Col lg="12" className="mt-5 text-align-center">
                <h4>No Campaign(s) assigned yet!</h4>
              </Col>
            ) : loadingState === "DATA_RECEIVED" &&
              notifications.data.length > 0 ? (
              notifications.data.map((notification, key) => {
                return (
                  <Link
                    key={key}
                    to="#"
                    className="text-reset notification-item"
                  >
                    <div className="media key-activities-icons">
                        <i
                          className={
                            notification.class_name + " h3 custom-notification-icons"
                          }
                        />  
                      {/* <div className="avatar-xs me-3">
                      <span className="avatar-title custom-bg-success rounded-circle font-size-16">
                         <img src={notification.icon} alt={notification.title} height='20px'/>    
                      </span>
                     </div> */}
                      <div className="media-body">
                        <h6 className="mt-0 mb-1 font-size-12">{notification.campaign}</h6>
                        <h6 className="mt-0 mb-1 font-size-12">{notification.title}</h6>
                        <div className="font-size-11 text-muted">
                          <p className="mb-1">{notification.description}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-calendar" />{" "}
                            {notification.date}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link> 
                )
              })
            ) : (
              <Col lg="12" className="mt-5 text-align-center">
                <h4>No Notifications yet!</h4>
              </Col>
            )}
          </SimpleBar>
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
