import { type } from "@amcharts/amcharts5"
import {
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGES_FAIL,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAIL,
  UPLOAD_FILES_TYPE,
  UPLOAD_FILES_TYPE_REMOVE
} from "./actionTypes"

const initialState = {
  loading: false,
  data: null,
  uploadImagesSuccess: null,
  uploadImagesFailed: null,
  uploadFilesSuccess : null,
  uploadFilesFailed: null,
  uploadLoadingFiles: {}
}

const images = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGES:
      state = {
        ...state,
        loading: true,
        uploadImagesFailed: null,
      }
      break
    case UPLOAD_IMAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        uploadImagesSuccess: action.payload,
        uploadImagesFailed: null,
      }
      break
    case UPLOAD_IMAGES_FAIL:
      state = {
        ...state,
        data: null,
        loading: false,
        uploadImagesFailed: action.payload,
      }
      break
      case UPLOAD_FILES:
        state = {
          ...state,
          loading: true,
          uploadFilesFailed: null,
        }
      break
      case UPLOAD_FILES_SUCCESS:
        state = {
          ...state,
          loading: false,
          uploadFilesSuccess: action.payload,
          uploadFilesFailed: null,
        }
        break
      case UPLOAD_FILES_FAIL:
        state = {
          ...state,
          data: null,
          loading: false,
          uploadFilesFailed: action.payload,
        }
        break
      case UPLOAD_FILES_TYPE:
        state = {
          ...state,
          loading: true,
          uploadLoadingFiles: {...state.uploadLoadingFiles,[action.payload]:true},
        }
      break
      case UPLOAD_FILES_TYPE_REMOVE:
        const newUploadingFiles = {...state.uploadLoadingFiles}
        if(newUploadingFiles[action.payload])
        {
          delete newUploadingFiles[action.payload]
        }
        state = {
          ...state,
          loading: true,
          uploadLoadingFiles: newUploadingFiles,
        }
      break
    default:
      state = { ...state }
      break
  }
  return state
}
export default images
