import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes"
import { changePwdSuccess, changePwdError } from "./actions"
import { postChangePassword } from "helpers/senara_backend/senarabackend_helper"

// senara reset password
function* changePwd({ payload: { user, history } }) {
  try {
    const { result,error } = yield call(postChangePassword, {
      old_password: user.old_password,
      password: user.password,
      confirm_password: user.confirm_password
    })
    if(result) {
      yield put(changePwdSuccess(result))
    }
    if(error) {
      yield put(changePwdError(error))
    }
  } catch (error) {
    yield put(changePwdError(error))
  }
}

export function* watchUserPasswordChange() {
  yield takeEvery(CHANGE_PASSWORD, changePwd)
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordChange)])
}

export default changePasswordSaga
