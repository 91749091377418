import {
    GET_LEADS_GENERATION,
    GET_LEADS_GENERATION_SUCCESS,
    GET__LEADS_GENERATION_FAIL,
    GET_COST_PER_LEAD,
    GET_COST_PER_LEAD_SUCCESS,
    GET_COST_PER_LEAD_FAIL,
    GET_LEADS_GENERATION_SPENDS,
    GET_LEADS_GENERATION_SPENDS_SUCCESS,
    GET_LEADS_GENERATION_SPENDS_FAIL,
    GET_CHANNEL_CHART,
    GET_CHANNEL_CHART_SUCCESS,
    GET_CHANNEL_CHART_FAIL,
    GET_PERSONA_CHART,
    GET_PERSONA_CHART_SUCCESS,
    GET_PERSONA_CHART_FAIL,
    GET_COHORT_CHART,
    GET_COHORT_CHART_SUCCESS,
    GET_COHORT_CHART_FAIL,
    GET_LEADS_GENERATION_REPORT,
    GET_LEADS_GENERATION_REPORT_SUCCESS,
    GET_LEADS_GENERATION_REPORT_FAIL,
    GET_LEADS_COST_PERFORMANCE,
    GET_LEADS_COST_PERFORMANCE_SUCCESS,
    GET_LEADS_COST_PERFORMANCE_FAIL,
    GET_LOCATION_REPORT,
    GET_LOCATION_REPORT_SUCCESS,
    GET_LOCATION_REPORT_FAIL,
    GET_LOCATION_MAP,
    GET_LOCATION_MAP_SUCCESS,
    GET_LOCATION_MAP_FAIL,
    GET_ALL_MAP_CHANNEL,
    GET_ALL_MAP_CHANNEL_SUCCESS,
    GET_ALL_MAP_CHANNEL_FAIL,
    GET_ALL_MAP_PERSONA,
    GET_ALL_MAP_PERSONA_SUCCESS,
    GET_ALL_MAP_PERSONA_FAIL,
    GET_ALL_MAP_COHORT,
    GET_ALL_MAP_COHORT_SUCCESS,
    GET_ALL_MAP_COHORT_FAIL
} from "./actionTypes"

export const getLeadsGeneration = id => ({
    type: GET_LEADS_GENERATION,
    payload: id
})

export const getLeadsGenerationSuccess = data => ({
    type: GET_LEADS_GENERATION_SUCCESS,
    payload: data,
})

export const getLeadsGenerationFail = error => ({
    type: GET__LEADS_GENERATION_FAIL,
    payload: error,
})

export const getAllCostPerLeads = id => ({
    type: GET_COST_PER_LEAD,
    payload: id
})

export const getCostPerLeadSuccess = data => ({
    type: GET_COST_PER_LEAD_SUCCESS,
    payload: data,
})

export const getCostPerLeadFail = error => ({
    type: GET_COST_PER_LEAD_FAIL,
    payload: error,
})

export const getAllLeadsgenerationSpend = id => ({
    type: GET_LEADS_GENERATION_SPENDS,
    payload: id
})

export const getLeadsgenerationSpendSuccess = data => ({
    type: GET_LEADS_GENERATION_SPENDS_SUCCESS,
    payload: data,
})

export const getLeadsgenerationSpendFail = error => ({
    type: GET_LEADS_GENERATION_SPENDS_FAIL,
    payload: error,
})

export const getAllChannelChart = params => ({
    type: GET_CHANNEL_CHART,
    payload: params
})

export const getChannelChartSuccess = data => ({
    type: GET_CHANNEL_CHART_SUCCESS,
    payload: data,
})

export const getChannelChartFail = error => ({
    type: GET_CHANNEL_CHART_FAIL,
    payload: error,
})

export const getAllPersonaChart = params => ({
    type: GET_PERSONA_CHART,
    payload: params
})

export const getPersonaChartSuccess = data => ({
    type: GET_PERSONA_CHART_SUCCESS,
    payload: data,
})

export const getPersonaChartFail = error => ({
    type: GET_PERSONA_CHART_FAIL,
    payload: error,
})

export const getAllCohortChart = params => ({
    type: GET_COHORT_CHART,
    payload: params
})

export const getCohortChartSuccess = data => ({
    type: GET_COHORT_CHART_SUCCESS,
    payload: data,
})

export const getCohortChartFail = error => ({
    type: GET_COHORT_CHART_FAIL,
    payload: error,
})

export const getAllLeadsgenerationReport = params => ({
    type: GET_LEADS_GENERATION_REPORT,
    payload: params
})

export const getLeadsgenerationReportSuccess = data => ({
    type: GET_LEADS_GENERATION_REPORT_SUCCESS,
    payload: data,
})

export const getLeadsgenerationReportFail = error => ({
    type: GET_LEADS_GENERATION_REPORT_FAIL,
    payload: error,
})

export const getAllLeadCostPerformance = id => ({
    type: GET_LEADS_COST_PERFORMANCE,
    payload: id
})

export const getLeadCostPerformanceSuccess = data => ({
    type: GET_LEADS_COST_PERFORMANCE_SUCCESS,
    payload: data,
})

export const getLeadCostPerformanceFail = error => ({
    type: GET_LEADS_COST_PERFORMANCE_FAIL,
    payload: error,
})

export const getLocationReport = id => ({
    type: GET_LOCATION_REPORT,
    payload: id
})

export const getLocationReportSuccess = data => ({
    type: GET_LOCATION_REPORT_SUCCESS,
    payload: data,
})

export const getLocationReportFail = error => ({
    type: GET_LOCATION_REPORT_FAIL,
    payload: error,
})
export const getLocationMap = params => ({
    type: GET_LOCATION_MAP,
    payload: params
})

export const getLocationMapSuccess = data => ({
    type: GET_LOCATION_MAP_SUCCESS,
    payload: data,
})

export const getLocationMapFail = error => ({
    type: GET_LOCATION_MAP_FAIL,
    payload: error,
})

export const getAllMapChannel = id => ({
    type: GET_ALL_MAP_CHANNEL,
    payload: id
})
  
export const getAllMapChannelSuccess = data => ({
    type: GET_ALL_MAP_CHANNEL_SUCCESS,
    payload: data,
})
  
export const getAllMapChannelFail = error => ({
    type: GET_ALL_MAP_CHANNEL_FAIL,
    payload: error,
})

export const getAllMapPersona = id => ({
    type: GET_ALL_MAP_PERSONA,
    payload: id
})
  
export const getAllMapPersonaSuccess = data => ({
    type: GET_ALL_MAP_PERSONA_SUCCESS,
    payload: data,
})
  
export const getAllMapPersonaFail = error => ({
    type: GET_ALL_MAP_PERSONA_FAIL,
    payload: error,
})

export const getAllMapCohort = id => ({
    type: GET_ALL_MAP_COHORT,
    payload: id
})
  
export const getAllMapCohortSuccess = data => ({
    type: GET_ALL_MAP_COHORT_SUCCESS,
    payload: data,
})
  
export const getAllMapCohortFail = error => ({
    type: GET_ALL_MAP_COHORT_FAIL,
    payload: error,
})