import {
  CAMPAIGN_ID,
  GET_LEADS_CREATIVE_VISITS_SUCCESS,
  GET_LEADS_CREATIVE_VISITS_FAIL,
  GET_CLICK_THROUGH_RATE_SUCCESS,
  GET_CLICK_THROUGH_RATE_FAIL,
  GET_COST_PER_CLICK_SUCCESS,
  GET_COST_PER_CLICK_FAIL,
  GET_CONCEPT_CHART_SUCCESS,
  GET_CONCEPT_CHART_FAIL,
  GET_VISUAL_CHART_SUCCESS,
  GET_VISUAL_CHART_FAIL,
  GET_COPY_STRATEGY_SUCCESS,
  GET_COPY_STRATEGY_FAIL,
  GET_TAG_LINE_SUCCESS,
  GET_TAG_LINE_FAIL,
  GET_TOP_ADS_SUCCESS,
  GET_TOP_ADS_FAIL,
  GET_TOP_ADS_DETAILS_SUCCESS,
  GET_TOP_ADS_DETAILS_FAIL,
  GET_CREATIVE_REPORT_SUCCESS,
  GET_CREATIVE_REPORT_FAIL
} from "./actionTypes"

const initialState = {
  campaignId: null,
  loading: false,
  all_leads_creative_visits: null,
  all_click_through_rate: null,
  all_cost_per_click: null,
  all_concept_chart: null,
  all_visual_chart: null,
  all_copy_strategy_chart: null,
  all_tag_line_chart: null,
  all_top_ads: null,
  all_top_ads_details: null,
  creative_report: null
}

const leadcreative = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_ID:
      state = {
        ...state,
        campaignId: action.payload
      }
      break
    case GET_LEADS_CREATIVE_VISITS_SUCCESS:
      state = {
        ...state,
        all_leads_creative_visits: action.payload,
      }
      break
    case GET_LEADS_CREATIVE_VISITS_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_CLICK_THROUGH_RATE_SUCCESS:
      state = {
        ...state,
        all_click_through_rate: action.payload,
      }
      break
    case GET_CLICK_THROUGH_RATE_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_COST_PER_CLICK_SUCCESS:
      state = {
        ...state,
        all_cost_per_click: action.payload,
      }
      break
    case GET_COST_PER_CLICK_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_CONCEPT_CHART_SUCCESS:
      state = {
        ...state,
        all_concept_chart: action.payload,
      }
      break
    case GET_CONCEPT_CHART_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_VISUAL_CHART_SUCCESS:
      state = {
        ...state,
        all_visual_chart: action.payload,
      }
      break
    case GET_VISUAL_CHART_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_COPY_STRATEGY_SUCCESS:
      state = {
        ...state,
        all_copy_strategy_chart: action.payload,
      }
      break
    case GET_COPY_STRATEGY_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_TAG_LINE_SUCCESS:
      state = {
        ...state,
        all_tag_line_chart: action.payload,
      }
      break
    case GET_TAG_LINE_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
      case GET_TOP_ADS_SUCCESS:
      state = {
        ...state,
        all_top_ads: action.payload,
      }
      break
    case GET_TOP_ADS_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
      case GET_TOP_ADS_DETAILS_SUCCESS:
        state = {
          ...state,
          all_top_ads_details: action.payload,
        }
        break
      case GET_TOP_ADS_DETAILS_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
      case GET_CREATIVE_REPORT_SUCCESS:
          state = {
            ...state,
            creative_report: action.payload,
          }
          break
      case GET_CREATIVE_REPORT_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
    default:
      state = { ...state }
      break
  }
  return state
}

export default leadcreative