import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"

import { IconSenaraLogo02, IconSenaraLogo05 } from "assets/images/senaraIndex"
import { getCampaignById } from "store/campaigns/actions"

const Sidebar = props => {
  const dispatch = useDispatch()
  const [customCampaignLogo, setCustomCampaignLogo] = useState()

  const { campaignId } = useSelector(state => ({
    campaignId: state.Dashboard.campaignId,
  }))

  const { current_campaign_details } = useSelector(state => ({
    current_campaign_details: state.Campaing.all_active_campaign_details_by_id,
  }))

  useEffect(() => {
    const logoStyle = current_campaign_details?.data?.logo_style || "senara"
    const logo =
      logoStyle === "senara" ? null : current_campaign_details?.data?.logo_path
    setCustomCampaignLogo(logo)
  }, [campaignId, current_campaign_details?.data])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={customCampaignLogo || IconSenaraLogo05}
                alt=""
                height="22"
              />
            </span>
            <span className="logo-lg">
              <img
                src={customCampaignLogo || IconSenaraLogo02}
                alt=""
                height="17"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={customCampaignLogo || IconSenaraLogo05}
                alt=""
                height="40"
                className="collpsed-logo-sm"
                style={{ marginLeft: "-8px" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src={customCampaignLogo || IconSenaraLogo02}
                alt=""
                height="50"
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent customCampaignLogo={customCampaignLogo} />
          ) : (
            <SidebarContent customCampaignLogo={customCampaignLogo} />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
