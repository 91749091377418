import {
  CAMPAIGN_ID,
  GET_END_USER_SALES_SUCCESS,
  GET_END_USER_SALES_FAIL,
  GET_UNREPRESENTED_RATIO_SUCCESS,
  GET_UNREPRESENTED_RATIO_FAIL,
  GET_COMMISSIONS_SAVED_SUCCESS,
  GET_COMMISSIONS_SAVED_FAIL,
  GET_REMAINING_INVENTORY_CHART_SUCCESS,
  GET_REMAINING_INVENTORY_CHART_FAIL,
  GET_DEMAND_RATIO_CHART_SUCCESS,
  GET_DEMAND_RATIO_CHART_FAIL,
  GET_REMAINING_INVENTORY_REPORT_SUCCESS,
  GET_REMAINING_INVENTORY_REPORT_FAIL,
  GET_DEMAND_RATIO_REPORT_SUCCESS,
  GET_DEMAND_RATIO_REPORT_FAIL
} from "./actionTypes.js"

const initialState = {
  campaignId: null,
  loading: false,
  all_end_user_sales: null,
  all_unrepresented_ratio: null,
  all_commissions_saved: null,
  all_remaining_inventory_chart: null,
  all_demand_ratio_chart: null,
  all_remaining_inventory_report: null,
  all_demand_ratio_report: null,
}

const profitability = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_ID:
      state = {
        ...state,
        campaignId: action.payload,
      }
      break
    case GET_END_USER_SALES_SUCCESS:
      state = {
        ...state,
        all_end_user_sales: action.payload,
      }
      break
    case GET_END_USER_SALES_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_UNREPRESENTED_RATIO_SUCCESS:
      state = {
        ...state,
        all_unrepresented_ratio: action.payload,
      }
      break
    case GET_UNREPRESENTED_RATIO_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_COMMISSIONS_SAVED_SUCCESS:
      state = {
        ...state,
        all_commissions_saved: action.payload,
      }
      break
    case GET_COMMISSIONS_SAVED_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_REMAINING_INVENTORY_CHART_SUCCESS:
      state = {
        ...state,
        all_remaining_inventory_chart: action.payload,
      }
      break
    case GET_REMAINING_INVENTORY_CHART_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_DEMAND_RATIO_CHART_SUCCESS:
      state = {
        ...state,
        all_demand_ratio_chart: action.payload,
      }
      break
    case GET_DEMAND_RATIO_CHART_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_REMAINING_INVENTORY_REPORT_SUCCESS:
        state = {
          ...state,
          all_remaining_inventory_report: action.payload,
        }
        break
    case GET_REMAINING_INVENTORY_REPORT_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
        break
    case GET_DEMAND_RATIO_REPORT_SUCCESS:
        state = {
          ...state,
          all_demand_ratio_report: action.payload,
        }
        break
    case GET_DEMAND_RATIO_REPORT_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profitability
