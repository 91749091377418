import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes"
import { resetPwdSuccess, resetPwdError } from "./actions"
import { postResetPassword } from "helpers/senara_backend/senarabackend_helper"

// senara reset password
function* resetPwd({ payload: { user, history } }) {
  try {
    const { result, error } = yield call(postResetPassword, {
      password: user.password,
      confirm_password: user.confipassword,
      reset_token: user.resetToken,
    })
    if (result) {
      yield put(resetPwdSuccess(result))
    }
    if (error) {
      yield put(resetPwdError(error))
    }
  } catch (error) {
    yield put(resetPwdError(error))
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPwd)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga
