/**
 * Get Leads Total
 */
 export const GET_LEADS_GENERATION = "GET_LEADS_GENERATION"
 export const GET_LEADS_GENERATION_SUCCESS = "GET_LEADS_GENERATION_SUCCESS"
 export const GET__LEADS_GENERATION_FAIL = "GET__LEADS_GENERATION_FAIL"

 /**
 * Get Cost Per Lead
 */
  export const GET_COST_PER_LEAD = "GET_COST_PER_LEAD"
  export const GET_COST_PER_LEAD_SUCCESS = "GET_COST_PER_LEAD_SUCCESS"
  export const GET_COST_PER_LEAD_FAIL = "GET_COST_PER_LEAD_FAIL"

   /**
 * Get Leads Generation Spend
 */
    export const GET_LEADS_GENERATION_SPENDS = "GET_LEADS_GENERATION_SPENDS"
    export const GET_LEADS_GENERATION_SPENDS_SUCCESS = "GET_LEADS_GENERATION_SPENDS_SUCCESS"
    export const GET_LEADS_GENERATION_SPENDS_FAIL = "GET_LEADS_GENERATION_SPENDS_FAIL"

 /**
 * Get Channel Chart
 */
  export const GET_CHANNEL_CHART = "GET_CHANNEL_CHART"
  export const GET_CHANNEL_CHART_SUCCESS = "GET_CHANNEL_CHART_SUCCESS"
  export const GET_CHANNEL_CHART_FAIL = "GET_CHANNEL_CHART_FAIL"

/**
 * Get Persona Chart
 */
    export const GET_PERSONA_CHART = "GET_PERSONA_CHART"
    export const GET_PERSONA_CHART_SUCCESS = "GET_PERSONA_CHART_SUCCESS"
    export const GET_PERSONA_CHART_FAIL = "GET_PERSONA_CHART_FAIL"

/**
 * Get Cohort Chart
 */
 export const GET_COHORT_CHART = "GET_COHORT_CHART"
 export const GET_COHORT_CHART_SUCCESS = "GET_COHORT_CHART_SUCCESS"
 export const GET_COHORT_CHART_FAIL = "GET_COHORT_CHART_FAIL"

/**
 * Lead Generation Report
 */
export const GET_LEADS_GENERATION_REPORT = "GET_LEADS_GENERATION_REPORT"
export const GET_LEADS_GENERATION_REPORT_SUCCESS = "GET_LEADS_GENERATION_REPORT_SUCCESS"
export const GET_LEADS_GENERATION_REPORT_FAIL = "GET_LEADS_GENERATION_REPORT_FAIL"

/**
 * Lead Cost Performance
 */
 export const GET_LEADS_COST_PERFORMANCE = "GET_LEADS_COST_PERFORMANCE"
 export const GET_LEADS_COST_PERFORMANCE_SUCCESS = "GET_LEADS_COST_PERFORMANCE_SUCCESS"
 export const GET_LEADS_COST_PERFORMANCE_FAIL = "GET_LEADS_COST_PERFORMANCE_FAIL"

 /**
 * Location Report
 */
export const GET_LOCATION_REPORT = "GET_LOCATION_REPORT"
export const GET_LOCATION_REPORT_SUCCESS = "GET_LOCATION_REPORT_SUCCESS"
export const GET_LOCATION_REPORT_FAIL = "GET_LOCATION_REPORT_FAIL"

 /**
 * Location Map
 */
export const GET_LOCATION_MAP = "GET_LOCATION_MAP"
export const GET_LOCATION_MAP_SUCCESS = "GET_LOCATION_MAP_SUCCESS"
export const GET_LOCATION_MAP_FAIL = "GET_LOCATION_MAP_FAIL"
 
 /**
 * Get Map Channel
 */
export const GET_ALL_MAP_CHANNEL = "GET_ALL_MAP_CHANNEL"
export const GET_ALL_MAP_CHANNEL_SUCCESS = "GET_ALL_MAP_CHANNEL_SUCCESS"
export const GET_ALL_MAP_CHANNEL_FAIL = "GET_ALL_MAP_CHANNEL_FAIL"

 /**
 * Get Map Persona
 */
export const GET_ALL_MAP_PERSONA = "GET_ALL_MAP_PERSONA"
export const GET_ALL_MAP_PERSONA_SUCCESS = "GET_ALL_MAP_PERSONA_SUCCESS"
export const GET_ALL_MAP_PERSONA_FAIL = "GET_ALL_MAP_PERSONA_FAIL"

 /**
 * Get Map Cohort
 */
export const GET_ALL_MAP_COHORT = "GET_ALL_MAP_COHORT"
export const GET_ALL_MAP_COHORT_SUCCESS = "GET_ALL_MAP_COHORT_SUCCESS"
export const GET_ALL_MAP_COHORT_FAIL = "GET_ALL_MAP_COHORT_FAIL"
    
  