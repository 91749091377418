import {
  CAMPAIGN_CREATE,
  CAMPAIGN_CREATE_SUCCESSFUL,
  CAMPAIGN_CREATE_FAILED,
  GET_ALL_CAMPAIGN_SUCCESS,
  GET_ALL_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_ALL_FLIGHT_PHASE_SUCCESS,
  GET_ALL_FLIGHT_PHASE_FAIL,
  UPLOAD_COVER_PHOTO,
  UPLOAD_COVER_PHOTO_SUCCESS,
  UUPLOAD_COVER_PHOTO_FAIL,
  GET_ALL_VIEW_PERMISSION_SUCCESS,
  GET_ALL_VIEW_PERMISSION_FAIL,
  SAVE_VIEW_PERMISSIONE,
  SAVE_VIEW_PERMISSIONE_SUCCESSFUL,
  SAVE_VIEW_PERMISSIONE_FAIL,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_BY_ID_SUCCESS,
  GET_CAMPAIGN_BY_ID_FAIL,
  GET_ALL_COUNTRIES_SUCCESS,
  GET_ALL_COUNTRIES_FAIL,
  GET_CAMPAIGN_PERSONALIZE,
  GET_CAMPAIGN_PERSONALIZE_SUCCESS,
  GET_CAMPAIGN_PERSONALIZE_FAIL,
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_SUGGESTIONS_SUCCESS,
} from "./actionTypes"

const initialState = {
  creationError: null,
  message: null,
  loading: false,
  campaignloading: false,
  data: null,
  all_campaign: null,
  all_campaign_details_by_id: null,
  all_active_campaign_details_by_id: null,
  campaingCreateSuccessful: null,
  campaingCreateFailed: null,
  updateCampaignSuccess: null,
  updateCampaignFail: null,
  deleteCampaignSuccess: null,
  deleteCampaignrFail: null,
  all_flight_phase: null,
  uploadCoverPhotoSuccess: null,
  uploadCoverPhotoFail: null,
  all_view_permission: null,
  all_view_user_permission: null,
  saveViewPermissionSuccess: null,
  saveViewPermissionError: null,
  all_countries: null,
  personalizedData: {},
  suggetions: {},
}

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_CREATE:
      state = {
        ...state,
        loading: true,
        campaingCreateFailed: null,
      }
      break
    case CAMPAIGN_CREATE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        campaingCreateSuccessful: action.payload,
        campaingCreateFailed: null,
      }
      break
    case CAMPAIGN_CREATE_FAILED:
      state = {
        ...state,
        data: null,
        loading: false,
        campaingCreateFailed: action.payload,
      }
      break
    case GET_ALL_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        all_campaign: action.payload,
      }
      break
    case GET_ALL_CAMPAIGN_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
    case GET_CAMPAIGN_BY_ID:
      state = {
        ...state,
        campaignloading: action.payload?.isActiveCam,
      }
      break
    case GET_CAMPAIGN_BY_ID_SUCCESS:
        state = !action.payload?.isActiveCam ? {
          ...state,
          all_campaign_details_by_id: action.payload.result,
        } :
        {
          ...state,
          campaignloading: false,
          all_active_campaign_details_by_id: action.payload.result,
        }
        break
    case GET_CAMPAIGN_BY_ID_FAIL:
        state = {
          ...state,
          campaignloading: false,
          error: action.payload,
        }
      break
      case UPDATE_CAMPAIGN_SUCCESS:
        state = {
          ...state,
          updateCampaignSuccess: action.payload,
          updateCampaignFail: null
        }
        break
      case UPDATE_CAMPAIGN_FAIL:
        state = {
          ...state,
          updateCampaignSuccess: null,
          updateCampaignFail: action.payload,
        }
        break
    case DELETE_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        deleteCampaignSuccess: action.payload,
        deleteCampaignrFail: null
      }
      break
    case DELETE_CAMPAIGN_FAIL:
      state = {
        ...state,
        deleteCampaignSuccess: null,
        deleteCampaignrFail: action.payload,
      }
      break
    case GET_ALL_FLIGHT_PHASE_SUCCESS:
      state = {
        ...state,
        all_flight_phase: action.payload,
      }
      break
    case GET_ALL_FLIGHT_PHASE_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
      case UPLOAD_COVER_PHOTO:
      state = {
        ...state,
        loading: true,
        uploadCoverPhotoFail: null,
      }
      break
    case UPLOAD_COVER_PHOTO_SUCCESS:
      state = {
        ...state,
        loading: false,
        uploadCoverPhotoSuccess: action.payload,
        uploadCoverPhotoFail: null,
      }
      break
    case UUPLOAD_COVER_PHOTO_FAIL:
      state = {
        ...state,
        data: null,
        loading: false,
        uploadCoverPhotoFail: action.payload,
      }
      break
    case SAVE_VIEW_PERMISSIONE:
      state = {
        ...state,
        loading: true,
        saveViewPermissionError: null,
      }
      break
    case SAVE_VIEW_PERMISSIONE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        saveViewPermissionSuccess: action.payload,
        saveViewPermissionError: null,
      }
      break
    case SAVE_VIEW_PERMISSIONE_FAIL:
      state = {
        ...state,
        data: null,
        loading: false,
        saveViewPermissionError: action.payload,
      }
      break
    case GET_ALL_VIEW_PERMISSION_SUCCESS:
      state = !action.payload?.isUser ? {
        ...state,
        all_view_permission: action.payload.result,
      } :
      {
        ...state,
        all_view_user_permission: action.payload.result,
      }
      break
    case GET_ALL_VIEW_PERMISSION_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
      case GET_ALL_COUNTRIES_SUCCESS:
        state = {
          ...state,
          all_countries: action.payload,
        }
        break
      case GET_ALL_COUNTRIES_FAIL:
        state = {
          ...state,
          error: action.payload,
        } 
      break
      case GET_CAMPAIGN_PERSONALIZE:
        state = {
          ...state,
          campaignloading: action.payload?.isActiveCam,
        }
      break
      case GET_CAMPAIGN_PERSONALIZE_SUCCESS:
        state = !action.payload?.isActiveCam ? {
          ...state,
          personalizedData: action.payload.result,
        } :
        {
          ...state,
          campaignloading: false,
          all_active_campaign_details_by_id: action.payload.result,
        }
      break
      case GET_CAMPAIGN_PERSONALIZE_FAIL:
        state = {
          ...state,
          campaignloading: false,
          error: action.payload,
        }
      break 
      case GET_ADDRESS_SUGGESTIONS:
        state = {
          ...state,
          error: action.payload,
        }
      break  
      case GET_ADDRESS_SUGGESTIONS_SUCCESS:
        state = {
          ...state,
          suggetions: action.payload,
        }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default campaign