import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
    GET_LEADS_CREATIVE_VISITS,
    GET_CLICK_THROUGH_RATE,
    GET_COST_PER_CLICK,
    GET_CONCEPT_CHART,
    GET_VISUAL_CHART,
    GET_COPY_STRATEGY_CHART,
    GET_TAG_LINE_CHART,
    GET_TOP_ADS,
    GET_TOP_ADS_DETAILS,
    GET_CREATIVE_REPORT
} from "./actionTypes"

import {
    getLeadCreationVisitsSuccess,
    getLeadCreationVisitsFail,
    getClickThroughRateSuccess,
    getClickThroughRateFail,
    getCostPerClickSuccess,
    getCostPerClickFail,
    getConceptChartSuccess,
    getConceptChartFail,
    getVisualChartSuccess,
    getVisualChartFail,
    getCopyStrategyChartSuccess,
    getCopyStrategyChartFail,
    getTagLineChartSuccess,
    getTagLineChartFail,
    getTopAdsSuccess,
    getTopAdsFail,
    getTopAdsDetailsSuccess,
    getTopAdsDetailsFail,
    getCreativeReportSuccess,
    getCreativeReportFail
} from "./actions"

import {
    getAllLeadCreativeVisits,
    getAllClickThroughRate,
    getAllCostPerClick,
    getAllConceptChart,
    getAllVisualChart,
    getAllCopyStrategyChart,
    getAllTagLineChart,
    getAllTopAds,
    getAllTopAdsDetails,
    getCreativeReport,
} from "helpers/senara_backend/senarabackend_helper"

function* fetchAllCreationVisits({ payload: id }) {
    try {
        //console.log("visits=>",id)
        const { result, error } = yield call(getAllLeadCreativeVisits, id)
        if (result) yield put(getLeadCreationVisitsSuccess(result))
        if (error) yield put(getLeadCreationVisitsFail(error))
    } catch (error) {
        yield put(getLeadCreationVisitsFail(error))
    }
}

function* fetchAllClickThroughRate({payload: id}) {
    try {
      const { result, error } = yield call(getAllClickThroughRate,id)
      //console.log("Result=>",result,error)
      if(result) yield put(getClickThroughRateSuccess(result))
      if(error) yield put(getClickThroughRateFail(error))
    } catch (error) {
      yield put(getClickThroughRateFail(error))
    }
  }

  function* fetchAllCostPerClick({payload: id}) {
    try {
      const { result, error } = yield call(getAllCostPerClick,id)
      if(result) yield put(getCostPerClickSuccess(result))
      if(error) yield put(getCostPerClickFail(error))
    } catch (error) {
      yield put(getCostPerClickFail(error))
    }
  }

  function* fetchAllConceptChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllConceptChart,query)
      if(result) yield put(getConceptChartSuccess(result))
      if(error) yield put(getConceptChartFail(error))
    } catch (error) {
      yield put(getConceptChartFail(error))
    }
  }
  
  function* fetchAllVisualChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllVisualChart,query)
      if(result) yield put(getVisualChartSuccess(result))
      if(error) yield put(getVisualChartFail(error))
    } catch (error) {
      yield put(getVisualChartFail(error))
    }
  }

  function* fetchAllCopyStrategyChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllCopyStrategyChart,query)
      if(result) yield put(getCopyStrategyChartSuccess(result))
      if(error) yield put(getCopyStrategyChartFail(error))
    } catch (error) {
      yield put(getCopyStrategyChartFail(error))
    }
  }


  function* fetchAllTagLineChart({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllTagLineChart,query)
      if(result) yield put(getTagLineChartSuccess(result))
      if(error) yield put(getTagLineChartFail(error))
    } catch (error) {
      yield put(getTagLineChartFail(error))
    }
  }

  function* fetchAllTopAds({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllTopAds,query)
      if(result) yield put(getTopAdsSuccess(result))
      if(error) yield put(getTopAdsFail(error))
    } catch (error) {
      yield put(getTopAdsFail(error))
    }
  }

  function* fetchAllTopAdsDetails({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}&sort_by=${params?.sort_by}`
      if(params?.tab_id) query = query+`&tab_id=${params?.tab_id}`
      //console.log('Cannel query===>', query)
      const { result, error } = yield call(getAllTopAdsDetails,query)
      if(result) yield put(getTopAdsDetailsSuccess(result))
      if(error) yield put(getTopAdsDetailsFail(error))
    } catch (error) {
      yield put(getTopAdsDetailsFail(error))
    }
  }

  function* fetchAllCreativeReport ({payload: params}) {
    try {
      let query = params?.campaign_id
      if(params?.type) query = `${query}&type=${params?.type}`
      const { result, error } = yield call(getCreativeReport,query)
      if(result) yield put(getCreativeReportSuccess(result))
      if(error) yield put(getCreativeReportFail(error))
    } catch (error) {
      yield put(getCreativeReportFail(error))
    }
  }

export function* watchLeadCreative() {
    yield takeEvery(GET_LEADS_CREATIVE_VISITS, fetchAllCreationVisits),
    yield takeEvery(GET_CLICK_THROUGH_RATE, fetchAllClickThroughRate),
    yield takeEvery(GET_COST_PER_CLICK, fetchAllCostPerClick),
    yield takeEvery(GET_CONCEPT_CHART, fetchAllConceptChart),
    yield takeEvery(GET_VISUAL_CHART, fetchAllVisualChart),
    yield takeEvery(GET_COPY_STRATEGY_CHART, fetchAllCopyStrategyChart),
    yield takeEvery(GET_TAG_LINE_CHART, fetchAllTagLineChart),
    yield takeEvery(GET_TOP_ADS, fetchAllTopAds),
    yield takeEvery(GET_TOP_ADS_DETAILS, fetchAllTopAdsDetails)
    yield takeEvery(GET_CREATIVE_REPORT, fetchAllCreativeReport)
  }

  function* LeadCreativeSaga() {
    yield all([fork(watchLeadCreative)])
  }
  
  export default LeadCreativeSaga