import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { SAVE_MEDIA_INTAKE, GET_MEDIA_INTAKE,
UPDATE_MEDIA_INTAKE,
DELETE_MEDIA_INTAKE,
SAVE_SALES_INTAKE,
GET_SALES_INTAKE,
SAVE_CREATIVE_INTAKE,
GET_CREATIVE_INTAKE,
SAVE_TECHNICAL_INTAKE,
GET_TECHNICAL_INTAKE,
DELETE_SALES_INTAKE,
DELETE_TECHNICAL_INTAKE,
DELETE_CREATIVE_INTAKE
} from "./actionTypes"

import { 
  saveMediaIntakeSuccess, 
  saveMediaIntakeFail, 
  getMediaIntakeSuccess, 
  getMediaIntakeFail,
  updateMediaIntakeSuccess,
  updateMediaIntakeFail,
  deleteMediaIntakeSuccess,
  deleteMediaIntakeFail,
  saveSalesIntakeSuccess,
  saveSalesIntakeFail,
  getSalesIntakeSuccess,
  getSalesIntakeFail,
  deleteSalesIntakeSuccess,
  deleteSalesIntakeeFail,
  saveCreativeIntakeSuccess,
  saveCreativeIntakeFail,
  getCreativeIntakeSuccess,
  getCreativeIntakeFail,
  deleteCreativeIntakeSuccess,
  deleteCreativeIntakeFail,
  saveTechnicalIntakeSuccess,
  saveTechnicalIntakeFail,
  getTechnicalIntakeSuccess,
  getTechnicalIntakeFail,
  deleteTechnicalIntakeSuccess,
  deleteTechnicalIntakeFail
} from "./actions"
import { 
  saveMediaIntake, 
  getAllMediaIntake,
  updateMediaIntake,
  deleteMediaIntake, 
  saveSalesIntake,
  getAllSalesIntake,
  deleteSalesIntake,
  saveCreativeIntake,
  getAllCreativeIntake,
  deleteCreativeIntake,
  saveTechnicalIntake,
  getAllTechnicalIntake,
  deleteTechnicalIntake
 } from "helpers/senara_backend/senarabackend_helper"

function* saveMediaIntakeDetails({payload: params}) {
    try{
        let query = params?.campaign_id
        const { result, error } = yield call(saveMediaIntake, query, params)
        if(result)yield put(saveMediaIntakeSuccess(result))
        if(error)yield put(saveMediaIntakeFail(error))
    } catch (error) {
      yield put(saveMediaIntakeFail(error))
    }
}
 
function* fetchAllMediaIntake({payload: params}) {
    try {
      let query = params?.campaign_id
      const { result, error } = yield call(getAllMediaIntake, query)
      //console.log('in get all company saga==>', result, error)
      if(result) yield put(getMediaIntakeSuccess(result))
      if(error) yield put(getMediaIntakeFail(error))
    } catch (error) {
      yield put(getMediaIntakeFail(error))
    }
  }
 
  function* onUpdateMediaIntake({ payload: data }) {
    try {
      const {result , error} = yield call(updateMediaIntake, data)
      if(result)yield put(updateMediaIntakeSuccess(result))
      if(error)yield put(updateMediaIntakeFail(error))
    } catch (error) {
      yield put(updateMediaIntakeFail(error))
    }
  }

  function* onDeleteMediaIntake({ payload: id }) {
    try {
      const {result, error} = yield call(deleteMediaIntake, id)
      if(result)yield put(deleteMediaIntakeSuccess(result))
      if(error)yield put(deleteMediaIntakeFail (error))
    } catch (error) {
      yield put(deleteMediaIntakeFail (error))
    }
  }
  
  function* addUpdateSalesIntake({payload: params}) {
    // console.log("Sales Intake Saga=>",params)
    try{
       let query = params?.campaign_id
       const { result, error } = yield call(saveSalesIntake, query, params)
        if(result)yield put(saveSalesIntakeSuccess(result))
        if(error)yield put(saveSalesIntakeFail(error))
    } catch (error) {
      yield put(saveSalesIntakeFail(error))
    }
  }
  
  function* fetchAllSalesIntake({payload: params}) {
    try {
      let query = params?.campaign_id
      const { result, error } = yield call(getAllSalesIntake,query)
      if(result) yield put(getSalesIntakeSuccess(result))
      if(error) yield put(getSalesIntakeFail(error))
    } catch (error) {
      yield put(getSalesIntakeFail(error))
    }
  }
 
  function* onDeleteSalesIntake({ payload: id }) {
    try {
      const {result, error} = yield call(deleteSalesIntake, id)
      if(result)yield put(deleteSalesIntakeSuccess(result))
      if(error)yield put(deleteSalesIntakeeFail(error))
    } catch (error) {
      yield put(deleteSalesIntakeeFail(error))
    }
  }

  function* saveCreativeIntakeDetails({payload: params}) {
    try{
        let query = params?.campaign_id
        const { result, error } = yield call(saveCreativeIntake, query, params)
        if(result)yield put(saveCreativeIntakeSuccess(result))
        if(error)yield put(saveCreativeIntakeFail(error))
    } catch (error) {
      yield put(saveCreativeIntakeFail(error))
    }
}
 
function* fetchAllCreativeIntake({payload: params}) {
  try {
    let query = params?.campaign_id
    const { result, error } = yield call(getAllCreativeIntake,query)
    //console.log('in get all company saga==>', result, error)
    if(result) yield put(getCreativeIntakeSuccess(result))
    if(error) yield put(getCreativeIntakeFail(error))
  } catch (error) {
    yield put(getCreativeIntakeFail(error))
  }
}

function* onDeleteCreativeIntake({ payload: id }) {
  try {
    const {result, error} = yield call(deleteCreativeIntake, id)
    if(result)yield put(deleteCreativeIntakeSuccess(result))
    if(error)yield put(deleteCreativeIntakeFail(error))
  } catch (error) {
    yield put(deleteCreativeIntakeFail(error))
  }
}

function* saveTechnicalIntakeDetails({payload: params}) {
  try{
      let query = params?.campaign_id
      const { result, error } = yield call(saveTechnicalIntake, query, params)
      if(result)yield put(saveTechnicalIntakeSuccess(result))
      if(error)yield put(saveTechnicalIntakeFail(error))
  } catch (error) {
    yield put(saveTechnicalIntakeFail(error))
  }
}

function* fetchAllTechnicalIntake({payload: params}) {
  try {
    let query = params?.campaign_id
    const { result, error } = yield call(getAllTechnicalIntake,query)
    if(result) yield put(getTechnicalIntakeSuccess(result))
    if(error) yield put(getTechnicalIntakeFail(error))
  } catch (error) {
    yield put(getTechnicalIntakeFail(error))
  }
}

function* onDeleteTechnicalIntake({ payload: id }) {
  try {
    const {result, error} = yield call(deleteTechnicalIntake, id)
    if(result)yield put(deleteTechnicalIntakeSuccess(result))
    if(error)yield put(deleteTechnicalIntakeFail(error))
  } catch (error) {
    yield put(deleteTechnicalIntakeFail(error))
  }
}

export function* watchMediaIntake() {
  yield takeEvery(SAVE_MEDIA_INTAKE, saveMediaIntakeDetails),
  yield takeEvery(GET_MEDIA_INTAKE, fetchAllMediaIntake),
  yield takeEvery(UPDATE_MEDIA_INTAKE, onUpdateMediaIntake),
  yield takeEvery(DELETE_MEDIA_INTAKE, onDeleteMediaIntake),
  yield takeEvery(SAVE_SALES_INTAKE, addUpdateSalesIntake),
  yield takeEvery(GET_SALES_INTAKE, fetchAllSalesIntake),
  yield takeEvery(SAVE_CREATIVE_INTAKE, saveCreativeIntakeDetails),
  yield takeEvery(GET_CREATIVE_INTAKE, fetchAllCreativeIntake),
  yield takeEvery(SAVE_TECHNICAL_INTAKE, saveTechnicalIntakeDetails),
  yield takeEvery(GET_TECHNICAL_INTAKE, fetchAllTechnicalIntake),
  yield takeEvery(DELETE_SALES_INTAKE, onDeleteSalesIntake),
  yield takeEvery(DELETE_TECHNICAL_INTAKE, onDeleteTechnicalIntake),
  yield takeEvery(DELETE_CREATIVE_INTAKE, onDeleteCreativeIntake)
}

function* intakeSaga() {
  yield all([fork(watchMediaIntake)])
}

  export default intakeSaga