import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Login Redux States
import { VERIFY_PASSWORD } from "./actionTypes"
import { verifySuccess, verifyError } from "./actions"
import { verifyResetPassword } from "helpers/senara_backend/senarabackend_helper"

// senara verify password
function* verify({ payload: { user, history } }) {
  console.log("user from saga", user)
  try {
    const { result, error } = yield call(verifyResetPassword, {
      password: user.password,
      confirm_password: user.confipassword,
      token: user.token,
    })
    if (result) {
      yield put(verifySuccess(result))
    }
    if (error) {
      yield put(verifyError(error))
    }
  } catch (error) {
    yield put(verifyError(error))
  }
}

export function* watchUserPasswordVerify() {
  yield takeEvery(VERIFY_PASSWORD, verify)
}

function* verifyPasswordSaga() {
  yield all([fork(watchUserPasswordVerify)])
}

export default verifyPasswordSaga
