/**
 * Get Flight plans
 */
 export const GET_FLIGHTPLANS = "GET_FLIGHTPLANS"
 export const GET_FLIGHTPLANS_SUCCESS = "GET_FLIGHTPLANS_SUCCESS"
 export const GET_FLIGHTPLANS_FAIL = "GET_FLIGHTPLANS_FAIL"

 /**
 * Get Home buyer persona
 */
export const GET_HOMEBUYER_PERSONA = "GET_HOMEBUYER_PERSONA"
export const GET_HOMEBUYER_PERSONA_SUCCESS = "GET_HOMEBUYER_PERSONA_SUCCESS"
export const GET_HOMEBUYER_PERSONA_FAIL = "GET_HOMEBUYER_PERSONA_FAIL"

/**
 * Get Targeted cohort tabs
 */
export const GET_TARGETED_COHORT_TABS = "GET_TARGETED_COHORT_TABS"
export const GET_TARGETED_COHORT_TABS_SUCCESS = "GET_TARGETED_COHORT_TABS_SUCCESS"
export const GET_TARGETED_COHORT_TABS_FAIL = "GET_TARGETED_COHORT_TABS_FAIL"

/**
 * Get Targeted cohort details
 */
export const GET_TARGETED_COHORT_DETAILS = "GET_TARGETED_COHORT_DETAILS"
export const GET_TARGETED_COHORT_DETAILS_SUCCESS = "GET_TARGETED_COHORT_DETAILS_SUCCESS"
export const GET_TARGETED_COHORT_DETAILS_FAIL = "GET_TARGETED_COHORT_DETAILS_FAIL"

/**
 * Get Landing Pages details
 */
 export const GET_LANDING_PAGES_DETAILS = "GET_LANDING_PAGES_DETAILS"
 export const GET_LANDING_PAGES_DETAILS_SUCCESS = "GET_LANDING_PAGES_DETAILS_SUCCESS"
 export const GET_LANDING_PAGES_DETAILS_FAIL = "GET_LANDING_PAGES_DETAILS_FAIL"

 /**
 * Get Ad Gallery tabs
 */
export const GET_AD_GALLERY_TABS = "GET_AD_GALLERY_TABS"
export const GET_AD_GALLERY_TABS_SUCCESS = "GET_AD_GALLERY_TABS_SUCCESS"
export const GET_AD_GALLERY_TABS_FAIL = "GET_AD_GALLERY_TABS_FAIL"

/**
 * Get Targeted cohort details
 */
 export const GET_AD_GALLERY_DETAILS = "GET_AD_GALLERY_DETAILS"
 export const GET_AD_GALLERY_DETAILS_SUCCESS = "GET_AD_GALLERY_DETAILS_SUCCESS"
 export const GET_AD_GALLERY_DETAILS_FAIL = "GET_AD_GALLERY_DETAILS_FAIL"

 /**
 * Get Media Plan Url
 */
  export const GET_MEDIA_PLAN_URL = "GET_MEDIA_PLAN_URL"
  export const GET_MEDIA_PLAN_URL_SUCCESS = "GET_MEDIA_PLAN_URL_SUCCESS"
  export const GET_MEDIA_PLAN_URL_FAIL = "GET_MEDIA_PLAN_URL_FAIL"
