import {
  SAVE_COMPANY,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_ALL_COMPANY_ROLE_SUCCESS,
  GET_ALL_COMPANY_ROLE_FAIL,
  GET_ALL_LOCATION_SUCCESS,
  GET_ALL_LOCATION_FAIL
} from "./actionTypes"

const initialState = {
  saveCompanySuccess: null,
  saveCompanyError: null,
  loading: false,
  companies: null,
  deleteCompanySuccess: null,
  deleteCompanyFail: null,
  all_company_role: null,
  all_location: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMPANY:
      state = {
        ...state,
        loading: true,
        saveCompanyError: null,
      }
      break
    case SAVE_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        saveCompanySuccess: action.payload,
        saveCompanyError: null,
      }
      break
    case SAVE_COMPANY_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        saveCompanyError: action.payload,
      }
      break
    case GET_COMPANIES_SUCCESS:
      state = {
        ...state,
        companies: action.payload,
      }
      break
    case GET_COMPANIES_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
      break
      case DELETE_COMPANY_SUCCESS:
        state = {
          ...state,
          deleteCompanySuccess: action.payload,
          deleteCompanyFail: null
        }
        break
      case DELETE_COMPANY_FAIL:
        state = {
          ...state,
          deleteCompanySuccess: null,
          deleteCompanyFail: action.payload,
        }
        break
        case GET_ALL_COMPANY_ROLE_SUCCESS:
          state = {
            ...state,
            all_company_role: action.payload,
          }
          break
        case GET_ALL_COMPANY_ROLE_FAIL:
          state = {
            ...state,
            error: action.payload,
          }
          break
          case GET_ALL_LOCATION_SUCCESS:
            state = {
              ...state,
              all_location: action.payload,
            }
            break
          case GET_ALL_LOCATION_FAIL:
            state = {
              ...state,
              error: action.payload,
            }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
