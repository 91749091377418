/**
 * Add User
 */
export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"

/**
 * Get Users
 */
export const GET_ALL_USERS = "get_all_users"
export const GET_ALL_USERS_SUCCESS = "get_all_users_success"
export const GET_ALL_USERS_FAIL = "get_all_users_fail"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

// Get Groups

export const GET_GROUP = "GET_GROUP"
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS"
export const GET_GROUP_FAIL = "GET_GROUP_FAIL"

// Get Role

export const GET_ROLE = "GET_ROLE"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_FAIL = "GET_ROLE_FAIL"

// Get Role Type

export const GET_ROLE_TYPE = "GET_ROLE_TYPE"
export const GET_ROLE_TYPE_SUCCESS = "GET_ROLE_TYPE_SUCCESS"
export const GET_ROLE_TYPE_FAIL = "GET_ROLE_TYPE_FAIL"

// Reset Role Type
export const RESET_ROLE_TYPE = "RESET_ROLE_TYPE"

// Update Profile Photo
export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO"
export const UPDATE_PROFILE_PHOTO_SUCCESS = "UPDATE_PROFILE_PHOTO_SUCCESS"
export const UPDATE_PROFILE_PHOTO_FAIL = "UPDATE_PROFILE_PHOTO_FAIL"
